import { t } from '@lingui/core/macro';

import { Flex } from '@/components/Flex';
import { Page, PageDateRangeFilter, PageFilterProvider } from '@/components/page';
import ShareMenu from '@/features/ShareMenu';

import styles from './Dashboard.module.scss';
import DashboardContent from './DashboardContent';

const Dashboard = () => {
  return (
    <Page title={t`Dashboard`} className={styles.dashboardPage}>
      <PageFilterProvider>
        <Flex justify="space-between">
          <PageDateRangeFilter />
          <ShareMenu notificationPageKey="dashboard" />
        </Flex>
        <DashboardContent />
      </PageFilterProvider>
    </Page>
  );
};

export default Dashboard;

import { percentToHex } from '@/helper/number-util';
import {
  COLOR_AQUA_100,
  COLOR_AQUA_200,
  COLOR_AQUA_400,
  COLOR_AQUA_600,
  COLOR_GRAY_200,
  COLOR_GRAY_400,
  COLOR_GRAY_500,
  COLOR_GRAY_600,
  COLOR_GRAY_700,
  COLOR_GREEN_100,
  COLOR_GREEN_400,
} from '@/styles/palette';

import { ALL_SEMANTIC_AQUAS } from './semantic/aqua';
import { ALL_SEMANTIC_BLUES } from './semantic/blue';
import { ALL_GRAYS } from './semantic/gray';
import { ALL_SEMANTIC_GREENS } from './semantic/green';
import { ALL_SEMANTIC_ORANGES } from './semantic/orange';
import { ALL_SEMANTIC_PINKS } from './semantic/pink';
import { ALL_SEMANTIC_PURPLES } from './semantic/purple';
import { ALL_SEMANTIC_REDS } from './semantic/red';
import { ALL_SEMANTIC_TEALS } from './semantic/teal';
import { ALL_SEMANTIC_VIOLETS } from './semantic/violet';
import { ALL_SEMANTIC_YELLOWS } from './semantic/yellow';

export const ALL_SEMANTIC_COLORS = [
  ...ALL_SEMANTIC_GREENS,
  ...ALL_SEMANTIC_AQUAS,
  ...ALL_SEMANTIC_TEALS,
  ...ALL_SEMANTIC_VIOLETS,
  ...ALL_SEMANTIC_ORANGES,
  ...ALL_SEMANTIC_YELLOWS,
  ...ALL_SEMANTIC_PINKS,
  ...ALL_SEMANTIC_PURPLES,
  ...ALL_SEMANTIC_BLUES,
  ...ALL_SEMANTIC_REDS,
];

export const ALL_COLORS = [...ALL_SEMANTIC_COLORS, ...ALL_GRAYS];

export const TAM_PRIMARY = COLOR_GREEN_400;
export const TAM_SECONDARY = COLOR_GREEN_100;
export const VISITS_PRIMARY = COLOR_AQUA_400;
export const VISITS_SECONDARY = COLOR_AQUA_100;
export const AUDIENCE_PRIMARY = COLOR_AQUA_600;
export const AUDIENCE_SECONDARY = COLOR_AQUA_200;
export const ALL_PRIMARY = COLOR_GRAY_500;
export const ALL_SECONDARY = COLOR_GRAY_400;
export const BOT_PRIMARY = COLOR_GRAY_700;
export const UNKNOWN_PRIMARY = COLOR_GRAY_500;

export const PIPELINE_PRIMARY = COLOR_AQUA_600;
export const CLOSED_WON_PRIMARY = COLOR_AQUA_200;
export const CLOSED_LOST_PRIMARY = COLOR_GRAY_600;

export const colorWithOpacity = (hexColorString: string, opacity: number) => {
  return `${hexColorString}${percentToHex(opacity)}`;
};

// Supporting colors
export const GRID_LINE_COLOR = COLOR_GRAY_200;
export const CROSSHAIR_LINE_COLOR = COLOR_GRAY_600;
export const CROSSHAIR_BACKGROUND = 'rgba(245, 245, 246, 0.8)';

export const SKELETON_FOREGROUND_COLOR = COLOR_GRAY_500;
export const SKELETON_BACKGROUND_COLOR = COLOR_GRAY_200;

/**
 * @deprecated
 */
export const VISUALISATION_CHART_COLORS = [
  '#B6DF59',
  '#59BDC6',
  '#9162FA',
  '#E45858',
  '#D2C92A',
  '#808080',
];

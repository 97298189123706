import { Tooltip as AntdTooltip } from 'antd';
import { TooltipPlacement, TooltipRef } from 'antd/es/tooltip';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode, Ref } from 'react';

import { Text } from '@/components/typography';

import styles from './Tooltip.module.scss';

const mouseEnterDelayMap = {
  slow: 1.75,
  normal: 0.5,
  fast: 0.1,
};

type Props = {
  ref?: Ref<TooltipRef>;
  title?: ReactNode;
  body: ReactNode;
  open?: boolean;
  placement?: TooltipPlacement;
  xOffset?: number;
  yOffset?: number;
  pointerEvents?: boolean;
  mouseEnterDelay?: 'slow' | 'normal' | 'fast';
  maxWidth?: number;
  onOpenChange?: (open: boolean) => void;
};

const Tooltip = ({
  ref,
  title,
  body,
  open,
  placement = 'bottom',
  xOffset,
  yOffset,
  pointerEvents = true,
  mouseEnterDelay = 'normal',
  maxWidth,
  children,
  onOpenChange,
}: PropsWithChildren<Props>) => {
  const offset = xOffset != null || yOffset != null ? [xOffset || 0, yOffset || 0] : undefined;
  const isBodyOnly = !title;

  return (
    <AntdTooltip
      ref={ref}
      classNames={{
        root: classNames(styles.overlay, {
          [styles.noPointerEvents]: pointerEvents === false,
          [styles.bodyOnly]: isBodyOnly,
        }),
      }}
      styles={{ root: { maxWidth } }}
      open={open}
      placement={placement}
      align={offset && { offset }}
      showArrow={false}
      mouseEnterDelay={mouseEnterDelayMap[mouseEnterDelay]}
      title={
        <div className={styles.tooltip}>
          {title && (
            <Text variant="caption1" color="light-green">
              {title}
            </Text>
          )}
          <Text variant="body2" color="white">
            {body}
          </Text>
        </div>
      }
      onOpenChange={onOpenChange}
    >
      {children}
    </AntdTooltip>
  );
};

export default Tooltip;

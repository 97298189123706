import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

import { NotificationSubscriptionDataType, useNotificationSubscriptionsList } from '@/api/settings';
import DeleteNotificationSubscriptionModal from '@/app/profile/DeleteNotificationSubscriptionModal';
import Edit from '@/assets/svg/edit.svg?react';
import Delete from '@/assets/svg/trashcan.svg?react';
import {
  InstantSearch,
  InstantSearchSortOrder,
  InstantSearchTable,
  QueryState,
  SolQueryParamsNew,
} from '@/components/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@/components/InstantSearch/util/search-util';
import { ColumnsType, FilterDropdown, MoreActionsCell } from '@/components/Table';
import Tooltip from '@/components/Tooltip';
import { Panel } from '@/components/panels';
import { Tag } from '@/components/typography';
import { USDateViewFormat } from '@/constants';
import NotificationSubscriptionModal from '@/features/NotificationSubscriptionModal';
import UserFilter from '@/features/UserFilter';
import { getWeekdayNames } from '@/helper/dateHelper';
import { numberFormat } from '@/helper/numberFormatter';
import { useNotification } from '@/providers/Notification';
import { useMe } from '@/providers/User';
import { COLOR_AQUA_600, COLOR_GREEN_400 } from '@/styles/palette';

enum ActionKey {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  sortBy: 'createdAt',
  sortOrder: InstantSearchSortOrder.DESC,
};

const NotificationSubscriptionsTable = () => {
  const currentUser = useMe();
  const { pushNotification } = useNotification();
  const [tableParams, setTableParams] = useState<SolQueryParamsNew>();
  const [subscriptionToDelete, setSubscriptionToDelete] =
    useState<NotificationSubscriptionDataType | null>(null);
  const [subscriptionToEdit, setSubscriptionToEdit] =
    useState<NotificationSubscriptionDataType | null>(null);
  const [lastSubscriptionKey, setLastSubscriptionKey] = useState<string | null>(null);

  const { subscriptions, totalResults, isLoading, error, mutate } =
    useNotificationSubscriptionsList(tableParams);

  const handleQueryStateChange = (queryState: Required<QueryState>) => {
    const tableParams = getSolQueryParamsNewFromQueryState(queryState);
    setTableParams(tableParams);
  };

  const handleActionSelect = (key: ActionKey, field: NotificationSubscriptionDataType) => {
    switch (key) {
      case ActionKey.EDIT:
        setSubscriptionToEdit(field);
        setLastSubscriptionKey(field.id);
        break;
      case ActionKey.DELETE:
        setSubscriptionToDelete(field);
        break;
    }
  };

  const handleSubscriptionUpdate = (success: boolean) => {
    setSubscriptionToEdit(null);
    if (success) {
      pushNotification({ type: 'success', message: t`Notification updated successfully` });
      mutate();
    } else {
      pushNotification({
        type: 'error',
        message: t`Failed to update notification. Please try again or contact support if you encounter the issue again.`,
      });
    }
  };

  const handleSubscriptionDelete = (isSubscriptionOwner: boolean, success: boolean) => {
    if (success) {
      mutate();

      pushNotification({
        type: 'success',
        message: isSubscriptionOwner
          ? t`Successfully deleted notification`
          : t`Successfully unsubscribed from notification`,
      });
    } else {
      pushNotification({
        type: 'error',
        message: isSubscriptionOwner
          ? t`An error occurred while attempting to delete the notification. Please try again or contact support if you encounter the issue again.`
          : t`An error occurred while attempting to unsubscribe from the notification. Please try again or contact support if you encounter the issue again.`,
      });
    }

    setSubscriptionToDelete(null);
  };

  const columns: ColumnsType<NotificationSubscriptionDataType> = useMemo(
    () => [
      {
        title: t`Notification Name`,
        key: 'name',
        fixed: 'left',
        sorter: true,
      },
      {
        title: t`Owner`,
        key: 'createdBy',
        sorter: true,
        render: (_, field) => (
          <Tooltip body={field.createdBy.email}>{field.createdBy.name}</Tooltip>
        ),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <UserFilter />
          </FilterDropdown>
        ),
      },
      {
        title: t`Delivery`,
        key: 'delivery',
        align: 'end',
        render: (_, field) => {
          if (field.type === 'goal') {
            return `${numberFormat(field.goal, { compact: true })} ${field.metric}`;
          } else {
            if (
              field.monday &&
              field.tuesday &&
              field.wednesday &&
              field.thursday &&
              field.friday &&
              !field.saturday &&
              !field.sunday
            ) {
              return t`Every Weekday`;
            }

            const weekdaysForDelivery = [
              field.sunday,
              field.monday,
              field.tuesday,
              field.wednesday,
              field.thursday,
              field.friday,
              field.saturday,
            ];
            const weekdayNames = getWeekdayNames('short');
            const deliveryDays = weekdaysForDelivery.reduce<string[]>((memo, isActive, index) => {
              if (isActive) {
                memo.push(weekdayNames[index]);
              }
              return memo;
            }, []);

            if (deliveryDays.length === 7) {
              return t`Every Day`;
            }

            return deliveryDays.join(', ');
          }
        },
      },
      {
        title: t`Creation Date`,
        key: 'createdAt',
        align: 'end',
        sorter: true,
        render: (text) => dayjs(text).format(USDateViewFormat),
      },
      {
        title: t`Type`,
        key: 'type',
        align: 'end',
        render: (_, field) => (
          <Tag color={field.type === 'recurring' ? COLOR_AQUA_600 : COLOR_GREEN_400} noMargin>
            {field.type === 'recurring' ? t`Recurring` : t`Goal`}
          </Tag>
        ),
      },
      {
        title: t`Repeats`,
        key: 'repeatsWeeks',
        align: 'end',
        sorter: true,
        render: (_, field) => {
          if (field.type === 'goal') {
            return t`Never`;
          }

          if (field.repeatsWeeks === '1') {
            return t`Every Week`;
          } else {
            return t`Every ${numberFormat(field.repeatsWeeks)} Weeks`;
          }
        },
      },
      {
        title: '',
        key: 'actions',
        width: 100,
        fixed: 'right',
        align: 'end',
        render: (_, field) => {
          const isSubscriptionOwner = field.createdBy.id === currentUser!.id;

          return (
            <MoreActionsCell<ActionKey>
              menuItems={[
                {
                  key: ActionKey.EDIT,
                  label: t`Edit`,
                  icon: <Edit />,
                  isHidden: !isSubscriptionOwner,
                },
                {
                  key: ActionKey.DELETE,
                  label: t`Delete`,
                  color: 'red',
                  icon: <Delete />,
                },
              ]}
              onSelect={(key) => handleActionSelect(key, field)}
            />
          );
        },
      },
    ],
    [],
  );

  return (
    <Panel size="L" title={t`Notification Subscriptions`}>
      <InstantSearch
        prefix="s"
        defaultQueryState={DEFAULT_QUERY_STATE}
        onQueryStateChange={handleQueryStateChange}
      >
        <InstantSearchTable<NotificationSubscriptionDataType>
          columns={columns}
          dataSource={subscriptions}
          totalResults={totalResults}
          loading={isLoading}
          emptyMessage={<Trans>No notification subscriptions found</Trans>}
          error={error}
          rowKey={(record) => record.id}
        />
        <DeleteNotificationSubscriptionModal
          subscription={subscriptionToDelete}
          onCancel={() => setSubscriptionToDelete(null)}
          onDeleteComplete={handleSubscriptionDelete}
        />
        <NotificationSubscriptionModal
          key={lastSubscriptionKey}
          type={subscriptionToEdit?.type}
          subscription={subscriptionToEdit}
          onCancel={() => setSubscriptionToEdit(null)}
          onSaveComplete={handleSubscriptionUpdate}
        />
      </InstantSearch>
    </Panel>
  );
};

export default NotificationSubscriptionsTable;

import once from 'lodash/once';
import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

import { ColumnType, ColumnsType } from '@/components/Table';
import {
  getVisibleTableColumnsState,
  setVisibleTableColumnsState,
} from '@/helper/user-preferences';

type TableStateContextType<T> = {
  columns: ColumnsType<T>;
  columnsMap: Record<string, ColumnType<T>>;
  columnsState: { sortedColumns: string[]; hiddenColumns: string[] };
  onColumnsStateChange: ({
    sortedColumns,
    hiddenColumns,
  }: {
    sortedColumns: string[];
    hiddenColumns: string[];
  }) => void;
};

const createTableStateContext = once(<T,>() => createContext({} as TableStateContextType<T>));

export const useTableState = <T extends object>() => {
  const context = useContext(createTableStateContext<T>());
  if (!context) {
    throw new Error('useTableState must be used within a TableState');
  }
  return context;
};

type Props<T> = {
  // Renaming `tableKey` is a breaking change in localStorage which will cause users to lose their
  // column sort and visibility preferences. Proceed carefully.
  tableKey: 'campaignActivity' | 'companyActivity' | 'offlineActivity' | 'vendorActivity';
  columns: ColumnType<T>[];
};

type ColumnPreferencesState = {
  sortedColumns: string[];
  hiddenColumns: string[];
};

const TableState = <T extends object>({
  tableKey,
  columns,
  children,
}: PropsWithChildren<Props<T>>) => {
  const TableStateContext = createTableStateContext<T>();

  const columnsMap = useMemo(() => {
    return columns.reduce(
      (acc, column) => {
        acc[column.key] = column;
        return acc;
      },
      {} as Record<string, ColumnType<T>>,
    );
  }, [columns]);

  const [columnsState, setColumnsState] = useState<ColumnPreferencesState>(() => {
    const userPreferences = getVisibleTableColumnsState(tableKey);
    if (userPreferences) {
      return userPreferences;
    }

    return {
      sortedColumns: columns.map((column) => `${column.key}`),
      hiddenColumns: [],
    };
  });

  const handleColumnsStateChange = (newColumnsState: ColumnPreferencesState) => {
    setColumnsState(newColumnsState);
    setVisibleTableColumnsState(tableKey, newColumnsState);
  };

  const sortedColumns = useMemo(() => {
    columnsState.sortedColumns.forEach((key, index) => {
      if (key in columnsMap) {
        columnsMap[key].order = index;
      }
    });

    return [...columns].sort((a, b) => {
      if (a?.order != null && b?.order != null) {
        return a?.order - b.order;
      }
      return 0;
    });
  }, [columnsMap, columnsState.sortedColumns]);

  const filteredAndSortedColumns = useMemo(() => {
    return sortedColumns.map((column) => {
      // If the column definition wasn't hidden to begin with, check to see if the user prefers it
      // to be hidden.
      if (!column.hidden) {
        column.hidden = columnsState.hiddenColumns.includes(`${column.key}`);
      }
      return column;
    });
  }, [columnsMap, sortedColumns, columnsState.hiddenColumns]);

  return (
    <TableStateContext.Provider
      value={{
        columns: filteredAndSortedColumns,
        columnsMap,
        columnsState,
        onColumnsStateChange: handleColumnsStateChange,
      }}
    >
      {children}
    </TableStateContext.Provider>
  );
};

export default TableState;

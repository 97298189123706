import { useVendorActivityKPIs } from '@/api/vendor';
import { usePageFilterContext } from '@/components/page';
import { TrafficKPIs } from '@/features/TrafficKPIs';

const VendorActivityKPIs = () => {
  const { pageFilters } = usePageFilterContext();
  const { startDate, endDate, audience } = pageFilters;
  const { vendorActivityKpis, isLoading, error } = useVendorActivityKPIs(
    startDate,
    endDate,
    audience,
  );

  return <TrafficKPIs trafficKPIData={vendorActivityKpis} isLoading={isLoading} error={error} />;
};

export default VendorActivityKPIs;

import dayjs, { Dayjs } from 'dayjs';

import { VendorSpendCellData, VendorSpendDataType } from '@/api/vendorSpend';

export const calculateBeginningDate = (vendorSpendRows: VendorSpendDataType[]): string => {
  const beginningDate = vendorSpendRows.reduce<Dayjs>((acc, row) => {
    const date = dayjs(row.data[0].firstDayOfMonth).date(1);
    return date && acc.isAfter(date) ? date : acc;
  }, dayjs().date(1));

  return beginningDate.toISOString();
};

export const addMissingDates = (beginningDate?: string, data: VendorSpendCellData[] = []) => {
  if (!beginningDate) {
    beginningDate = dayjs().startOf('month').toISOString();
  }

  const endingDate = dayjs().add(1, 'year').date(1);
  let dateCursor = dayjs(beginningDate).startOf('month');
  const newData: VendorSpendCellData[] = [];
  const isUserSupplied = data?.[0]?.userSupplied === false ? false : true;

  while (dateCursor.isSameOrBefore(endingDate, 'month')) {
    const firstDayOfMonthCursor = dateCursor.toISOString();
    const existingItem = data.find((item) => item.firstDayOfMonth === firstDayOfMonthCursor);
    if (existingItem) {
      newData.push(existingItem);
    } else {
      newData.push({
        amount: 0,
        firstDayOfMonth: firstDayOfMonthCursor,
        userSupplied: isUserSupplied,
      });
    }
    dateCursor = dayjs(dateCursor).utc().add(1, 'month').startOf('month');
  }

  return newData;
};

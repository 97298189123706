import { Input } from 'antd';
import { TextAreaProps as AntdTextAreaProps } from 'antd/es/input';
import classNames from 'classnames';
import { FocusEventHandler } from 'react';

import ClearIcon from '@/assets/svg/bx-x.svg?react';
import { maxDefaultLength } from '@/constants/numbers';

import { TextInputProps } from '../TextInput';
import inputStyles from '../TextInput/TextInput.module.scss';
import styles from './TextArea.module.scss';

export type TextAreaProps = Omit<TextInputProps, 'onFocus'> &
  Omit<AntdTextAreaProps, 'disabled' | 'variant' | 'allowClear' | 'size' | 'status' | 'suffix'> & {
    showResizer?: boolean;
    onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  };

const TextArea = ({
  ref,
  className,
  allowClear = false,
  isDisabled,
  maxLength = maxDefaultLength,
  showCount,
  showResizer = false,
  size = 'large',
  ...rest
}: TextAreaProps) => {
  return (
    <Input.TextArea
      {...rest}
      className={classNames(inputStyles.input, styles.textArea, className, {
        [styles.charCount]: !!showCount,
      })}
      ref={ref}
      style={{ resize: showResizer && !isDisabled ? 'vertical' : 'none' }}
      size={size === 'medium' ? 'middle' : size}
      variant="outlined"
      disabled={isDisabled}
      maxLength={maxLength}
      allowClear={allowClear && { clearIcon: <ClearIcon /> }}
      showCount={showCount && { formatter: ({ count, maxLength }) => `${count}/${maxLength}` }}
    />
  );
};

export default TextArea;

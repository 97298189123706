import '@ant-design/v5-patch-for-react-19';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import {
  ErrorBoundary,
  reactRouterV7BrowserTracingIntegration,
  init as sentryInit,
} from '@sentry/react';
import { StytchB2BProvider } from '@stytch/react/dist/b2b';
import { StytchB2BHeadlessClient } from '@stytch/vanilla-js/dist/b2b/index.headless';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

import App from '@/app/App';
import Error500 from '@/components/errors/Error500';
import { sessionDurationMinutes } from '@/constants/numbers';
import '@/helper/highcharts-tooltip-extension';
import { getLDContext } from '@/helper/launchdarkly';
import { updateSentryTags } from '@/helper/sentry';
import { QUERY_DEFAULTS } from '@/hooks/useSolQuery';
import { messages } from '@/locales/en/messages.mjs';
import { createUserContext } from '@/providers/User';

import './helper/dayjs';
import './helper/highcharts';
import styles from './index.module.scss';
import './styles/colors.light.css';

i18n.load('en', messages);
i18n.activate('en');

document.body.classList.add(styles.app);

const ENV = window.ENV || 'local';
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || '';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...QUERY_DEFAULTS,
    },
  },
});

const stytch = new StytchB2BHeadlessClient(import.meta.env.VITE_STYTCH_PUBLIC_TOKEN || '', {
  cookieOptions: {
    jwtCookieName: 'c99_session_jwt',
    istCookieName: 'c99_intermediate_session_token',
    opaqueTokenCookieName: 'c99_session',
  },
});

sentryInit({
  dsn: SENTRY_DSN,
  environment: ENV,
  ignoreErrors: [
    /^AbortError/,

    // Thrown by `useSolQuery` when the component is unmounted before the query is resolved.
    /^CanceledError/,
  ],
  integrations: [
    reactRouterV7BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement, {
  identifierPrefix: 'c99-',
});

// TODO: This warning should be removed in React 19. When that happens, see if we can remove this.
if (process.env.NODE_ENV === 'development') {
  const originalError = console.error;
  console.error = (message, ...args) => {
    if (!message.startsWith('Warning: useLayoutEffect does nothing on the server')) {
      originalError(message, ...args);
    }
  };
}

(async () => {
  let user = null;
  try {
    user = await stytch.session.authenticate({
      session_duration_minutes: sessionDurationMinutes,
    });
  } catch {
    // no logged-in user yet
  }

  let context = undefined;
  if (user) {
    const userContext = createUserContext(user.member, user.organization);
    context = getLDContext(userContext);
    updateSentryTags(userContext);
    window.USER_ID = userContext.id;
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID!,
    options: { streaming: true },
    context,
    timeout: 5,
  });

  root.render(
    <StrictMode>
      <I18nProvider i18n={i18n}>
        <ErrorBoundary fallback={<Error500 />}>
          <QueryClientProvider client={queryClient}>
            <StytchB2BProvider stytch={stytch}>
              <LDProvider>
                <App />
              </LDProvider>
            </StytchB2BProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </I18nProvider>
    </StrictMode>,
  );
})();

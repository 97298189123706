import { RouterProvider } from 'react-router';

import { useFlags } from '@/constants/featureFlags';
import { HeaderProvider } from '@/hooks/header/HeaderContext';
import { AntDConfigProvider } from '@/providers/AntD';
import { NotificationProvider } from '@/providers/Notification';
import { ThemeProvider } from '@/providers/Theme';
import { UserProvider } from '@/providers/User';
import getRouter from '@/router/Router';

function App() {
  const flags = useFlags();
  const router = getRouter(flags);

  return (
    <UserProvider>
      <AntDConfigProvider>
        <ThemeProvider>
          <NotificationProvider>
            <HeaderProvider>
              <RouterProvider router={router} />
            </HeaderProvider>
          </NotificationProvider>
        </ThemeProvider>
      </AntDConfigProvider>
    </UserProvider>
  );
}

export default App;

import { t } from '@lingui/core/macro';
import { Plural } from '@lingui/react/macro';

import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { numberFormat } from '@/helper/numberFormatter';

export const OpportunitiesOverviewKPIMetrics = () => {
  return (
    <KpiMetricGroup>
      <KpiMetric label={t`Days to Close`} value={numberFormat(45)} isLoading={false} error={null} />
      <KpiMetric
        label={t`Average Contract Value`}
        value={numberFormat(25000, { isCurrency: true, precision: 0 })}
        isLoading={false}
        error={null}
      />
      <KpiMetric label={t`Visits/Opp`} value={numberFormat(3.5)} isLoading={false} error={null} />
      <KpiMetric
        label={t`Non-Digital Events/Opp`}
        value={numberFormat(1.2)}
        isLoading={false}
        error={null}
      />
      <KpiMetric
        label={t`Total Pipeline`}
        value={numberFormat(500000, { isCurrency: true, precision: 0 })}
        caption={<Plural value={numberFormat(24)} one="# Opportunity" other="# Opportunities" />}
        isLoading={false}
        error={null}
      />
      <KpiMetric
        label={t`Total Closed Won`}
        value={numberFormat(300000, { isCurrency: true, precision: 0 })}
        caption={<Plural value={numberFormat(12)} one="# Opportunity" other="# Opportunities" />}
        isLoading={false}
        error={null}
      />
    </KpiMetricGroup>
  );
};

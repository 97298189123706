import { useBenchmarksByChannel } from '@/api/benchmarks';
import BenchmarksChart from '@/features/charts/BenchmarksChart';

import { DashboardWidgetComponentProps } from '../../../components/Dashboard/types';

const BenchmarksWidget = ({ pageFilters }: DashboardWidgetComponentProps) => {
  const { startDate, endDate } = pageFilters;
  const {
    benchmarksByChannel: data,
    isLoading,
    error,
  } = useBenchmarksByChannel(startDate, endDate);

  return <BenchmarksChart isLoading={isLoading} error={error} data={data} />;
};

export default BenchmarksWidget;

type BaseWebSocketResponse<TType, TPayload = never> = {
  type: TType;
  payload: TPayload;
  requestId?: string;
};

type CompletePayload = {
  content: string;
  sessionId: string;
};

type ChunkPayload = {
  content: string;
};

type UnknownWebSocketError = {
  connectionId: string;
  message: string;
  requestId: string;
};

export enum WebSocketErrorCode {
  ISE = 'err:nebula.internal-server-error',
  AUTH_FAILURE = 'err:nebula.auth-failure',
  UNKNOWN_ROUTE = 'err:nebula.unknown-route',
}

type ErrorPayload = {
  content: string;
  code: WebSocketErrorCode;
  sessionId?: string;
};

export type WebSocketAIMessage =
  | BaseWebSocketResponse<'complete', CompletePayload>
  | BaseWebSocketResponse<'chunk', ChunkPayload>;

export type WebSocketResponse = WebSocketAIMessage | UnknownWebSocketError;

export type WebSocketError = BaseWebSocketResponse<'error', ErrorPayload>;

export const isUnknownWebSocketMessage = (
  e: UnknownWebSocketError | WebSocketAIMessage,
): e is UnknownWebSocketError => (e as WebSocketAIMessage).type == null;

export const isWebSocketError = (e: WebSocketResponse | WebSocketError): e is WebSocketError =>
  (e as WebSocketError).type === 'error';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect } from 'react';

import { DateRangePicker, RangeValue } from '@/components/Form';
import { DateISOFormat } from '@/constants/formats';
import { getDefaultDateRange, setDefaultDateRange } from '@/helper/user-preferences';

import styles from './PageDateRangeFilter.module.scss';
import { usePageFilterContext } from './PageFilterProvider';
import { PageFiltersType } from './types';

type Props = {
  className?: string;
};

const getLast90Days = () => {
  return {
    startDate: dayjs().subtract(90, 'd').startOf('d').format(DateISOFormat),
    endDate: dayjs().endOf('d').format(DateISOFormat),
  };
};

const addDefaultDatesIntoFiltersIfNecessary = (pageFilters: PageFiltersType) => {
  // We may not have a `startDate` and `endDate` in the pageFilters until the filters get pushed
  // down into the component. This is a workaround to ensure that the date range is set to what the
  // filters will eventually contain.
  if (!pageFilters.startDate && !pageFilters.endDate) {
    const defaultRange = getDefaultDateRange() || getLast90Days();
    return {
      ...pageFilters,
      ...defaultRange,
    };
  } else {
    return pageFilters;
  }
};

const DashboardDateRangeFilter = ({ className = '' }: Props) => {
  const { pageFilters, setPageFilters } = usePageFilterContext();

  // Push the default start/end dates into the filters context so other components can use them.
  useEffect(() => {
    const defaultRange = getDefaultDateRange();

    if (defaultRange) {
      setPageFilters(defaultRange);
    } else {
      const newDefaultRange = getLast90Days();
      setDefaultDateRange(newDefaultRange);
      setPageFilters(newDefaultRange);
    }
  }, []);

  const handleDateRangeChange = (dates: RangeValue | null, dateStrings: [string, string]) => {
    const newDateRange = { startDate: dateStrings[0], endDate: dateStrings[1] };
    setDefaultDateRange(newDateRange);
    setPageFilters(newDateRange);
  };

  const filtersWithDateRange = addDefaultDatesIntoFiltersIfNecessary(pageFilters);

  return (
    <div className={classNames(styles.dateRange, className)} data-testid="date-range-picker">
      <DateRangePicker
        size="large"
        value={[dayjs(filtersWithDateRange.startDate), dayjs(filtersWithDateRange.endDate)]}
        allowClear={false}
        maxDate={dayjs().endOf('d')}
        onChange={handleDateRangeChange}
      />
    </div>
  );
};

export default DashboardDateRangeFilter;

import { t } from '@lingui/core/macro';
import classNames from 'classnames';
import lodashDebounce from 'lodash/debounce';
import { ChangeEvent, InputHTMLAttributes, useCallback, useEffect, useState } from 'react';

import SearchIcon from '@/assets/svg/search.svg?react';

import { TextInput, TextInputProps } from '../TextInput';
import styles from './SearchInput.module.scss';

const DEFAULT_DEBOUNCE_TIME = 750;

type ValueType = InputHTMLAttributes<HTMLInputElement>['value'] | bigint;

export type SearchInputProps = Omit<TextInputProps, 'onChange'> & {
  debounce?: boolean;
  onChange?: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
};

const SearchInput = ({
  ref,
  className,
  allowClear = true,
  debounce = true,
  defaultValue,
  placeholder = t`Search`,
  value,
  onChange = () => {},
  ...rest
}: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState<ValueType>(value ?? defaultValue);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const handleSearch = useCallback(lodashDebounce(onChange, debounce ? DEFAULT_DEBOUNCE_TIME : 0), [
    onChange,
  ]);

  const handleChange = (value: string, event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
    handleSearch(value, event);

    // If the input was cleared, flush the debounce to fire the event immediately
    if (value === '') {
      handleSearch.flush();
    }
  };

  useEffect(() => {
    return () => {
      handleSearch?.cancel();
    };
  }, []);

  return (
    <TextInput
      ref={ref}
      {...rest}
      className={classNames(styles.search, className)}
      allowClear={allowClear}
      placeholder={placeholder}
      suffix={<SearchIcon />}
      value={searchValue}
      onChange={handleChange}
      onPressEnter={handleSearch.flush}
    />
  );
};

export default SearchInput;

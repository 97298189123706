import { useState } from 'react';

import { useUserOptions } from '@/api/user';
import { FilterOperatorMulti, FilterOperatorValue } from '@/components/InstantSearch';
import { InfiniteScrollCategoryFilter } from '@/components/filters';

const PAGE_SIZE = 50;

type Props = {
  value?: FilterOperatorMulti;
  onChange?: (value: FilterOperatorValue) => void;
};

const UserFilter = (props: Props) => {
  const [search, setSearch] = useState<string>('');

  const { userOptions, error, hasMore, isLoading, isFetchingNextPage, fetchNextPage } =
    useUserOptions({ search, pageSize: PAGE_SIZE });

  return (
    <InfiniteScrollCategoryFilter
      {...props}
      options={userOptions}
      isLoading={isLoading}
      isLoadingMore={isFetchingNextPage}
      hasMore={hasMore}
      error={error}
      loadMore={fetchNextPage}
      searchText={search}
      onSearch={setSearch}
    />
  );
};

export default UserFilter;

import { UserType } from '../user/userType';

export interface BaseMetadataType {
  id: string;
  name: string;
  description?: string;
  created_at?: string;
  created_by?: UserType;
  updated_at?: string;
  updated_by?: UserType;
}

export type VisitTrafficType = {
  allVisits: number;
  botVisits: number;
  otherVisits: number;
  targetVisits: number;
  unresolvedVisits: number;
};

export type ActivityDataType = VisitTrafficType & {
  adClicks: number;
  adImpressions: number;
  allVisitsBest: boolean;
  allVisitsWorst: boolean;
  baseAccounts: number;
  closedWonFirstTouchAttribution: number;
  closedWonInfluenced: number;
  closedWonLinearAttribution: number;
  countCampaigns: number;
  engagedCompanies: number;
  engagedCompaniesBest: boolean;
  engagedCompaniesWorst: boolean;
  fitScore: number;
  fitScoreBest: boolean;
  fitScoreWorst: boolean;
  opportunitiesInfluenced: number;
  opportunityInfluencedBest: boolean;
  opportunityInfluencedWorst: boolean;
  percentOfEngagedCompanies: number;
  percentOpportunitiesInfluenced: number;
  pipelineFirstTouchAttribution: number;
  pipelineInfluenced: number;
  pipelineInfluencedBest: boolean;
  pipelineInfluencedPerSpend: number;
  pipelineInfluencedWorst: boolean;
  pipelineLastTouchAttribution: number;
  pipelineLinearAttribution: number;
  spend: number;
  spendPerVisit: number;
  spendPerVisitBest: boolean;
  spendPerVisitWorst: boolean;
  viewThroughVisits: number;
  visitEfficiency: number;
};

export type OptionType = {
  id: string;
  name: string;
};

export type SelectableOptionType = OptionType & {
  selected: boolean;
};

export type PagingOptionType = {
  results: OptionType[];
  hasNext: boolean;
};

export type CompanyType = {
  id: string;
  name: string;
  tld: string;
};

export type SectorType = OptionType;

export type RevenueRangeType = OptionType & {
  id: REVENUE_RANGE_ID;
  shortName?: string;
  name: string;
};

export type RegionType = OptionType;

export enum TRAFFIC_TYPES {
  ALL = 'all',
  AUDIENCE = 'audience',
  BOT = 'bot',
  OTHER = 'other',
  TAM = 'tam',
  TARGET = 'target',
  UNRESOLVED = 'unresolved',
}

export enum REVENUE_RANGE_ID {
  ENTERPRISE = 'rrng_enterprise',
  MID_MARKET = 'rrng_mid_market',
  SMALL_MEDIUM = 'rrng_small_medium',
  VERY_SMALL = 'rrng_very_small',
}

export type DisplayNamesGQLResponse = {
  displayNames: {
    get: {
      id: string;
      name: string;
    }[];
  };
};

export type CSVJobStatus = {
  id: string;
  status: 'completed' | 'error' | 'processing' | 'unknown';
  url: string;
};

export type DownloadCSVBeginGQLResponse = {
  csv: {
    begin: CSVJobStatus;
  };
};

export type DownloadCSVStatusGQLResponse = {
  csv: {
    status: CSVJobStatus;
  };
};

export type OpportunitiesTrendData = {
  sundayOfWeek: string;
  opportunities: number;
  revenue: number;
};

export type OpportunitiesTrendDataSummary = {
  data: OpportunitiesTrendData[];
  totalOpportunities: number;
  totalRevenue: number;
};

export type OpportunitiesInfluencedDataType = {
  pipeline: OpportunitiesTrendDataSummary;
  recentDeals: OpportunitiesTrendDataSummary;
};

export type VisitTrafficTrendType = VisitTrafficType & {
  sundayOfWeek: string;
};

export type TrafficVisitTrendGQLResponse = {
  visualization: {
    trafficVisitTrend2: VisitTrafficTrendType[];
  };
};

export type TrafficKPIType = {
  id: string;
  label: string;
  value: number | undefined;
  valueLink?: string | false;
  lastPeriod?: number | null;
  percentChange?: number | null;
  valueFormat: {
    isCurrency?: boolean;
    precision?: number;
  };
};

import {
  ALL_CAMPAIGNS_SEARCH_PARAM_KEYS,
  CAMPAIGNS_SORT_KEYS,
  CampaignsSearchParamType,
} from '@/router/searchParams/campaignSearchParams';
import { NavigableRouteType, RouteSearchParamEnumType } from '@/router/types';

export const campaigns = {
  path: '/app/campaigns',
  description:
    'Displays metrics for each campaign. An overview of each campaign is shown with the channel and vendor it belongs to, total spend, pixel impressions, pixel click-throughs, visits, companies who have engaged with the campaign, pipeline opportunities influenced, and closed-won opportunities influenced. The campaign can be broken down by the audiences that are targeted through that campaign. A fit score visualization is also displayed which shows how well the campaign is performing relative to other campaigns in the same space.',
  navigable: true,
  searchParams: {
    'c.campaignId': {
      type: 'string[]',
      description: 'A comma-separated string of campaign IDs',
      object: 'campaign',
    },
    'c.audienceId': {
      type: 'string',
      description: 'An audience ID to filter the channels by',
      object: 'audience',
    },
    'c.vendor.id': {
      type: 'string[]',
      description: 'A comma-separated string of vendor IDs',
      object: 'vendor',
    },
    'c.spend': {
      type: 'range',
      description: 'A range of ad spend from a min to max value.',
    },
    'c.adImpressions': {
      type: 'range',
      description: 'A range of ad impressions from a min to max value.',
    },
    'c.adClicks': {
      type: 'range',
      description: 'A range of ad clicks from a min to max value.',
    },
    'c.visits': {
      type: 'range',
      description: 'A range of visits from a min to max value.',
    },
    'c.visitEfficiency': {
      type: 'range',
      description:
        'A range of visit efficiency from a min to max value. This is a percentage field which can range from 0 to 1.',
    },
    'c.viewThroughVisits': {
      type: 'range',
      description: 'A range of view-through visits from a min to max value.',
    },
    'c.engagedCompanies': {
      type: 'range',
      description: 'A range of engaged companies from a min to max value.',
    },
    'c.pipelineInfluenced': {
      type: 'range',
      description:
        'A range of pipeline influenced from a min to max value. This is a currency field which indicates the amount of possible revenue influenced by the campaign.',
    },
    'c.closedWonInfluenced': {
      type: 'range',
      description:
        'A range of closed-won influenced from a min to max value. This is a currency field which indicates the amount of revenue influenced by the campaign that has been closed as won.',
    },
    'c.sortBy': {
      type: 'enum',
      values: [
        'campaign.name',
        'vendor.id',
        'spend',
        'adImpressions',
        'adClicks',
        'visits',
        'viewThroughVisits',
        'visitEfficiency',
        'engagedCompanies',
        'pipelineFirstTouchAttribution',
        'pipelineLastTouchAttribution',
        'pipelineLinearAttribution',
        'pipelineInfluenced',
        'closedWonFirstTouchAttribution',
        'closedWonLinearAttribution',
        'closedWonInfluenced',
      ],
      default: 'visits',
      description: 'The field to sort by. Only one field can be sorted at a time.',
    } satisfies RouteSearchParamEnumType<CAMPAIGNS_SORT_KEYS>,
    'c.sortOrder': {
      type: 'enum',
      values: ['asc', 'desc'],
      default: 'desc',
      description:
        'The sort order. Fields with type `number` or `date` are usually best sorted in descending order. While string fields are best sorted in ascending order.',
    },
    'c.page': {
      type: 'string',
      description: 'The page number to display',
    },
    'c.size': {
      type: 'string',
      description: 'The number of items to display per page',
    },
  },
} satisfies NavigableRouteType<ALL_CAMPAIGNS_SEARCH_PARAM_KEYS>;

export const campaignDetail = {
  path: '/app/campaigns/:campaigns',
  description:
    'Display metrics for a single campaign or a comma-separated list of campaign ids as a dynamic grouped campaign. The campaign is shown with the total number of visits over time, return on marketing spend (ROMS), visit efficiency, and campaign influence on pipeline and closed-won opportunities. The campaign can be broken down by an audience of companies.',
  navigable: true,
  pathParams: {
    campaigns: {
      type: 'string',
      description: 'The ID of the campaign the user wishes to view',
      object: 'campaign',
      required: true,
    },
  },
  searchParams: {
    audience: {
      type: 'string',
      description: 'An audience ID to filter the campaigns by',
      object: 'audience',
    },
  },
} satisfies NavigableRouteType<'audience'>;

export const campaignById = (campaignId?: string) =>
  campaignId && `${campaigns.path}/${encodeURIComponent(campaignId)}`;
export const campaignGroupByIds = (campaignIds: string[]) =>
  `${campaigns.path}/${campaignIds.join(',')}`;

/**
 * Returns a URL string for the web traffic activity route with the given search params.
 *
 * @param params An object with keys that match the search params for the web traffic activity
 * route. Undefined values are skipped.
 * @returns A URL string with the search params appended as query params
 */
export const campaignsWithParams = (params: Partial<CampaignsSearchParamType>) => {
  const nonNullFilters = Object.entries(params).reduce(
    (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
    {},
  );
  const query = new URLSearchParams(nonNullFilters as Record<string, string>);
  return `${campaigns.path}?${query.toString()}`;
};

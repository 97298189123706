import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { useState } from 'react';

import { UserOptionType, useUserOptions } from '@/api/user';
import { MultiSelectProps, SelectCustomTagProps } from '@/components/Form';
import InfiniteScrollMultiSelect from '@/components/Form/selects/InfiniteScrollMultiSelect';
import { Tag } from '@/components/typography';
import { useMe } from '@/providers/User';

const PAGE_SIZE = 50;

type Value = {
  label: string;
  value: string;
};

type Props = MultiSelectProps<UserOptionType> & {
  disableOwner?: boolean;
};

const UserMultiSelect = ({
  value,
  isLoading: isLoadingProp,
  disableOwner = false,
  size,
  onChange,
  ...rest
}: Props) => {
  const currentUser = useMe();
  const [search, setSearch] = useState<string>('');
  const { userOptions, hasMore, isLoading, isFetchingNextPage, fetchNextPage } = useUserOptions({
    pageSize: PAGE_SIZE,
    search,
    disableOwner,
  });

  const handleChange = (
    selectedValues: Value[] | null | undefined,
    options?: DefaultOptionType | DefaultOptionType[],
  ) => {
    if (!selectedValues || !options) {
      onChange?.(undefined, options);
    } else {
      onChange?.(selectedValues, options);
    }
  };

  const renderTag = ({ value, label, ...tagProps }: SelectCustomTagProps) => {
    return (
      <Tag
        {...tagProps}
        size={size}
        closable={value !== currentUser?.id}
        variant="solid"
        color="success"
      >
        {label}
      </Tag>
    );
  };

  return (
    <InfiniteScrollMultiSelect
      placeholder={t`Select User(s)...`}
      {...rest}
      data-testid="user-multiselect"
      options={userOptions}
      hasMore={hasMore}
      loadMore={fetchNextPage}
      isLoading={isLoadingProp || isLoading || isFetchingNextPage}
      size={size}
      value={value}
      tagRender={renderTag}
      onChange={handleChange}
      onSearch={setSearch}
      labelInValue
      notFoundContent={<Trans>No users were found...</Trans>}
    />
  );
};

export default UserMultiSelect;

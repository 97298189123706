import { t } from '@lingui/core/macro';

import { useMultiplePixelTargetingEfficiency } from '@/api/pixel';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  pixelIds?: string[];
};

const PixelMultiKPIMetrics = ({ pixelIds }: Props) => {
  const { pageFilters } = usePageFilterContext();
  const { startDate, endDate } = pageFilters;
  const { pixelTargetingEfficiencyData, isLoading, error } = useMultiplePixelTargetingEfficiency(
    pixelIds,
    startDate,
    endDate,
  );

  return (
    <KpiMetricGroup>
      {pixelIds?.map((id) => {
        const item = pixelTargetingEfficiencyData?.pixels.find((d) => d.pixel.id === id);

        return (
          <KpiMetric
            key={id}
            label={item ? t`${item.pixel.name ?? ''} - Impressions` : t`Impressions`}
            value={item && numberFormat(item.stats.all)}
            infoPopover={{
              title: t`Impressions (Pixel)`,
              body: t`Number of events the C99 Pixel recorded and analyzed. This only counts impressions where the Pixel code has been placed. This differs from the Impressions on the Vendors or Campaign page which gathers impression information directly from the Vendor Integration.`,
            }}
            isLoading={isLoading}
            error={error}
          />
        );
      })}
    </KpiMetricGroup>
  );
};

export default PixelMultiKPIMetrics;

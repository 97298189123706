import classNames from 'classnames';
import { Children, PropsWithChildren, Ref, cloneElement, isValidElement } from 'react';

import ChevronLeft from '@/assets/svg/chevron-left.svg?react';

import styles from './PanelDrawer.module.scss';

type Props = {
  ref?: Ref<HTMLDivElement>;
  isOpen: boolean;
  onOpenChange?: (isOpen: boolean) => void;
};

type PanelDrawerChildProps = {
  isOpen: boolean;
  [key: string]: unknown;
};

const PanelDrawer = ({ ref, children, isOpen, onOpenChange }: PropsWithChildren<Props>) => {
  const handleDrawerToggle = () => {
    const newIsOpen = !isOpen;
    onOpenChange?.(newIsOpen);
  };

  const wrappedChildren = Children.map(children, (child) => {
    if (isValidElement<PanelDrawerChildProps>(child)) {
      return cloneElement(child, { ...(child.props ?? {}), isOpen });
    }
  });

  return (
    <div
      ref={ref}
      data-testid="panel-drawer"
      className={classNames(styles.drawer, { [styles.isOpen]: isOpen })}
    >
      <div className={styles.content}>{wrappedChildren}</div>
      <div
        role="button"
        data-testid="panel-drawer-toggle"
        className={styles.toggleButton}
        onClick={handleDrawerToggle}
      >
        <ChevronLeft className={styles.toggleButtonIcon} />
      </div>
    </div>
  );
};

export default PanelDrawer;

import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

import { PixelType } from '@/api/pixel';
import Edit from '@/assets/svg/edit.svg?react';
import { Checkbox } from '@/components/Form';
import { InstantSearchTable } from '@/components/InstantSearch';
import { ColumnsType, MoreActionsCell } from '@/components/Table';
import { Link, Tag } from '@/components/typography';
import { colorWithOpacity } from '@/constants/colors';
import { USDateViewFormat } from '@/constants/formats';
import { PIXEL_COMPARE_MAX_LIMIT, PIXEL_GROUP_MAX_LIMIT } from '@/constants/numbers';
import SolAPIError from '@/error/SolAPIError';
import { numberFormat } from '@/helper/numberFormatter';
import { useMe } from '@/providers/User';
import { ROUTES } from '@/router';
import { COLOR_GREEN_400, COLOR_MANGO } from '@/styles/palette';

import PixelTableActionContent from './PixelTableActionContent';

type Props = {
  data?: PixelType[];
  error: SolAPIError | null;
  totalResults?: number;
  isLoading?: boolean;
};

enum ActionKey {
  EDIT = 'EDIT',
  PAUSE = 'PAUSE',
  PLAY = 'PLAY',
}

const PixelsListTable = ({ data, error, totalResults, isLoading = false }: Props) => {
  const user = useMe();
  const [selectedPixels, setSelectedPixels] = useState<PixelType[]>([]);

  const sortFields = ROUTES.pixels.searchParams['p.sortBy'].values;
  const isMaxCompareLimit =
    selectedPixels.length === Math.max(PIXEL_GROUP_MAX_LIMIT, PIXEL_COMPARE_MAX_LIMIT);

  const addPixel = (pixels: PixelType[], pixel: PixelType) => [...pixels, pixel];
  const removePixel = (pixels: PixelType[], index: number) => [
    ...pixels.slice(0, index),
    ...pixels.slice(index + 1),
  ];

  const handleSelectRow = (pixel: PixelType) => {
    setSelectedPixels((checkedPixels: PixelType[]) => {
      const pixelIndex = checkedPixels.indexOf(pixel);
      const isPixelSelected = pixelIndex >= 0;

      // If we hit the max-compare limit for pixels don't allow the user to add another one
      if (!isPixelSelected && isMaxCompareLimit) {
        return checkedPixels;
      }

      if (isPixelSelected) {
        return removePixel(checkedPixels, pixelIndex);
      }
      return addPixel(checkedPixels, pixel);
    });
  };

  const handleRemoveSelectedPixel = (pixel: PixelType) => {
    setSelectedPixels((checkedPixels: PixelType[]) =>
      removePixel(checkedPixels, checkedPixels.indexOf(pixel)),
    );
  };

  const columns: ColumnsType<PixelType> = useMemo(() => {
    const cols: ColumnsType<PixelType> = [
      {
        title: '',
        key: 'id',
        fixed: 'left',
        width: 40,
        render: (text, field) => {
          const isSelected = selectedPixels.includes(field);
          return <Checkbox checked={isSelected} isDisabled={!isSelected && isMaxCompareLimit} />;
        },
      },
      {
        title: t`Pixel Name`,
        key: 'name',
        fixed: 'left',
        width: 160,
        sorter: sortFields.indexOf('name') >= 0,
        render: (text, field) => (
          <Link variant="caption1" to={ROUTES.pixelById(field.id)}>
            {text}
          </Link>
        ),
      },
      {
        title: t`Channel`,
        key: 'channel.name',
        width: 120,
        sorter: sortFields.indexOf('channel.name') >= 0,
        render: (text, field) => field.channel.name,
      },
      {
        title: t`Vendor`,
        key: 'vendor.name',
        width: 120,
        sorter: sortFields.indexOf('vendor.name') >= 0,
        render: (text, field) => field.vendor.name,
      },
      {
        title: t`Audience`,
        key: 'audience.name',
        width: 140,
        sorter: sortFields.indexOf('audience.name') >= 0,
        render: (text, field) => field.audience.name,
      },
      {
        title: t`Status`,
        key: 'status',
        width: 100,
        sorter: sortFields.indexOf('status') >= 0,
        render: (text, field) =>
          field.status === 'paused' ? (
            <Tag color={colorWithOpacity(COLOR_MANGO, 0.3)}>
              <Trans>Paused</Trans>
            </Tag>
          ) : (
            <Tag color={colorWithOpacity(COLOR_GREEN_400, 0.3)}>
              <Trans>Active</Trans>
            </Tag>
          ),
        responsive: ['lg'],
      },
      {
        title: t`Updated On`,
        key: 'updated_at',
        width: 120,
        sorter: sortFields.indexOf('updated_at') >= 0,
        align: 'right',
        render: (_, field) => dayjs(field.updated_at).format(USDateViewFormat),
        responsive: ['lg'],
      },
      {
        title: t`Last Impression`,
        key: 'metrics.lastImpressionDate',
        width: 145,
        sorter: sortFields.indexOf('metrics.lastImpressionDate') >= 0,
        align: 'right',
        render: (_, field) =>
          field.metrics?.lastImpressionDate
            ? dayjs(field.metrics?.lastImpressionDate).format(USDateViewFormat)
            : '',
        responsive: ['lg'],
      },
      {
        title: t`Impressions (Last 30d)`,
        key: 'metrics.impressions',
        width: 120,
        sorter: sortFields.indexOf('metrics.impressions') >= 0,
        align: 'right',
        render: (text, field) => numberFormat(field.metrics?.impressions),
        responsive: ['sm'],
      },
    ];

    if (user?.isAdmin) {
      cols.push({
        title: '',
        key: 'action',
        width: 48,
        align: 'right',
        fixed: 'right',
        render: (_, field) => (
          <MoreActionsCell<ActionKey>
            menuItems={[
              {
                key: ActionKey.EDIT,
                label: t`Edit`,
                icon: <Edit />,
                to: ROUTES.pixelEditById(field.id),
              },
            ]}
          />
        ),
      });
    }

    return cols;
  }, [selectedPixels]);

  return (
    <>
      <InstantSearchTable<PixelType>
        data-testid="pixels-table"
        actionContent={
          <PixelTableActionContent
            selectedPixels={selectedPixels}
            onRemoveSelectedPixel={handleRemoveSelectedPixel}
          />
        }
        columns={columns}
        dataSource={data}
        totalResults={totalResults}
        loading={isLoading}
        emptyMessage={<Trans>No pixels found</Trans>}
        error={error}
        rowKey={(record) => record.id}
        sticky
        scroll={{ x: 1200 }}
        onRow={(record) => ({
          onClick: () => handleSelectRow(record),
        })}
      />
    </>
  );
};

export default PixelsListTable;

import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useParams } from 'react-router';

import { useVendorById } from '@/api/vendor';
import { Flex } from '@/components/Flex';
import { ReturnToLink } from '@/components/ReturnToLink';
import Error404 from '@/components/errors/Error404';
import {
  Page,
  PageAudienceFilter,
  PageDateRangeFilter,
  PageFilterProvider,
} from '@/components/page';
import ShareMenu from '@/features/ShareMenu';
import { ROUTES } from '@/router';

import VendorDetailKPIMetrics from './VendorDetailKPIMetrics';
import VendorWeeklyVisitsChart from './VendorWeeklyVisitsChart';

const VendorDetail = () => {
  const { vendor: vendorId } = useParams();
  const { vendor, isLoading } = useVendorById(vendorId);

  return (
    <Page title={t`Vendors`} pageName={vendor && `${vendor?.channel?.name} - ${vendor?.name}`}>
      <Flex vertical gap="large" fullWidth>
        <ReturnToLink route={ROUTES.vendors.path} routeName={<Trans>Vendors</Trans>} />
        {!isLoading && vendor == null ? (
          <Error404 message={<Trans>The vendor you're looking for does not exist</Trans>} />
        ) : (
          <PageFilterProvider>
            <Flex justify="space-between">
              <PageDateRangeFilter />
              <Flex gap="small">
                <PageAudienceFilter />
                <ShareMenu notificationPageKey="vendor" />
              </Flex>
            </Flex>
            <VendorDetailKPIMetrics vendorId={vendor?.id} isLoading={isLoading} />
            <VendorWeeklyVisitsChart vendorId={vendor?.id} isLoading={isLoading} />
          </PageFilterProvider>
        )}
      </Flex>
    </Page>
  );
};

export default VendorDetail;

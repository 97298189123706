import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';

import { InstanceShortDataType } from '@/api/organization';
import { InstantSearchTable } from '@/components/InstantSearch';
import { ColumnsType } from '@/components/Table';
import { Panel } from '@/components/panels';
import { MonthDateYearFormat } from '@/constants/formats';
import { formatDate } from '@/helper/dateHelper';

type Props = {
  selectedRow: InstanceShortDataType | undefined;
  data?: InstanceShortDataType[];
  totalResults?: number;
  isLoading?: boolean;
  error: Error | null;
  onSelectedRowChange: (org: InstanceShortDataType) => void;
};

const LoginAsTable = ({
  selectedRow,
  data,
  totalResults,
  isLoading,
  error,
  onSelectedRowChange,
}: Props) => {
  const columns: ColumnsType<InstanceShortDataType> = [
    {
      title: t`Name`,
      key: 'name',
      fixed: 'left',
      sorter: true,
    },
    {
      title: t`Domain`,
      key: 'domain',
    },
    {
      title: t`Instance Id`,
      key: 'id',
    },
    {
      title: t`Created On`,
      key: 'createdAt',
      sorter: true,
      render: (text) => formatDate(text, MonthDateYearFormat),
    },
  ];

  return (
    <Panel title={t`Customer Instances`}>
      <InstantSearchTable<InstanceShortDataType>
        columns={columns}
        dataSource={data}
        totalResults={totalResults}
        loading={isLoading}
        emptyMessage={<Trans>No customer instances found</Trans>}
        error={error}
        rowKey={(record) => record.id}
        rowCursor="pointer"
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selectedRow ? [selectedRow.id] : [],
          onSelect: (record) => onSelectedRowChange(record),
        }}
        onRow={(record) => ({ onClick: () => onSelectedRowChange(record) })}
      />
    </Panel>
  );
};

export default LoginAsTable;

import debounce from 'lodash/debounce';
import { BaseSelectRef } from 'rc-select';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { ReactNode, UIEvent, useRef } from 'react';

import { OptionType } from '@/api/common';

import { MultiSelect, MultiSelectProps } from '../MultiSelect';
import { Option, getLoadingOption } from '../Select';

// We display a loading option as the last item in the list (if there are more pages to fetch).
// If the user sees this loading option, load the next page. Don't wait until the user scrolls all
// the way to the bottom.
const LOADING_OPTION_OFFSET = 30;

const defaultRenderOption = <T extends OptionType>({ id, name, ...rest }: T) => (
  <Option key={id} value={id} label={name} {...rest}>
    {name}
  </Option>
);

export interface InfiniteScrollMultiSelectProps<T extends OptionType> extends MultiSelectProps<T> {
  loadMore?: () => void;
  hasMore?: boolean;
  renderOption?: (option: T, index: number, array: T[]) => ReactNode;
  notFoundContent: ReactNode;
}

const InfiniteScrollMultiSelect = <T extends OptionType>({
  isLoading,
  options,
  hasMore,
  loadMore,
  renderOption = defaultRenderOption,
  onSearch,
  onChange,
  ...rest
}: InfiniteScrollMultiSelectProps<T>) => {
  const selectRef = useRef<BaseSelectRef>(null);

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;

    if (
      loadMore &&
      hasMore &&
      !isLoading &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight - LOADING_OPTION_OFFSET
    ) {
      loadMore();
    }
  };

  const handleChange = (value: T, option?: DefaultOptionType | DefaultOptionType[] | undefined) => {
    // reset any search we might've already done
    onSearch?.('');
    onChange?.(value, option);
  };

  const handleSearch = debounce((search: string) => {
    selectRef.current?.scrollTo({ left: 0, top: 0 });
    onSearch?.(search);
  }, 500);

  const handleDropdownVisibleChange = (open: boolean) => {
    if (open) {
      // Reset the search box
      onSearch?.('');
    } else {
      handleSearch.cancel();
    }
  };

  return (
    <MultiSelect<T>
      {...rest}
      ref={selectRef}
      showSearch
      isLoading={isLoading}
      filterOption={false}
      onPopupScroll={handleScroll}
      onSearch={handleSearch}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      onChange={handleChange}
    >
      {options?.map(renderOption)}
      {hasMore && getLoadingOption()}
    </MultiSelect>
  );
};

export default InfiniteScrollMultiSelect;

import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useMemo, useState } from 'react';

import { TRAFFIC_TYPES } from '@/api/common';
import { OfflineActivityDataType } from '@/api/vendor';
import OfflineActivityTableActionMenu from '@/app/vendors/OfflineActivityTableActionMenu';
import { InstantSearchTable } from '@/components/InstantSearch';
import { ColumnsType } from '@/components/Table';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { Link } from '@/components/typography';
import SolAPIError from '@/error/SolAPIError';
import { MetricSelector } from '@/features/MetricSelector';
import { VisitActivityLegend } from '@/features/VisitActivityLegend';
import { useTableState } from '@/providers/TableState';
import { ROUTES } from '@/router';

const HIDDEN_SERIES = [TRAFFIC_TYPES.BOT, TRAFFIC_TYPES.UNRESOLVED];

type Props = {
  data?: OfflineActivityDataType[];
  isLoading?: boolean;
  error: SolAPIError | null;
  onDisabledSeriesChange: (disabledSeriesType: TRAFFIC_TYPES[]) => void;
};

const OfflineActivityTable = ({ data, isLoading, error, onDisabledSeriesChange }: Props) => {
  const {
    pageFilters: { audience },
  } = usePageFilterContext();

  const { columns } = useTableState<OfflineActivityDataType>();

  const [metricSelectorOpen, setMetricSelectorOpen] = useState(false);

  const allColumns = useMemo<ColumnsType<OfflineActivityDataType>>(() => {
    return [
      {
        title: t`Category`,
        key: 'vendorId',
        fixed: 'left',
        width: 120,
        render: (_, field) => field.campaignType.vendor.name,
      },
      {
        title: t`Type`,
        key: 'campaignTypeId',
        fixed: 'left',
        width: 200,
        render: (_, field) => field.campaignType.name,
      },
      ...columns,
    ];
  }, [columns]);

  return (
    <Panel
      size="L"
      title={<Trans>Non-Digital Events</Trans>}
      actions={
        <OfflineActivityTableActionMenu onOpenMetricSelector={() => setMetricSelectorOpen(true)} />
      }
      infoPopover={{
        title: t`Non-Digital Events`,
        body: (
          <Trans>
            Non-Digital events are activities and campaigns imported from salesforce, more
            information about the setup and customization of these reports can be found{' '}
            <Link variant="body2" color="static-green" to={ROUTES.companySettings.path}>
              {t`HERE`}
            </Link>
          </Trans>
        ),
      }}
      verifyC99Tag
      isFullHeight
    >
      <VisitActivityLegend
        audience={audience}
        hiddenSeries={HIDDEN_SERIES}
        onDisabledSeriesChange={onDisabledSeriesChange}
      />
      <InstantSearchTable<OfflineActivityDataType>
        data-testid="offline-table"
        loading={isLoading}
        error={error}
        columns={allColumns}
        dataSource={data}
        emptyMessage={<Trans>No non-digital events found</Trans>}
        pagination={false}
        rowKey={(record) => record.campaignType.id}
        sticky
        scroll={{ x: 900 }}
      />
      <MetricSelector open={metricSelectorOpen} onClose={() => setMetricSelectorOpen(false)} />
    </Panel>
  );
};

export default OfflineActivityTable;

import { useWebTrafficBreakdownByTrafficType } from '@/api/webTraffic';
import WebTrafficTotalVisitsChart from '@/app/web-traffic/WebTrafficTotalVisitsChart';
import { DashboardWidgetComponentProps } from '@/components/Dashboard/types';

const WebTrafficVisitsByTypeWidget = ({ pageFilters }: DashboardWidgetComponentProps) => {
  const { startDate, endDate, audience } = pageFilters;
  const { webTrafficSummary, isLoading, error } = useWebTrafficBreakdownByTrafficType(
    startDate,
    endDate,
    audience,
  );

  return (
    <WebTrafficTotalVisitsChart
      data={webTrafficSummary}
      audience={audience}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default WebTrafficVisitsByTypeWidget;

/* eslint-disable no-restricted-imports */
import { Flex as AntdFlex, FlexProps as AntdFlexProps } from 'antd';
import { Ref } from 'react';

type GapType = number | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

export type Props = AntdFlexProps & {
  ref?: Ref<HTMLElement>;
  gap?: GapType;
  fullWidth?: boolean;
  fullHeight?: boolean;
};

const getGap = (size?: GapType) => {
  if (size == 'xsmall') {
    return 4;
  }
  if (size == null || size === 'small') {
    return 8;
  }
  if (size === 'medium') {
    return 16;
  }
  if (size === 'large') {
    return 24;
  }
  if (size === 'xlarge') {
    return 32;
  }
  return size;
};

const Flex = ({ ref, gap = 'small', style, fullWidth, fullHeight, ...rest }: Props) => {
  if (fullWidth && style?.width == null) {
    style = { ...style, width: '100%' };
  }

  if (fullHeight && style?.height == null) {
    style = { ...style, height: '100%' };
  }

  return <AntdFlex {...rest} ref={ref} gap={getGap(gap)} style={style} />;
};

export default Flex;

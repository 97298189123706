import { OpportunitiesOverviewKPIMetrics } from './OpportunitiesOverviewKPIMetrics';
import { OpportunitiesOverviewTable } from './OpportunitiesOverviewTable';
import { OpportunitiesOverviewTrendBar } from './OpportunitiesOverviewTrendBar';

const OpportunitiesOverviewContent = () => {
  return (
    <>
      <OpportunitiesOverviewKPIMetrics />
      <OpportunitiesOverviewTrendBar />
      <OpportunitiesOverviewTable />
    </>
  );
};

export default OpportunitiesOverviewContent;

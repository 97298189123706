import classNames from 'classnames';
import { ReactNode } from 'react';

import { Button } from '../Button';
import { ButtonHTMLProps } from '../types';
import styles from './ActionButton.module.scss';

export type ActionButtonProps = ButtonHTMLProps & {
  className?: string;
  color: 'green' | 'black' | 'red';
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium' | 'large';
  isDisabled?: boolean;
  isSelected?: boolean;
  icon?: ReactNode;
  autoFocus?: boolean;
};

const ActionButton = ({
  className,
  children,
  color,
  size = 'medium',
  icon,
  isSelected = false,
  ...rest
}: ActionButtonProps) => {
  const isIconOnly = children == null && icon != null;

  return (
    <Button
      {...rest}
      className={classNames(
        styles.actionButton,
        className,
        styles[`color-${color}`],
        styles[`size-${size}`],
        {
          [styles['icon-only']]: isIconOnly,
          [styles.selected]: isSelected,
        },
      )}
      color={color}
      icon={icon}
      isSelected={isSelected}
    >
      {children}
    </Button>
  );
};

export default ActionButton;

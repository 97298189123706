import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useMemo, useState } from 'react';

import { PipelineVendorInfluenceDataType, usePipelineVendorInfluence } from '@/api/pipeline';
import { Legend, LegendItem } from '@/components/Legend';
import Table, { ColumnsType } from '@/components/Table';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { Link } from '@/components/typography';
import { TAM_PRIMARY, VISITS_PRIMARY } from '@/constants/colors';
import { ROUTES } from '@/router';

import PipelineVendorInfluenceTableBarCell from './PipelineVendorInfluenceTableBarCell';

export enum SERIES_INFLUENCE_TYPE {
  OPP_INFLUENCE,
  PIPE_INFLUENCE,
}

const PipelineVendorInfluenceChart = () => {
  const { pageFilters } = usePageFilterContext();
  const { startDate, endDate } = pageFilters;
  const [enabledSeries, setEnabledSeries] = useState<SERIES_INFLUENCE_TYPE[]>([
    SERIES_INFLUENCE_TYPE.OPP_INFLUENCE,
    SERIES_INFLUENCE_TYPE.PIPE_INFLUENCE,
  ]);
  const [hoveredRow, setHoveredRow] = useState<PipelineVendorInfluenceDataType | null>(null);
  const {
    pipelineVendorInfluence: data,
    isLoading,
    error,
  } = usePipelineVendorInfluence(startDate, endDate);

  const handleSeriesToggle = (type: SERIES_INFLUENCE_TYPE, selected: boolean) => {
    setEnabledSeries((seriesList) => {
      if (selected) {
        return seriesList.concat(type);
      }
      return seriesList.filter((d) => d !== type);
    });
  };

  const maxPercentage = useMemo(
    () =>
      data?.reduce((max, d) => {
        let maximum = max;

        if (enabledSeries.indexOf(SERIES_INFLUENCE_TYPE.OPP_INFLUENCE) >= 0) {
          maximum = Math.max(maximum, d.opportunitiesInfluencedPercent);
        }
        if (enabledSeries.indexOf(SERIES_INFLUENCE_TYPE.PIPE_INFLUENCE) >= 0) {
          maximum = Math.max(maximum, d.pipelineRevenueInfluencePercent);
        }

        return maximum;
      }, 0),
    [data, enabledSeries],
  );

  const columns: ColumnsType<PipelineVendorInfluenceDataType> = useMemo(
    () => [
      {
        title: t`Channel`,
        key: 'channel.name',
        sorter: (a, b) => a.channel.name.toLowerCase().localeCompare(b.channel.name.toLowerCase()),
        render: (text, field) => (
          <Link variant="caption1" to={ROUTES.channelById(field.channel.id)}>
            {field.channel.name}
          </Link>
        ),
        width: 140,
      },
      {
        title: t`Vendor`,
        key: 'vendor.name',
        sorter: (a, b) => a.vendor.name.toLowerCase().localeCompare(b.vendor.name.toLowerCase()),
        render: (text, field) => (
          <Link variant="caption1" to={ROUTES.vendorById(field.vendor.id)}>
            {field.vendor.name}
          </Link>
        ),
        width: 140,
      },
      {
        title: t`Visits`,
        key: 'opportunitiesInfluencedPercent',
        sorter: (a, b) => a.opportunitiesInfluencedPercent - b.opportunitiesInfluencedPercent,
        render: (text, field) => (
          <PipelineVendorInfluenceTableBarCell
            data={field}
            maxPercentage={maxPercentage}
            hoveredRow={hoveredRow}
            enabledSeries={enabledSeries}
          />
        ),
        defaultSortOrder: 'descend',
        onCell: (record) => ({
          onMouseEnter: () => setHoveredRow(record),
          onMouseLeave: () => setHoveredRow(null),
        }),
      },
    ],
    [data, hoveredRow, maxPercentage, enabledSeries],
  );

  return (
    <Panel size="L" title={<Trans>Vendor Influence</Trans>}>
      <Legend style={{ marginInline: -32, marginBlockStart: -20, marginBlockEnd: 16 }}>
        <LegendItem
          value="tam"
          color={TAM_PRIMARY}
          name={t`Opportunity Influence`}
          onChange={(selected) => handleSeriesToggle(SERIES_INFLUENCE_TYPE.OPP_INFLUENCE, selected)}
        />
        <LegendItem
          value="visits"
          color={VISITS_PRIMARY}
          name={t`Pipeline $ Influence`}
          onChange={(selected) =>
            handleSeriesToggle(SERIES_INFLUENCE_TYPE.PIPE_INFLUENCE, selected)
          }
        />
      </Legend>
      <Table<PipelineVendorInfluenceDataType>
        loading={isLoading}
        error={error}
        columns={columns}
        dataSource={data}
        emptyMessage={<Trans>No vendor influence found</Trans>}
        pagination={false}
        rowKey={(record) => `${record.channel.id}-${record.vendor.id}`}
      />
    </Panel>
  );
};

export default PipelineVendorInfluenceChart;

import { Fragment, ReactNode } from 'react';
import { To } from 'react-router';

import { Divider } from '@/components/Divider';
import Tooltip from '@/components/Tooltip';
import { ActionIcon } from '@/components/buttons';

import styles from './MoreActionsCell.module.scss';

type Props<T> = {
  size?: 'small' | 'medium' | 'large';
  menuItems: {
    key: T;
    label: string;
    color?: 'black' | 'white' | 'red';
    icon: ReactNode;
    to?: To | false;
    isHidden?: boolean;
  }[];
  onSelect?: (key: T) => void;
};

const MoreActionsCell = <T extends string>({ size = 'medium', menuItems, onSelect }: Props<T>) => {
  return (
    <div className={styles.container}>
      {menuItems.map((menuItem, i) => (
        <Fragment key={menuItem.key}>
          <Tooltip body={menuItem.label} placement="top">
            {!menuItem.isHidden && (
              <ActionIcon
                aria-label={menuItem.label}
                color={menuItem.color ?? 'black'}
                size={size}
                icon={menuItem.icon}
                to={menuItem.to}
                onClick={() => {
                  onSelect?.(menuItem.key);
                }}
              />
            )}
          </Tooltip>
          {i < menuItems.length - 1 && !menuItem.isHidden && (
            <Divider className={styles.divider} color="black" type="vertical" />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default MoreActionsCell;

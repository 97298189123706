import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Space } from 'antd';
import { useState } from 'react';

import { PixelType, usePixelList } from '@/api/pixel';
import {
  InstantSearch,
  InstantSearchSortOrder,
  QueryState,
  SolQueryParams,
} from '@/components/InstantSearch';
import { getSolQueryParamsFromQueryState } from '@/components/InstantSearch/util/search-util';
import { Page } from '@/components/page';
import { Panel } from '@/components/panels';
import useDisplayNotificationOnLoad from '@/hooks/useDisplayNotificationOnLoad';
import useEffectOnceWhenDepsExist from '@/hooks/useEffectOnceWhenDepsExist';
import useLocationState from '@/hooks/useLocationState';

import PixelUsageChart from './PixelUsageChart';
import PixelsListHeader from './PixelsListHeader';
import PixelsListTable from './PixelsListTable';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  size: 10,
  sortBy: 'updated_at',
  sortOrder: InstantSearchSortOrder.DESC,
};

const PixelsOverview = () => {
  const [tableParams, setTableParams] = useState<SolQueryParams>();
  const { successMessage, optimisticUpdate } = useLocationState<{
    successMessage: string;
    optimisticUpdate: PixelType;
  }>();
  const {
    pixelListData,
    totalResults,
    isLoading,
    error,
    mutate: mutateListData,
  } = usePixelList(tableParams);

  useDisplayNotificationOnLoad({ type: 'success', message: successMessage });
  useEffectOnceWhenDepsExist(() => {
    if (pixelListData && totalResults) {
      mutateListData({
        pixels: {
          get: {
            edges: pixelListData?.map((pixel) =>
              pixel.id === optimisticUpdate.id
                ? { node: { ...pixel, ...optimisticUpdate } }
                : { node: pixel },
            ),
            totalEdges: totalResults,
          },
        },
      });
    }
  }, [optimisticUpdate, pixelListData, mutateListData]);

  const handleQueryStateChange = (queryState: Required<QueryState>) => {
    const tableParams = getSolQueryParamsFromQueryState(queryState);
    setTableParams(tableParams);
  };

  return (
    <Page title={t`Pixel Overview`}>
      <Space size="large" direction="vertical">
        <InstantSearch
          prefix="p"
          defaultQueryState={DEFAULT_QUERY_STATE}
          onQueryStateChange={handleQueryStateChange}
        >
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <PixelsListHeader />
            <Panel
              size="L"
              title={<Trans>Pixels</Trans>}
              data-testid="pixels-table-panel"
              isFullHeight
            >
              <PixelsListTable
                data={pixelListData}
                totalResults={totalResults}
                isLoading={isLoading}
                error={error}
              />
            </Panel>
          </Space>
        </InstantSearch>
        <Panel size="L" title={<Trans>Pixel Usage</Trans>} noPadding>
          <div style={{ height: 600 }}>
            <PixelUsageChart />
          </div>
        </Panel>
      </Space>
    </Page>
  );
};

export default PixelsOverview;

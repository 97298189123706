import { plural, t } from '@lingui/core/macro';
import { useMemo } from 'react';

import { useOpportunityTrend } from '@/api/opportunity';
import Flare, { Axis, Column, Legend, Tooltip } from '@/components/Flare';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { CLOSED_LOST_PRIMARY, MonthDateShortFormat, MonthDateYearFormat } from '@/constants';
import { CLOSED_WON_PRIMARY, PIPELINE_PRIMARY } from '@/constants/colors';
import { formatDate, getTime } from '@/helper/dateHelper';
import { numberFormat } from '@/helper/numberFormatter';

export const OpportunitiesOverviewTrendBar = () => {
  const {
    pageFilters: { startDate, endDate, audience },
  } = usePageFilterContext();
  const { opportunityTrend, isLoading, error } = useOpportunityTrend(startDate, endDate, audience);

  const seriesData = useMemo(() => {
    if (!opportunityTrend) {
      return [];
    }

    return [
      {
        id: 'Pipeline',
        name: t`Pipeline`,
        zIndex: 1,
        data: opportunityTrend
          .filter((item) => item.status === 'Pipeline')
          .map((item) => ({
            amount: item.revenue,
            custom: { date: item.sundayOfWeek, count: item.count },
          })),
      },
      {
        id: 'Closed Won',
        name: t`Closed Won`,
        zIndex: 1,
        data: opportunityTrend
          .filter((item) => item.status === 'Closed Won')
          .map((item) => ({
            amount: item.revenue,
            custom: { date: item.sundayOfWeek, count: item.count },
          })),
      },
      {
        id: 'Closed Lost',
        name: t`Closed Lost`,
        zIndex: 1,
        data: opportunityTrend
          .filter((item) => item.status === 'Closed Lost')
          .map((item) => ({
            amount: item.revenue,
            custom: { date: item.sundayOfWeek, count: item.count },
          })),
      },
    ];
  }, [opportunityTrend]);

  const categories = useMemo(() => {
    if (!opportunityTrend) {
      return [];
    }

    return Array.from(new Set(opportunityTrend?.map((item) => item.sundayOfWeek)));
  }, [opportunityTrend]);

  return (
    <Panel title={t`Opportunity Changes`} style={{ height: 465 }} noPadding>
      <Flare
        data={seriesData}
        description={t`A bar chart showing the number of opportunities closed lost, closed won, and in pipeline over the select time period`}
        colors={[PIPELINE_PRIMARY, CLOSED_WON_PRIMARY, CLOSED_LOST_PRIMARY]}
        parseX={getTime}
        isLoading={isLoading}
        error={error}
      >
        <Legend />
        <Column
          y="amount"
          position="dodge"
          skeletonLoaderOptions={{ numCategories: 12, numSeries: 3 }}
        />
        <Axis
          position="left"
          labelFormat={(item) => numberFormat(item.value, { isCurrency: true, precision: 0 })}
        />
        <Axis
          position="bottom"
          categories={categories}
          labelFormat={(item) => formatDate(item.value, MonthDateShortFormat, { isUTC: true })}
          crosshair="rect"
        />
        <Tooltip
          titleFormat={(item) => formatDate(item?.category, MonthDateYearFormat, { isUTC: true })}
          rowSecondaryValueFormat={(point) =>
            `(${plural(point?.custom?.count, {
              one: '# Opportunity',
              other: '# Opportunities',
            })})`
          }
          rowValueFormat={(point) => numberFormat(point?.y, { isCurrency: true, precision: 0 })}
          shared
        />
      </Flare>
    </Panel>
  );
};

export const maxDefaultLength = 1000;
export const maxEmailLength = 256;
export const maxUserNameLength = 128;
export const maxPasswordLength = 128;
export const maxObjectNameLength = 35;
export const maxObjectLongNameLength = 70;
export const maxDescriptionLength = 2000;
export const maxDomainLength = 200;
export const skeletonActivityListCount = 9;
export const listViewPageLimit = 20;
export const maxVendorConfigFieldLength = 10;
export const minCharSearchLength = 3;

// Stytch Session Length
export const sessionDurationMinutes = 43200; // 30 days

// Data retention policy
export const dataRetentionPolicy = 730; // 730 days

// CSV download limit
export const csvDownloadRowLimit = 100000;

// PIXELS
export const PIXEL_GROUP_MIN_LIMIT = 2;
export const PIXEL_GROUP_MAX_LIMIT = 10;
export const PIXEL_COMPARE_MIN_LIMIT = 2;
export const PIXEL_COMPARE_MAX_LIMIT = 5;

// CAMPAIGNS
export const CAMPAIGN_GROUP_MIN_LIMIT = 2;
export const CAMPAIGN_GROUP_MAX_LIMIT = 10;
export const CAMPAIGN_SCORE_MIN_LIMIT = 1;
export const CAMPAIGN_SCORE_MAX_LIMIT = 10;

import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { TableRef } from 'antd/es/table';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { Ref, useImperativeHandle, useMemo, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import { ChannelType } from '@/api/channel';
import {
  ChannelMappingCustomParameterType,
  ChannelMappingRuleDataType,
} from '@/api/channelMappingRules';
import { OptionType } from '@/api/common';
import ChannelSelect from '@/app/pixels/ChannelSelect';
import VendorByChannelSelect from '@/app/pixels/VendorByChannelSelect';
import DeleteIcon from '@/assets/svg/trashcan.svg?react';
import { Select, TextArea } from '@/components/Form';
import { QueryStateFilterMulti, useInstantSearchState } from '@/components/InstantSearch';
import Table, { ColumnsType } from '@/components/Table';
import { ActionIcon } from '@/components/buttons';
import { ConfirmModal } from '@/components/modals';
import { maxDescriptionLength } from '@/constants/numbers';
import SolAPIError from '@/error/SolAPIError';

import { NEW_ROW_ID_PREFIX } from './ChannelMappingRules';
import styles from './ChannelMappingRulesTable.module.scss';
import {
  displayCustomParamsAsText,
  displayCommaSeparatedValuesAsText as displayRuleAsCSV,
  parseCustomParameters,
  parseCommaSeparatedValues as parseRuleCSV,
} from './util';

const HIGHLIGHTED_ROW_REMOVAL_TIMER = 5000;

type Props = {
  ref?: Ref<ChannelMappingRulesTableRef>;
  data: ChannelMappingRuleDataType[];
  invalidRowKeys?: string[];
  isLoading?: boolean;
  error: SolAPIError | null;
  isEditMode: boolean;
  onDataChange: (newData: ChannelMappingRuleDataType[]) => void;
  onValidate: (newData: ChannelMappingRuleDataType[]) => void;
};

export type ChannelMappingRulesTableRef = {
  scrollToRow: (key: string) => void;
  highlightRows: (keys: string[]) => void;
};

const updateDataPriority = (data: ChannelMappingRuleDataType[]) =>
  data.map((item, i) => ({ ...item, priority: i }));

const ChannelMappingRulesTable = ({
  ref,
  data,
  invalidRowKeys = [],
  isLoading,
  error,
  isEditMode,
  onDataChange,
  onValidate,
}: Props) => {
  const tableRef = useRef<TableRef>(null);
  const { queryState } = useInstantSearchState();
  const [highlightedRowKeys, setHighlightedRowKeys] = useState<string[]>([]);
  const [deleteRow, setDeleteRow] = useState<ChannelMappingRuleDataType>();

  const filteredData = useMemo(() => {
    let localData = data;

    // Match wildcard search
    if (queryState?.search) {
      const searchCompareStr = queryState.search.trim().toLocaleLowerCase();

      localData = localData.filter((row) => {
        const vendorName = row.vendor?.name?.toLocaleLowerCase();
        const channelName = row.vendor?.channel?.name.toLocaleLowerCase();

        return (
          (vendorName?.indexOf(searchCompareStr) ?? -1) >= 0 ||
          (channelName?.indexOf(searchCompareStr) ?? -1) >= 0 ||
          row.id.startsWith(NEW_ROW_ID_PREFIX)
        );
      });
    }

    if (queryState?.filters) {
      localData = localData.filter((row) => {
        // Every explicit filter must match
        return queryState.filters.every((filter) => {
          const multiFilter = filter as QueryStateFilterMulti;
          const operand = multiFilter.operand;
          const operands = Array.isArray(operand) ? operand : [operand];

          // Only one operand has to match
          return operands.some((operandVal) => {
            if (multiFilter.field === 'vendor.name' && multiFilter.operator === 'in') {
              return row.vendor?.name === operandVal;
            } else if (multiFilter.field === 'channel.name' && multiFilter.operator === 'in') {
              return row.vendor?.channel?.name === operandVal;
            }
            return true;
          });
        });
      });
    }

    return localData;
  }, [data, queryState]);

  const priorityOptions = useMemo(
    () =>
      data.filter((d) => !d.isC99).map((d) => ({ value: d.priority + 1, label: d.priority + 1 })),
    [data],
  );

  const addHighlightedRowKeys = (keys: string[]) => {
    setHighlightedRowKeys((existingKeys) => existingKeys?.concat(keys));
    setTimeout(() => {
      // Remove the highlighted rows after a few seconds
      setHighlightedRowKeys((existingKeys) => existingKeys?.filter((k) => keys.indexOf(k) < 0));
    }, HIGHLIGHTED_ROW_REMOVAL_TIMER);
  };

  const scrollToKey = (key: string) => {
    setTimeout(() => {
      tableRef.current?.scrollTo({ key });

      // Sometimes the scroll doesn't quite get us here due to expanding textareas on virtual
      // cells. A subsequent call will get us the rest of the way there.
      setTimeout(() => {
        tableRef.current?.scrollTo({ key });
      }, 250);
    }, 0);
  };

  const scrollAndHighlightRow = (key: string) => {
    addHighlightedRowKeys([key]);
    scrollToKey(key);
  };

  useImperativeHandle(ref, () => ({
    scrollToRow: (key: string) => scrollToKey(key),
    highlightRows: (keys: string[]) => addHighlightedRowKeys(keys),
  }));

  const columns = useMemo(() => {
    const handlePriorityChange = (
      field: ChannelMappingRuleDataType,
      index: number,
      newIndex: number,
    ) => {
      const i = data.findIndex((d) => d.id === field.id);
      const newData = [...data];
      const changedItem = newData.splice(i, 1)[0];
      newData.splice(newIndex, 0, changedItem);
      onDataChange(updateDataPriority(newData));
      scrollAndHighlightRow(changedItem.id);
    };

    const getRowAriaInvalid = (field: ChannelMappingRuleDataType) =>
      invalidRowKeys.indexOf(field.id) >= 0 ? 'true' : undefined;

    const handleFieldChange = (newField: ChannelMappingRuleDataType) => {
      const newData = data.map((field) => (field.id === newField.id ? newField : field));
      onDataChange(newData);
      // We changed a formerly invalid row, let's validate everything again to see if the error
      // is resolved.
      if (invalidRowKeys.indexOf(newField.id) >= 0) {
        onValidate(newData);
      }
    };

    const handleChannelChange = (field: ChannelMappingRuleDataType, channel: ChannelType) => {
      // Reset vendor
      const newVendor = { channel, id: undefined, name: undefined };
      handleFieldChange({ ...field, vendor: newVendor });
    };

    const handleVendorChange = (field: ChannelMappingRuleDataType, vendor: OptionType) => {
      const newVendor = { channel: field.vendor?.channel, ...vendor };
      handleFieldChange({ ...field, vendor: newVendor });
    };

    const handleRuleChange = (
      field: ChannelMappingRuleDataType,
      fieldName: 'media' | 'sources' | 'referralDomains' | 'customParameters',
      newValue: string[] | ChannelMappingCustomParameterType[],
    ) => {
      const oldValue = field[fieldName];
      if (!isEqual(newValue, oldValue)) {
        handleFieldChange({ ...field, [fieldName]: newValue });
      }
    };

    const cols: ColumnsType<ChannelMappingRuleDataType> = [
      {
        title: t`Priority`,
        key: 'priority',
        fixed: 'left',
        width: 90,
        render: (text, field, index) =>
          field.isC99 ? (
            ''
          ) : isEditMode ? (
            <Select
              size="medium"
              showSearch
              notFoundContent={t`Choose priority`}
              value={field.priority + 1}
              options={priorityOptions}
              aria-invalid={getRowAriaInvalid(field)}
              onChange={(priority) => handlePriorityChange(field, index, priority - 1)}
            />
          ) : (
            field.priority + 1
          ),
      },
      {
        title: t`Channel`,
        key: 'vendor.channel.id',
        width: 185,
        render: (text, field) =>
          isEditMode && !field.isC99 ? (
            <ChannelSelect
              size="medium"
              value={field?.vendor?.channel}
              aria-invalid={getRowAriaInvalid(field)}
              onChange={(channel) => handleChannelChange(field, channel)}
            />
          ) : (
            field?.vendor?.channel?.name
          ),
      },
      {
        title: t`Vendor`,
        key: 'vendor.id',
        width: 185,
        render: (text, field) =>
          isEditMode && !field.isC99 ? (
            <VendorByChannelSelect
              size="medium"
              channelId={field?.vendor?.channel?.id}
              value={field?.vendor as OptionType}
              aria-invalid={getRowAriaInvalid(field)}
              onChange={(vendor) => handleVendorChange(field, vendor)}
            />
          ) : (
            field?.vendor?.name
          ),
      },
      {
        title: t`Medium`,
        key: 'media',
        width: 220,
        render: (text, field) =>
          isEditMode && !field.isC99 ? (
            <TextArea
              size="medium"
              rows={1}
              autoSize={{ maxRows: 30 }}
              defaultValue={displayRuleAsCSV(field.media)}
              aria-invalid={getRowAriaInvalid(field)}
              maxLength={maxDescriptionLength}
              onBlur={(e) => handleRuleChange(field, 'media', parseRuleCSV(e.currentTarget.value))}
            />
          ) : (
            displayRuleAsCSV(field.media)
          ),
      },
      {
        title: t`Source`,
        key: 'sources',
        width: 200,
        render: (text, field) =>
          isEditMode && !field.isC99 ? (
            <TextArea
              size="medium"
              rows={1}
              autoSize={{ maxRows: 30 }}
              defaultValue={displayRuleAsCSV(field.sources)}
              aria-invalid={getRowAriaInvalid(field)}
              maxLength={maxDescriptionLength}
              onBlur={(e) =>
                handleRuleChange(field, 'sources', parseRuleCSV(e.currentTarget.value))
              }
            />
          ) : (
            displayRuleAsCSV(field.sources)
          ),
      },
      {
        title: t`Referral Domain`,
        key: 'referralDomains',
        width: 200,
        render: (text, field) =>
          isEditMode && !field.isC99 ? (
            <TextArea
              size="medium"
              rows={1}
              autoSize={{ maxRows: 30 }}
              defaultValue={displayRuleAsCSV(field.referralDomains)}
              aria-invalid={getRowAriaInvalid(field)}
              maxLength={maxDescriptionLength}
              onBlur={(e) =>
                handleRuleChange(field, 'referralDomains', parseRuleCSV(e.currentTarget.value))
              }
            />
          ) : (
            displayRuleAsCSV(field.referralDomains)
          ),
      },
      {
        title: t`Custom Parameters`,
        key: 'params',
        width: 164,
        render: (text, field) =>
          isEditMode && !field.isC99 ? (
            <TextArea
              size="medium"
              rows={1}
              autoSize={{ maxRows: 30 }}
              defaultValue={displayCustomParamsAsText(field.customParameters)}
              aria-invalid={getRowAriaInvalid(field)}
              maxLength={maxDescriptionLength}
              onBlur={(e) =>
                handleRuleChange(
                  field,
                  'customParameters',
                  parseCustomParameters(e.currentTarget.value),
                )
              }
            />
          ) : (
            displayCustomParamsAsText(field.customParameters)
          ),
      },
    ];

    if (isEditMode) {
      // TODO: Add drag and drop behavior
      // Drag-and-drop is not currently supported for `virtual` tables like this one since it
      // requires edits to `components.body` which is not supported when in `virtual` mode. There
      // is, however, a PR in Ant Design which will enable in and allow us to have drag-and-drop
      // in this `virtual` table. This is currently slated for `antd@5.13.4`. When that lands we
      // will be able to support this functionality.
      // Related PR: https://github.com/ant-design/ant-design/pull/47098
      return [
        // {
        //   title: '',
        //   key: 'dragHandle',
        //   fixed: 'left',
        //   width: 30,
        //   render: () => (
        //     <button className={styles.dragHandle}>
        //       <DragHandleIcon />
        //     </button>
        //   ),
        // },
        ...cols,
        {
          title: '',
          key: 'deleteButton',
          fixed: 'right',
          width: 50,
          render: (text, field) => (
            <ActionIcon color="red" icon={<DeleteIcon />} onClick={() => setDeleteRow(field)} />
          ),
        },
      ] as ColumnsType<ChannelMappingRuleDataType>;
    }

    return cols;
  }, [
    data,
    invalidRowKeys,
    isEditMode,
    onDataChange,
    setDeleteRow,
    updateDataPriority,
    scrollAndHighlightRow,
    onValidate,
  ]);

  const handleDeleteRow = () => {
    if (data && deleteRow) {
      onDataChange(updateDataPriority(data.filter((d) => d.id !== deleteRow.id)));
      setDeleteRow(undefined);
    }
  };

  return (
    <AutoSizer disableWidth>
      {({ height }) => (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <div style={{ position: 'absolute', overflow: 'auto', width: '100%' }}>
            <Table
              ref={tableRef}
              className={styles.table}
              columns={columns}
              dataSource={filteredData}
              emptyMessage={<Trans>No results found. Edit to add new rules.</Trans>}
              error={error}
              loading={isLoading}
              virtual
              scroll={{ y: height - 82 }}
              pagination={false}
              rowKey="id"
              rowClassName={(record) =>
                classNames({
                  [styles.rowHighlight]: isEditMode && highlightedRowKeys.indexOf(record.id) >= 0,
                  [styles.rowHighlightError]: isEditMode && invalidRowKeys.indexOf(record.id) >= 0,
                })
              }
            />
          </div>
          <ConfirmModal
            open={!!deleteRow}
            okText={<Trans>Yes, Delete</Trans>}
            onCancel={() => setDeleteRow(undefined)}
            onOk={handleDeleteRow}
          >
            <Trans>Are you sure you want to delete this rule?</Trans>
          </ConfirmModal>
        </div>
      )}
    </AutoSizer>
  );
};

export default ChannelMappingRulesTable;

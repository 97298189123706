import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import {
  CellChange,
  CellLocation,
  Column,
  DefaultCellTypes,
  Row,
  TextCell,
} from '@silevis/reactgrid';
import dayjs from 'dayjs';
import { useCallback, useMemo, useRef, useState } from 'react';

import { VendorSpendCellData, VendorSpendDataType } from '@/api/vendorSpend';
import LockIcon from '@/assets/svg/bx-lock.svg?react';
import ExcelGrid, {
  CurrencyCellInterface,
  IconCellInterface,
  getCell,
  getHeaderCell,
  getIconCell,
  getTextCell,
  getTotalCell,
} from '@/components/ExcelGrid';
import Tooltip from '@/components/Tooltip';
import { Link, Text } from '@/components/typography';
import { isCurrentMonth, isLastMonthOfYear } from '@/helper/dateHelper';
import { ROUTES } from '@/router';

import ConfirmDeleteRowModal from './ConfirmDeleteRowModal';
import styles from './VendorSpendTable.module.scss';
import useVendorSpendTableScrollPosition from './useVendorSpendTableScrollPosition';
import { fixedTableColumns, getColumnItem, getColumnWidth } from './util';

const ROW_ID_HEADER = 'header';
const ROW_ID_TOTAL = 'total';
const COL_ID_CHANNEL = 'channel';
const COL_ID_VENDOR = 'vendor';
const COL_ID_DELETE = 'delete';

const HEADER_ROW_HEIGHT = 32;
const BODY_ROW_HEIGHT = 56;
const DELETE_COLUMN_WIDTH = 48;

type Props = {
  data: VendorSpendDataType[];
  totalsData: VendorSpendCellData[];
  highlightedRowKeys: string[];
  editMode?: boolean;
  onDataChange: (newDate: VendorSpendDataType[]) => void;
  onHighlightedRowKeysChange: (highlightedRows: string[]) => void;
};

type ChangeIndex = {
  arrayIndex: number;
  vendorCellIndex: number;
  updateValue: number;
};

const VendorSpendTable = ({
  data,
  totalsData,
  highlightedRowKeys,
  editMode = false,
  onDataChange,
  onHighlightedRowKeysChange,
}: Props) => {
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [focusedItem, setFocusedItem] = useState<CellLocation | undefined>();
  const tableWrapperRef = useRef<HTMLDivElement | null>(null);
  const totalColumns = useMemo<Column[]>(
    () =>
      totalsData.map(
        (item, index): Column =>
          getColumnItem(
            index,
            isLastMonthOfYear(item.firstDayOfMonth),
            getColumnWidth(Math.round(item.amount).toString()),
          ),
      ),
    [totalsData],
  );
  useVendorSpendTableScrollPosition({ tableWrapperRef, data, totalsData, totalColumns });

  const deleteDisabled = data.length === 1;
  const focusedCell = editMode ? focusedItem : undefined;

  const headerRow = useMemo<Row>(() => {
    const cells = [
      getHeaderCell({ text: t`Channel`, isFixedHeader: true }),
      getHeaderCell({ text: t`Vendor`, isFixedHeader: true, isSorted: true }),
      ...totalsData.map(
        (item): DefaultCellTypes =>
          getHeaderCell({
            text: dayjs(item.firstDayOfMonth).utc().format("MMM 'YY"),
            isEoY: isLastMonthOfYear(item.firstDayOfMonth),
            currentMonth: isCurrentMonth(item.firstDayOfMonth),
          }),
      ),
    ];

    if (editMode && !deleteDisabled) {
      cells.push(getHeaderCell({ text: ' ' }));
    }

    return {
      rowId: ROW_ID_HEADER,
      height: HEADER_ROW_HEIGHT,
      cells,
    };
  }, [totalsData, editMode, deleteDisabled]);

  const totalRow = useMemo<
    Row<DefaultCellTypes | CurrencyCellInterface | IconCellInterface>
  >(() => {
    const cells: (DefaultCellTypes | CurrencyCellInterface | IconCellInterface)[] = [
      getTotalCell({
        text: t`Monthly Total:`,
        isFixedHeader: true,
        isExpanded: true,
      }),
      getTotalCell({ text: '', isFixedHeader: true }),
      ...totalsData.map((item) =>
        getTotalCell({
          text: item.amount.toString(),
          isEoY: isLastMonthOfYear(item.firstDayOfMonth),
          currentMonth: isCurrentMonth(item.firstDayOfMonth),
          isFixedHeader: false,
        }),
      ),
    ];

    if (editMode && !deleteDisabled) {
      cells.push(getIconCell({}));
    }

    return {
      rowId: ROW_ID_TOTAL,
      height: BODY_ROW_HEIGHT,
      cells,
    };
  }, [totalsData, editMode, deleteDisabled]);

  const valueRows = useMemo<
    Row<DefaultCellTypes | CurrencyCellInterface | IconCellInterface>[]
  >(() => {
    return data.map<Row<DefaultCellTypes | CurrencyCellInterface | IconCellInterface>>(
      (item, index) => {
        const isHighlighted = highlightedRowKeys.some((key) => key === item.id);
        const isDisabled = !item.data?.[0].userSupplied;

        const cells = [
          getTextCell({
            text: item.vendor.channel?.name || '',
            highlighted: isHighlighted,
            isFixedLeft: true,
            nonEditable: true,
            disabled: isDisabled,
            renderer: (text) => <div>{text}</div>,
          }),
          getTextCell({
            text: item.vendor.name,
            highlighted: isHighlighted,
            isFixedLeft: true,
            isSorted: true,
            nonEditable: true,
            disabled: isDisabled,
            renderer: isDisabled
              ? (text) => (
                  <div className={styles.lockCell}>
                    {text}{' '}
                    <Tooltip
                      placement="rightTop"
                      title={<Trans>{item.provider?.name} Integration</Trans>}
                      body={
                        <Trans>
                          {item.provider?.name} spend is currently automated. For more information
                          about the {item.provider?.name} Integration Click{' '}
                          <Link
                            variant="body2"
                            color="static-green"
                            to={ROUTES.integrationById(item.provider?.id)}
                          >
                            {t`Learn More`}
                          </Link>
                        </Trans>
                      }
                    >
                      <LockIcon className={styles.lockIcon} />
                    </Tooltip>
                  </div>
                )
              : (text) => <div>{text}</div>,
          }),
          ...item.data.map<DefaultCellTypes | CurrencyCellInterface | IconCellInterface>(
            (data, colIndex) =>
              getCell({
                text: data.amount?.toString() || '0',
                highlighted:
                  isHighlighted ||
                  (focusedCell?.rowId === index && focusedCell.columnId === colIndex),
                nonEditable: !editMode,
                isEoY: isLastMonthOfYear(data.firstDayOfMonth),
                currentMonth: isCurrentMonth(data.firstDayOfMonth),
                disabled: !data.userSupplied,
              }),
          ),
        ];

        if (editMode && !deleteDisabled) {
          if (isDisabled) {
            cells.push(getIconCell({ disabled: true }));
          } else {
            cells.push(
              getIconCell({ onIconClick: () => setDeleteRowId(item.id), disabled: false }),
            );
          }
        }

        return {
          rowId: index,
          height: BODY_ROW_HEIGHT,
          cells,
        };
      },
    );
  }, [data, editMode, highlightedRowKeys, focusedCell, deleteDisabled]);

  // ReactGrid doesn't support dynamic column width. Need to adjust width based on incoming values.
  // Rethink in the future.
  const headerWidth = useMemo(() => {
    return getColumnWidth(
      data.reduce((acc, data): string => {
        let max = data.vendor.name;
        if (max.length < (data.vendor.channel?.name.length || 0)) {
          max = data.vendor.channel?.name || '';
        }
        if (max.length < acc.length) {
          max = acc;
        }
        return max;
      }, ''),
    );
  }, [data]);

  const columns = useMemo(() => {
    const cols = [...fixedTableColumns(headerWidth), ...totalColumns];

    if (editMode && !deleteDisabled) {
      cols.push({ columnId: COL_ID_DELETE, width: DELETE_COLUMN_WIDTH });
    }

    return cols;
  }, [totalColumns, headerWidth, editMode, deleteDisabled]);

  const rows = data.length ? [headerRow, totalRow, ...valueRows] : [];

  const enableFillHandle =
    editMode &&
    focusedCell?.columnId !== COL_ID_VENDOR &&
    focusedCell?.columnId !== COL_ID_CHANNEL &&
    focusedCell?.rowId !== ROW_ID_HEADER &&
    focusedCell?.rowId !== ROW_ID_TOTAL;

  const handleFocusChange = useCallback(
    (location: CellLocation): boolean => {
      if (location.rowId !== ROW_ID_HEADER) {
        setFocusedItem(location);
      }
      if (highlightedRowKeys) {
        onHighlightedRowKeysChange([]);
      }
      // need to return true to keep focus change working on React Grid
      return true;
    },
    [highlightedRowKeys],
  );

  const handleCellsChanged = (cellChanges: CellChange[]) => {
    const changes = cellChanges.map<ChangeIndex>((change) => ({
      arrayIndex: Number(change.rowId),
      vendorCellIndex: Number(change.columnId),
      updateValue: Number((change.newCell as TextCell).text) || 0,
    }));

    const newData = data.map((item, index) => {
      const availableChanges = changes.filter((item) => item.arrayIndex === index);
      if (availableChanges.length) {
        return {
          ...item,
          data: item.data.map((config, index) => {
            const availableSpendDataChanges = availableChanges.filter(
              (item) => item.vendorCellIndex === index,
            );

            // arrayIndex and vendorCellIndex should point to only one cell
            if (availableSpendDataChanges.length >= 1) {
              return {
                amount: availableSpendDataChanges[0].updateValue,
                firstDayOfMonth: config.firstDayOfMonth,
                userSupplied: true, // users can't edit non-userSupplied cells
              };
            }
            return config;
          }),
        };
      }
      return item;
    });

    onDataChange(newData);
  };

  const handleDeleteVendorRow = (deletedVendorSpendRow: VendorSpendDataType) => {
    setDeleteRowId(null);
    onDataChange(data.filter((item) => item.id !== deletedVendorSpendRow.id));
  };

  if (!rows.length) {
    return (
      <div className={styles.emptyTable}>
        <Text variant="body2" weight="light" color="grey">
          <Trans>No Vendor Spend data found. Click the "Add Vendor" button to add spend.</Trans>
        </Text>
      </div>
    );
  }

  return (
    <div className={styles.tableWrapper} ref={tableWrapperRef}>
      <ExcelGrid
        stickyTopRows={2}
        stickyLeftColumns={2}
        stickyRightColumns={editMode && !deleteDisabled ? 1 : 0}
        rows={rows}
        columns={columns}
        onCellsChanged={handleCellsChanged}
        enableColumnSelection={false}
        enableRowSelection={false}
        enableRangeSelection={false}
        onFocusLocationChanging={handleFocusChange}
        horizontalStickyBreakpoint={40}
        enableFillHandle={enableFillHandle}
      />
      <ConfirmDeleteRowModal
        vendorSpendRow={
          deleteRowId != null ? data.find((row) => row.id === deleteRowId) : undefined
        }
        onDeleteVendorRow={handleDeleteVendorRow}
        onCancel={() => setDeleteRowId(null)}
      />
    </div>
  );
};

export default VendorSpendTable;

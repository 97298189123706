import { t } from '@lingui/core/macro';

import OpportunitiesOverviewContent from '@/app/opportunities/OpportunitiesOverviewContent';
import { Flex } from '@/components/Flex';
import { InstantSearch, InstantSearchSortOrder, type QueryState } from '@/components/InstantSearch';
import TableActions from '@/components/TableActions/TableActions';
import {
  Page,
  PageAudienceFilter,
  PageDateRangeFilter,
  PageFilterProvider,
} from '@/components/page';
import ShareMenu from '@/features/ShareMenu';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  size: 20,
  sortBy: 'createdAt',
  sortOrder: InstantSearchSortOrder.DESC,
};

const OpportunitiesOverview = () => {
  return (
    <Page title={t`Opportunities`}>
      <PageFilterProvider>
        <InstantSearch prefix="o" defaultQueryState={DEFAULT_QUERY_STATE}>
          <Flex vertical gap="large" fullWidth>
            <Flex justify="space-between">
              <PageDateRangeFilter />
              <Flex gap="small">
                <TableActions />
                <PageAudienceFilter />
                <ShareMenu notificationPageKey="opportunities" />
              </Flex>
            </Flex>
            <OpportunitiesOverviewContent />
          </Flex>
        </InstantSearch>
      </PageFilterProvider>
    </Page>
  );
};

export default OpportunitiesOverview;

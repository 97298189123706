import { Trans } from '@lingui/react/macro';

import { usePipelineKPIs } from '@/api/pipeline';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { numberFormat } from '@/helper/numberFormatter';

const PipelineKPIMetrics = () => {
  const { pageFilters } = usePageFilterContext();
  const { startDate, endDate } = pageFilters;
  const { pipelineKPIs, isLoading, error } = usePipelineKPIs(startDate, endDate);

  return (
    <KpiMetricGroup>
      <KpiMetric
        label={<Trans>Pipeline</Trans>}
        value={
          pipelineKPIs && numberFormat(pipelineKPIs.amount, { isCurrency: true, precision: 0 })
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Total Spend</Trans>}
        value={
          pipelineKPIs && numberFormat(pipelineKPIs.totalSpend, { isCurrency: true, precision: 0 })
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Return on Spend</Trans>}
        value={
          pipelineKPIs &&
          numberFormat(pipelineKPIs.returnOnSpend, { precision: 1, isMultiplier: true })
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Opportunities</Trans>}
        value={pipelineKPIs && numberFormat(pipelineKPIs.opportunities, { precision: 0 })}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Cost per Opportunity</Trans>}
        value={
          pipelineKPIs &&
          numberFormat(pipelineKPIs.costPerOpportunity, { isCurrency: true, precision: 0 })
        }
        isLoading={isLoading}
        error={error}
      />
    </KpiMetricGroup>
  );
};

export default PipelineKPIMetrics;

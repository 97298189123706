import { NavigableRouteType } from '@/router/types';

export const opportunities = {
  path: '/app/opportunities',
  description:
    'Displays all opportunities imported from a CRM connection and metrics associated with them.',
  navigable: false,
  searchParams: {
    audience: {
      type: 'string',
      description: 'An audience ID to filter the opportunities by',
      object: 'audience',
    },
  },
} satisfies NavigableRouteType<'audience'>;

export const opportunityDetail = {
  path: '/app/opportunities/:opportunity',
  description: 'Displays metrics for an individual opportunity.',
  navigable: false,
  pathParams: {
    opportunity: {
      type: 'string',
      description: 'The ID of the opportunity the user wishes to view',
      object: 'opportunity',
      required: true,
    },
  },
};

export const opportunityById = (opportunityId?: string) =>
  opportunityId && `${opportunities.path}/${encodeURIComponent(opportunityId)}`;

import { t } from '@lingui/core/macro';
import { useMemo } from 'react';

import { DashboardLayout, DashboardType } from '@/components/Dashboard';
import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';
import WidgetLibrary from '@/features/WidgetLibrary';
import { ROUTES } from '@/router';

const DashboardContent = () => {
  const hasAttributionFlag = useFeatureFlag(FEATURE_FLAG.attribution);

  const dashboard = useMemo<DashboardType>(
    () => ({
      id: 'dashboard',
      widgets: [
        {
          id: '0',
          type: 'cell',
          title: t`Pipeline Trend`,
          library: WidgetLibrary.PIPELINE_TRENDS_WIDGET,
          link: hasAttributionFlag
            ? undefined
            : {
                url: ROUTES.pipeline.path,
                title: t`Pipeline`,
              },
          showNotes: true,
          gridPos: { x: 0, y: 0, w: 12, h: 6 },
          verifyC99Tag: true,
        },
        {
          id: '1',
          type: 'cell',
          title: t`Traffic Summary`,
          library: WidgetLibrary.TRAFFIC_TRENDS_WIDGET,
          link: {
            url: ROUTES.webTraffic.path,
            title: t`Web Traffic`,
          },
          showNotes: true,
          gridPos: { x: 12, y: 0, w: 12, h: 6 },
          verifyC99Tag: true,
        },
        {
          id: '3',
          type: 'cell',
          title: t`Benchmarks`,
          library: WidgetLibrary.BENCHMARKS_WIDGET,
          link: {
            url: ROUTES.webTrafficBenchmarks.path,
            title: t`Benchmarks`,
          },
          gridPos: { x: 0, y: 16, w: 12, h: 10 },
        },
        {
          id: '4',
          type: 'cell',
          title: t`Closed Won`,
          library: WidgetLibrary.RECENT_DEALS_WIDGET,
          link: {
            url: ROUTES.closedWon.path,
            title: t`Closed Won`,
          },
          gridPos: { x: 12, y: 16, w: 12, h: 10 },
        },
      ],
    }),
    [hasAttributionFlag],
  );

  return <DashboardLayout dashboard={dashboard} isEditable={false} />;
};

export default DashboardContent;

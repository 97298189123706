import { DefaultOptionType } from 'antd/es/select';
import { useState } from 'react';

import { OptionType } from '@/api/common';

import { Option, Select, SelectProps } from '../Select';

export interface MultiSelectProps<T> extends Omit<SelectProps, 'mode' | 'options'> {
  defaultValue?: T[];
  value?: T[];
  isLoading?: boolean;
  options?: T[];
}

const MultiSelect = <T extends OptionType>({
  ref,
  defaultValue,
  value: valueFromProps,
  children,
  options,
  isLoading,
  onChange,
  ...rest
}: MultiSelectProps<T>) => {
  const isControlled = typeof valueFromProps !== 'undefined';
  const hasDefaultValue = typeof defaultValue !== 'undefined';

  const [internalValue, setInternalValue] = useState<T[] | undefined>(
    hasDefaultValue ? defaultValue : undefined,
  );

  const value = isControlled ? valueFromProps : internalValue;

  const handleChange = (
    selectedValues: DefaultOptionType[],
    options?: DefaultOptionType | DefaultOptionType[],
  ) => {
    const updatedValues = selectedValues.map(({ value, label, ...rest }) => {
      return {
        ...rest,
        id: value,
        name: label,
      };
    });

    const updatedOptions = options?.map((option: DefaultOptionType, i: number) => {
      // object wasn't found in the options list (maybe wasn't loaded yet). Add it in manually.
      if (Object.keys(option).length === 0) {
        return selectedValues[i];
      } else {
        return option;
      }
    });

    onChange?.(updatedValues, updatedOptions);

    if (!isControlled) {
      setInternalValue(selectedValues as T[]);
    }
  };

  return (
    <Select
      ref={ref}
      isLoading={isLoading}
      value={(value || [])?.map(({ id, name, ...rest }) => ({
        value: id,
        key: id,
        label: name,
        ...rest,
      }))}
      mode="multiple"
      allowClear
      onChange={handleChange}
      labelInValue
      showSearch
      {...rest}
    >
      {options
        ? (options || []).map((option) => (
            <Option key={option.id} value={option.id}>
              {option.name}
            </Option>
          ))
        : children}
    </Select>
  );
};

export default MultiSelect;

import ReactMarkdown from 'react-markdown';

import { Code, Link, Text, Title } from '@/components/typography';

import styles from './Markdown.module.scss';

type Props = {
  children: string;
};

const Markdown = ({ children }: Props) => {
  return (
    <div className={styles.markdown}>
      <ReactMarkdown
        components={{
          a: ({ children, href }) => (
            <Link variant="body1" to={href}>
              {children}
            </Link>
          ),
          b: ({ children }) => (
            <Text variant="body1" weight="bold">
              {children}
            </Text>
          ),
          body: ({ children }) => <Text variant="body1">{children}</Text>,
          code: ({ children, className }) => {
            const match = /language-(\w+)/.exec(className || '');
            const lang = className != null ? match?.[1] || 'text' : undefined;
            return <Code language={lang === 'markup' ? 'html' : lang}>{children}</Code>;
          },
          h1: ({ children }) => <Title level={1}>{children}</Title>,
          h2: ({ children }) => <Title level={2}>{children}</Title>,
          h3: ({ children }) => <Title level={3}>{children}</Title>,
          h4: ({ children }) => <Title level={4}>{children}</Title>,
          i: ({ children }) => (
            <Text variant="body1" italic>
              {children}
            </Text>
          ),
          link: ({ children, href }) => (
            <Link variant="body1" to={href}>
              {children}
            </Link>
          ),
          strong: ({ children }) => (
            <Text variant="body1" weight="extra-bold">
              {children}
            </Text>
          ),
        }}
      >
        {children}
      </ReactMarkdown>
    </div>
  );
};

export default Markdown;

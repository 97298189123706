import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Col } from 'antd';
import { useEffect, useState } from 'react';

import { NotificationSubscriptionDataType } from '@/api/settings';
import Form, { TextInput } from '@/components/Form';
import { maxObjectLongNameLength } from '@/constants';
import UserMultiSelect from '@/features/UserMultiSelect/UserMultiSelect';
import { useMe } from '@/providers/User';

import { FORM_FIELD, FormType } from './NotificationSubscriptionModal';

type Props = {
  subscription?: NotificationSubscriptionDataType | null;
  defaultReportName?: string;
  isSaving: boolean;
};

const NotificationSubscriptionFormCommon = ({
  defaultReportName,
  subscription,
  isSaving,
}: Props) => {
  const currentUser = useMe();
  const [isNameChanged, setIsNameChanged] = useState(false);
  const formInstance = Form.useFormInstance<FormType>();
  const isEditing = subscription?.id != null;

  useEffect(() => {
    if (!isEditing && !isNameChanged && defaultReportName) {
      formInstance.setFieldValue(FORM_FIELD.NAME, defaultReportName);
    }
  }, [defaultReportName, isNameChanged, isEditing]);

  return (
    <>
      <Col xs={24}>
        <Form.Item
          label={<Trans>Report Name</Trans>}
          name={FORM_FIELD.NAME}
          initialValue={subscription?.name ?? defaultReportName}
          rules={[{ required: true, message: <Trans>Name is Required</Trans> }]}
        >
          <TextInput
            placeholder={t`Name`}
            maxLength={maxObjectLongNameLength}
            isDisabled={isSaving}
            onChange={() => setIsNameChanged(true)}
          />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          label={<Trans>Recipients</Trans>}
          name={FORM_FIELD.RECIPIENTS}
          initialValue={subscription?.allRecipients || [currentUser]}
          rules={[
            {
              required: true,
              message: (
                <Trans>
                  Recipients is Required. At a minimum, you are required to be in the list.
                </Trans>
              ),
            },
          ]}
        >
          <UserMultiSelect
            placeholder={t`Choose Recipients`}
            isDisabled={isSaving}
            disableOwner
            allowClear={false}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default NotificationSubscriptionFormCommon;

import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useState } from 'react';

import {
  NotificationSubscriptionDataType,
  mutateNotificationDelete,
  mutateNotificationUnsubscribe,
} from '@/api/settings';
import { ConfirmModal } from '@/components/modals';
import { useMe } from '@/providers/User';

type Props = {
  subscription: NotificationSubscriptionDataType | null;
  onCancel: () => void;
  onDeleteComplete: (success: boolean, isSubscriptionOwner: boolean) => void;
};

const DeleteNotificationSubscriptionModal = ({
  subscription,
  onCancel,
  onDeleteComplete,
}: Props) => {
  const currentUser = useMe();
  const [isDeleting, setIsDeleting] = useState(false);
  const isSubscriptionOwner = subscription?.createdBy.id === currentUser?.id;

  const handleDelete = async () => {
    setIsDeleting(true);

    let success = false;

    try {
      if (isSubscriptionOwner) {
        success = await mutateNotificationDelete(subscription!.id);
      } else {
        success = await mutateNotificationUnsubscribe(subscription!.id);
      }
    } catch {
      success = false;
    }

    onDeleteComplete(isSubscriptionOwner, success);
    setIsDeleting(false);
  };

  const additionalRecipients = subscription?.additionalRecipients ?? [];

  return (
    <ConfirmModal
      open={subscription != null}
      okText={isSubscriptionOwner ? t`Yes, Delete` : t`Unsubscribe`}
      isLoading={isDeleting}
      onCancel={onCancel}
      onOk={handleDelete}
    >
      {isSubscriptionOwner ? (
        <div>
          <p>
            {additionalRecipients.length ? (
              <Trans>
                Deleting this notification will end delivery for you and the following recipients.
                Do you wish to proceed?
              </Trans>
            ) : (
              <Trans>
                Deleting this notification will end delivery for you. Do you wish to proceed?
              </Trans>
            )}
          </p>
          <p>
            <b>{additionalRecipients.map((subscriber) => subscriber.email).join(', ')}</b>
          </p>
        </div>
      ) : (
        <Trans>
          You are not the owner of this notification. Unsubscribing will only end notifications to
          you.
        </Trans>
      )}
    </ConfirmModal>
  );
};

export default DeleteNotificationSubscriptionModal;

/* eslint-disable no-restricted-imports */
import { Trans } from '@lingui/react/macro';
import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import classNames from 'classnames';
import { BaseSelectRef } from 'rc-select';
import { ReactElement, Ref } from 'react';

import CheckIcon from '@/assets/svg/check.svg?react';
import Chevron from '@/assets/svg/chevron-down.svg?react';
import CloseIcon from '@/assets/svg/cross.svg?react';
import { Tag } from '@/components/typography';

import styles from './Select.module.scss';

type AntdCustomTagProps = {
  label: React.ReactNode;
  value: string;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
};

export type SelectCustomTagProps = Omit<AntdCustomTagProps, 'disabled'> & {
  isDisabled: boolean;
  size: 'small' | 'medium' | 'large';
};

export type SelectProps = Omit<
  AntdSelectProps,
  'disabled' | 'loading' | 'size' | 'allowClear' | 'tagRender'
> & {
  ref?: Ref<BaseSelectRef>;
  allowClear?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  error?: boolean;
  size?: 'small' | 'medium' | 'large';
  width?: number | string | undefined;
  tagRender?: (props: SelectCustomTagProps) => ReactElement;
};

const Select = ({
  ref,
  className,
  style = {},
  allowClear = false,
  isDisabled = false,
  isLoading,
  notFoundContent,
  popupClassName,
  size = 'large',
  suffixIcon,
  tagRender,
  width,
  ...rest
}: SelectProps) => {
  const renderTag = ({ label, ...tagProps }: AntdCustomTagProps) => {
    if (tagRender) {
      return tagRender({ ...tagProps, isDisabled, label, size });
    } else {
      return (
        <Tag {...tagProps} size={size} isDisabled={isDisabled} variant="solid" color="success">
          {label}
        </Tag>
      );
    }
  };

  return (
    <AntdSelect
      tagRender={renderTag}
      {...rest}
      ref={ref}
      size={size === 'medium' ? 'middle' : size}
      loading={isLoading}
      disabled={isDisabled}
      notFoundContent={isLoading ? <Trans>Loading...</Trans> : notFoundContent}
      allowClear={allowClear && { clearIcon: <CloseIcon /> }}
      className={classNames(styles.select, className)}
      style={{ ...style, width: width ? width : undefined }}
      menuItemSelectedIcon={<CheckIcon className={styles.optionIcon} />}
      suffixIcon={suffixIcon === undefined ? <Chevron className={styles.suffixIcon} /> : suffixIcon}
      popupClassName={classNames(styles.popup, popupClassName, size && styles[`size-${size}`])}
    />
  );
};

export const Option = AntdSelect.Option;

export const getLoadingOption = () => (
  <AntdSelect.Option className={styles.loadingOption} key="loading..." value="loading..." disabled>
    <Trans>Loading...</Trans>
  </AntdSelect.Option>
);

export default Select;

import { Input, InputProps, InputRef } from 'antd';
import classNames from 'classnames';
import { Ref } from 'react';

import ClearIcon from '@/assets/svg/bx-x.svg?react';
import { maxDefaultLength } from '@/constants/numbers';

import styles from './TextInput.module.scss';

export type TextInputProps = Omit<
  InputProps,
  'disabled' | 'variant' | 'allowClear' | 'size' | 'showCount' | 'onChange'
> & {
  ref?: Ref<InputRef>;
  allowClear?: boolean;
  isDisabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
};

const TextInput = ({
  ref,
  className,
  style,
  allowClear = false,
  isDisabled,
  size = 'large',
  maxLength = maxDefaultLength,
  width,
  onChange,
  ...rest
}: TextInputProps) => {
  return (
    <Input
      {...rest}
      className={classNames(styles.input, className)}
      style={{ ...style, width: width ? width : undefined }}
      ref={ref}
      size={size === 'medium' ? 'middle' : size}
      variant="outlined"
      disabled={isDisabled}
      maxLength={maxLength}
      allowClear={allowClear && { clearIcon: <ClearIcon data-testid="search-clear" /> }}
      showCount={false}
      onChange={(e) => onChange?.(e.target.value, e)}
    />
  );
};

export default TextInput;

import gql from 'graphql-tag';
import { useMemo } from 'react';

import { InstantSearchSortOrder, SolQueryParamsNew } from '@/components/InstantSearch';
import { useSolInfiniteQuery, useSolQuery } from '@/hooks/useSolQuery';
import { useMe } from '@/providers/User';

import { fetcherSol } from '../sol-fetcher';
import { getVariablesFromTableParamsNew } from '../util/getVariablesFromTableParams';
import {
  UserDeleteGQLResponse,
  UserDeleteVariableType,
  UserDetailGQLResponse,
  UserInviteGQLResponse,
  UserInviteVariableType,
  UserListGQLResponse,
  UserOptionGQLResponseType,
  UserRoleType,
  UserUpdateGQLResponse,
  UserUpdateVariableType,
} from './userType';

export const useUserList = (tableParams?: SolQueryParamsNew) => {
  const variables = getVariablesFromTableParamsNew(tableParams);

  const { data, isLoading, error, mutate } = useSolQuery<UserListGQLResponse>({
    query:
      tableParams &&
      gql`
        query GetUserList($page: Pagination!, $sort: [SortParamInput]!, $searchQuery: String) {
          users {
            get(page: $page, sort: $sort, searchQuery: $searchQuery) {
              edges {
                node {
                  id
                  name
                  email
                  status
                  role
                }
              }
              totalEdges
            }
          }
        }
      `,
    variables: {
      page: variables?.page,
      sort: variables?.sort,
      searchQuery: variables?.searchQuery,
    },
  });

  return {
    userListData: data?.users.get.edges.map((edge) => edge.node),
    totalResults: data?.users.get.totalEdges,
    isLoading,
    error,
    mutate,
  };
};

export const useUserOptions = ({ search = '', pageSize = 50, disableOwner = false }) => {
  const currentUser = useMe();

  const { data, isLoading, isFetchingNextPage, error, fetchNextPage } =
    useSolInfiniteQuery<UserOptionGQLResponseType>({
      queryKey: ['userOptions', search],
      queryFn: ({ pageParam = 0, signal }) => {
        return {
          query: gql`
            query GetUserList($page: Pagination!, $sort: [SortParamInput]!, $searchQuery: String) {
              users {
                get(page: $page, sort: $sort, searchQuery: $searchQuery) {
                  edges {
                    node {
                      id
                      name
                      email
                    }
                  }
                  pageMeta {
                    hasNext
                  }
                }
              }
            }
          `,
          variables: getVariablesFromTableParamsNew({
            page: {
              offset: pageParam * pageSize,
              limit: pageSize,
            },
            sort: {
              direction: InstantSearchSortOrder.ASC,
              field: 'name',
            },
            filter: [],
            searchQuery: search,
          }),
          signal,
        };
      },
      getNextPageParam: (lastPage, pages) => {
        return lastPage.users.get.pageMeta.hasNext ? pages.length + 1 : undefined;
      },
    });

  const hasMore = data?.pages?.[data.pages.length - 1].users.get.pageMeta.hasNext;

  const userOptions = useMemo(() => {
    if (!data) {
      return [];
    }

    const users = data.pages
      .map((d) =>
        d.users.get.edges.map((edge) => ({
          ...edge.node,
          name: edge.node.id === currentUser?.id ? `${edge.node.name} (you)` : edge.node.name,
          disabled: disableOwner && edge.node.id === currentUser?.id,
        })),
      )
      .flat();

    return users;
  }, [data, currentUser, disableOwner]);

  return {
    userOptions,
    hasMore,
    isLoading,
    isFetchingNextPage,
    error,
    fetchNextPage,
  };
};

export const useUserById = (userId?: string) => {
  const { data, isLoading, error, mutate } = useSolQuery<UserDetailGQLResponse>({
    query:
      !!userId &&
      gql`
        query GetUserById($userId: String!) {
          users {
            getById(id: $userId) {
              id
              name
              email
              status
              role
            }
          }
        }
      `,
    variables: {
      userId,
    },
  });

  return {
    user: data?.users.getById,
    isLoading,
    error,
    mutate,
  };
};

export const mutateUserUpdate = async (id: string, name: string, role: UserRoleType) => {
  return await fetcherSol<UserUpdateVariableType, UserUpdateGQLResponse>({
    query: gql`
      mutation UpdateUser($user: UpdateUserInput!) {
        users {
          update(user: $user) {
            id
          }
        }
      }
    `,
    variables: {
      user: {
        id,
        name,
        role,
      },
    },
  });
};

export const mutateUserDelete = async (id: string) => {
  const response = await fetcherSol<UserDeleteVariableType, UserDeleteGQLResponse>({
    query: gql`
      mutation DeleteUser($deleteId: String!) {
        users {
          delete(id: $deleteId) {
            success
          }
        }
      }
    `,
    variables: {
      deleteId: id,
    },
  });

  return response.users.delete;
};

export const mutateUserInvite = async (name: string, email: string, role: UserRoleType) => {
  const response = await fetcherSol<UserInviteVariableType, UserInviteGQLResponse>({
    query: gql`
      mutation InviteUser($email: String!, $name: String!, $role: UserRole!) {
        users {
          invite(email: $email, name: $name, role: $role) {
            success
          }
        }
      }
    `,
    variables: {
      email,
      name,
      role,
    },
  });

  return response.users.invite;
};

import { t } from '@lingui/core/macro';

import { Flex } from '@/components/Flex';
import { InstantSearch, InstantSearchSortOrder, QueryState } from '@/components/InstantSearch';
import TableActions from '@/components/TableActions/TableActions';
import { Page, PageDateRangeFilter, PageFilterProvider } from '@/components/page';

import EventLogContent from './EventLogContent';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  size: 20,
  sortBy: 'occurredAt',
  sortOrder: InstantSearchSortOrder.DESC,
};

const EventLog = () => {
  return (
    <Page title={t`Settings`} pageName={t`Event Log`}>
      <InstantSearch prefix="e" defaultQueryState={DEFAULT_QUERY_STATE}>
        <PageFilterProvider>
          <Flex vertical gap="large" fullWidth>
            <Flex justify="space-between">
              <PageDateRangeFilter />
              <TableActions />
            </Flex>
            <EventLogContent />
          </Flex>
        </PageFilterProvider>
      </InstantSearch>
    </Page>
  );
};

export default EventLog;

import { BaseSelectRef } from 'rc-select';
import { Ref } from 'react';

import { TagTextArea } from '@/components/Form';
import { TagTextAreaProps } from '@/components/Form/selects/TagTextArea/TagTextArea';
import { Tag } from '@/components/typography';

import { isDomainValid } from './validators';

type Props = Omit<TagTextAreaProps, 'onChange'> & {
  ref?: Ref<BaseSelectRef>;
  onChange?: (values: string[]) => void;
};

const AudienceTextArea = ({ ref, isDisabled, tokenSeparators, onChange, ...props }: Props) => {
  const handleChange = (newValues: string[]) => {
    const sanitizedValues = newValues.map((value) => value.trim());

    if (onChange) {
      onChange(sanitizedValues);
    }
  };

  return (
    <TagTextArea
      ref={ref}
      isDisabled={isDisabled}
      tokenSeparators={tokenSeparators}
      onChange={handleChange}
      {...props}
      tagRender={({ label, ...props }) => (
        <Tag
          {...props}
          variant="solid"
          color={isDomainValid(label as string) ? 'success' : 'error'}
        >
          {label}
        </Tag>
      )}
    />
  );
};

export default AudienceTextArea;

import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes, ReactNode, Ref } from 'react';

import { TextColorType, TextVariantType, TextWeightType } from '../types';
import styles from './Text.module.scss';

type TextHTMLType = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

export type TextProps = TextHTMLType & {
  ref?: Ref<HTMLSpanElement>;
  variant: TextVariantType;
  color?: TextColorType;
  weight?: TextWeightType;
  italic?: boolean;
  underline?: boolean;
  uppercase?: boolean;
  lowercase?: boolean;
  pointer?: boolean;
  value?: ReactNode;
  isDisabled?: boolean;
};

const getDefaultWeight = (variant: TextVariantType): TextWeightType => {
  switch (variant) {
    case 'subhead':
      return 'bold';
    case 'kpi':
    case 'label':
    case 'footnote':
      return 'semi-bold';
    case 'headline':
    case 'body1':
    case 'body2':
    case 'caption1':
    case 'caption2':
      return 'regular';
  }
};

const Text = ({
  ref,
  className,
  variant,
  color = 'black',
  weight,
  italic,
  underline,
  uppercase,
  lowercase,
  pointer,
  isDisabled,
  value,
  children,
  ...rest
}: TextProps) => {
  return (
    <span
      {...rest}
      ref={ref}
      className={classNames(
        styles.text,
        styles[`variant-${variant}`],
        styles[`color-${color}`],
        styles[`weight-${weight ?? getDefaultWeight(variant)}`],
        {
          [styles.italic]: italic,
          [styles.underline]: underline,
          [styles.uppercase]: uppercase,
          [styles.lowercase]: lowercase,
          [styles.pointer]: pointer,
          [styles.disabled]: isDisabled,
        },
        className,
      )}
    >
      {children ?? value}
    </span>
  );
};

export default Text;

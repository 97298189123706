import { plural } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';

import { OpportunityType } from '@/api/opportunity';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  data?: OpportunityType | null;
  isLoading: boolean;
  error: Error | null;
};

const ClosedWonKPIMetrics = ({ data, isLoading, error }: Props) => {
  return (
    <KpiMetricGroup>
      <KpiMetric
        label={<Trans>Opportunity Amount</Trans>}
        value={data && numberFormat(data.amount, { isCurrency: true, precision: 0 })}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Days to Close</Trans>}
        value={data && plural(data.daysToClose ?? 0, { one: '# Day', other: '# Days' })}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Visits</Trans>}
        value={data && numberFormat(data.account?.visits)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Non-Digital Events</Trans>}
        value={data && numberFormat(data.offlineEvents)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Unique People</Trans>}
        value={data && numberFormat(data.account?.people)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Vendors</Trans>}
        value={data && numberFormat(data.account?.vendors)}
        isLoading={isLoading}
        error={error}
      />
    </KpiMetricGroup>
  );
};

export default ClosedWonKPIMetrics;

import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import { mutateOrganizationCreate } from '@/api/organization';
import SignInPanel from '@/app/sign-in/SignInPanel';
import Form from '@/components/Form';
import { FullPage } from '@/components/page';
import { Link, Text } from '@/components/typography';
import { sessionDurationMinutes } from '@/constants/numbers';
import { INSTANCE_ERROR_CODES } from '@/error/messages/instance-standard-errors';
import getSolStandardError from '@/error/sol-standard-errors';
import useLocationState from '@/hooks/useLocationState';
import { useNotification } from '@/providers/Notification';
import { ROUTES } from '@/router';

import styles from './ConfigureOrg.module.scss';
import ConfigureOrgForm from './ConfigureOrgForm';

const SAVE_TAKING_LONG_TIME_DELAY = 5000;

export enum FORM_FIELD {
  DOMAIN = 'domain',
}

export type FormType = {
  [FORM_FIELD.DOMAIN]: string;
};

const ConfigureOrg = () => {
  const { intermediateToken } = useLocationState<{ intermediateToken: string }>();
  const navigate = useNavigate();
  const stytch = useStytchB2BClient();
  const { pushNotification } = useNotification();
  const [form] = Form.useForm();
  const [isSaving, setSaving] = useState(false);
  const [saveIsTakingLongTime, setSaveIsTakingLongTime] = useState(false);
  const stillSavingTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const setCookie = (name: string, value: string, expirationMinutes: number) => {
    const now = new Date();
    const expirationDate = now.getTime() + expirationMinutes * 60 * 1000;
    document.cookie = `${name}=${value};expires=${expirationDate};path=/`;
  };

  const handleChange = async (values: FormType) => {
    try {
      setSaving(true);
      stillSavingTimeout.current = setTimeout(
        () => setSaveIsTakingLongTime(true),
        SAVE_TAKING_LONG_TIME_DELAY,
      );
      const response = await mutateOrganizationCreate(values[FORM_FIELD.DOMAIN], intermediateToken);
      const { data } = response ?? {};

      if (!data.success) {
        throw new Error();
      }

      setCookie('c99_session_jwt', data.sessionJwt, sessionDurationMinutes);
      setCookie('c99_session', data.sessionToken, sessionDurationMinutes);

      await stytch.session.authenticate({
        session_duration_minutes: sessionDurationMinutes,
      });
      await stytch.self.get();

      // We should be authenticated now, head to the app.
      navigate(ROUTES.app.path, { replace: true });
      // force a refresh
      navigate(0);
    } catch (e) {
      const solError = getSolStandardError(e);

      if (solError.extensions.code === INSTANCE_ERROR_CODES.INTERMEDIATE_SESSION_EXPIRED) {
        pushNotification({
          type: 'error',
          message: (
            <Trans>
              {solError.message}&nbsp;
              <Link
                variant="caption1"
                color="static-white"
                to={ROUTES.signUp.path}
              >{t`Try to sign up again?`}</Link>
            </Trans>
          ),
        });
      } else {
        pushNotification({
          type: 'error',
          message: solError.message,
        });
      }
    } finally {
      setSaving(false);
      setSaveIsTakingLongTime(false);

      if (stillSavingTimeout.current) {
        clearTimeout(stillSavingTimeout.current);
        stillSavingTimeout.current = undefined;
      }
    }
  };

  return (
    <FullPage title={t`Configure Organization`}>
      <SignInPanel
        title={<Trans>Enter your Company's Domain</Trans>}
        description={
          <Trans>
            Your domain will be used to create a new Account. Please use your top-level domain
            without subdomains.
          </Trans>
        }
      >
        <Form
          className={styles.form}
          form={form}
          validateTrigger="onSubmit"
          onFinish={handleChange}
        >
          <ConfigureOrgForm domainFieldName={FORM_FIELD.DOMAIN} isSaving={isSaving} />
        </Form>
        {isSaving && saveIsTakingLongTime && (
          <Text className={styles.saveWarning} variant="body2" weight="bold">
            <Trans>Please wait while we create your new account</Trans>
          </Text>
        )}
      </SignInPanel>
    </FullPage>
  );
};

export default ConfigureOrg;

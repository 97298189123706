import { Row } from 'antd';

import { AudienceOptionType } from '@/api/audience';
import {
  NotificationPageMeta,
  NotificationSubscriptionDataType,
  NotificationSubscriptionGoalDataType,
  NotificationSubscriptionRecurringDataType,
} from '@/api/settings';
import NotificationSubscriptionGoalForm from '@/features/NotificationSubscriptionModal/NotificationSubscriptionGoalForm';
import NotificationSubscriptionRecurringForm from '@/features/NotificationSubscriptionModal/NotificationSubscriptionRecurringForm';

type Props = {
  type: NotificationSubscriptionDataType['type'] | undefined;
  subscription?: NotificationSubscriptionDataType | null;
  audience?: AudienceOptionType;
  notificationPageMeta?: NotificationPageMeta;
  isSaving: boolean;
};

const NotificationSubscriptionForm = ({
  type,
  subscription,
  audience,
  notificationPageMeta,
  isSaving,
}: Props) => {
  const isEditing = subscription?.id != null;

  return (
    <Row gutter={[16, 16]}>
      {type === 'goal' ? (
        <NotificationSubscriptionGoalForm
          subscription={subscription as NotificationSubscriptionGoalDataType}
          audience={audience}
          notificationPageMeta={notificationPageMeta}
          isEditing={isEditing}
          isSaving={isSaving}
        />
      ) : (
        <NotificationSubscriptionRecurringForm
          subscription={subscription as NotificationSubscriptionRecurringDataType}
          audience={audience}
          notificationPageMeta={notificationPageMeta}
          isSaving={isSaving}
        />
      )}
    </Row>
  );
};

export default NotificationSubscriptionForm;

import { Trans } from '@lingui/react/macro';
import { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

import { useCurrentInstance } from '@/api/organization';
import { GlobalLoader } from '@/components/GlobalLoader';
import PageError from '@/components/errors/PageError';
import { PageHeader } from '@/components/page';
import { Layout } from '@/components/page';
import { getForeignSuperuserInstance } from '@/helper/user-preferences';
import { useMe } from '@/providers/User';
import { DEFAULT_APP_PATH, DEFAULT_ONBOARDING_PATH } from '@/router';

import styles from './Home.module.scss';
import LoginAsNotification from './LoginAsNotification';
import NotificationWrapper from './NotificationWrapper';
import { Sider } from './Sider';
import TagNotFoundNotification from './TagNotFoundNotification';

const { Content } = Layout;

export const Home = () => {
  const { pathname } = useLocation();
  const user = useMe();
  const { instance, isLoading, error } = useCurrentInstance();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const foreignSuperuserInstance = user?.isC99Superuser && getForeignSuperuserInstance();

  if (isLoading) {
    return <GlobalLoader />;
  }

  if (error) {
    return (
      <PageError
        message={<Trans>Unable to load the organization instance</Trans>}
        detail={
          <Trans>
            Please refresh the page. If this error continues to happen please contact support.
          </Trans>
        }
      />
    );
  }

  // DEFAULT ROUTE
  if (pathname === '/app' || pathname === '/app/') {
    if (!instance?.tagInstallationDate) {
      return <Navigate to={DEFAULT_ONBOARDING_PATH} replace />;
    }
    return <Navigate to={DEFAULT_APP_PATH} replace />;
  }

  return (
    <Layout className={styles.layout}>
      <PageHeader isMenuOpen={isMenuOpen} onToggleMenu={() => setIsMenuOpen((open) => !open)} />
      <Layout>
        <Sider isOpen={isMenuOpen} />
        <Content className={styles.content}>
          {foreignSuperuserInstance && (
            <LoginAsNotification instanceId={foreignSuperuserInstance.instanceId} />
          )}
          {!instance?.tagInstallationDate && <TagNotFoundNotification />}
          <NotificationWrapper />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

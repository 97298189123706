import dayjs from 'dayjs';

import { type OpportunityStatusType, OpportunityType } from '@/api/opportunity';
import { useCurrentInstance } from '@/api/organization';
import ClosedWonTrendComboCharts from '@/app/closed-won/ClosedWonTrendComboCharts';
import { Flex } from '@/components/Flex';
import { InstantSearch, InstantSearchSortOrder, type QueryState } from '@/components/InstantSearch';
import { DateISOFormat } from '@/constants';
import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';
import { OpportunityAttributionOfflineTable } from '@/features/OpportunityAttributionOfflineTable';
import { OpportunityAttributionOnlineTable } from '@/features/OpportunityAttributionOnlineTable';

const ONLINE_DEFAULT_QUERY_STATE: Partial<QueryState> = {
  sortBy: 'visits',
  sortOrder: InstantSearchSortOrder.DESC,
  size: 10,
};

const OFFLINE_DEFAULT_QUERY_STATE: Partial<QueryState> = {
  sortBy: 'visits',
  sortOrder: InstantSearchSortOrder.DESC,
  size: 10,
};

const getOffsetDate = (date?: string, offset = 0) => {
  const dayjsDate = dayjs(date);
  if (dayjsDate.isValid()) {
    if (offset) {
      return dayjsDate.subtract(offset, 'day').format(DateISOFormat);
    }
    return dayjsDate.format(DateISOFormat);
  }
  return undefined;
};

type Props = {
  opportunity?: OpportunityType;
  oppType?: OpportunityStatusType;
};

const ClosedWonContent = ({ opportunity, oppType }: Props) => {
  // When FEATURE_FLAG.attribution flag is removed, pull out the `oppType` logic from here and put
  // it in the parent component.
  const hasAttributionFlag = useFeatureFlag(FEATURE_FLAG.attribution);
  const { instance } = useCurrentInstance();
  const { openDate, closeDate } = opportunity ?? {};

  const startDate = getOffsetDate(openDate, instance?.pipelineReport?.influenceWindow ?? 90);
  const endDate = getOffsetDate(closeDate);

  return (
    <Flex gap="large" vertical>
      <ClosedWonTrendComboCharts opportunity={opportunity} />
      {hasAttributionFlag && (oppType === 'pipeline' || oppType === 'closedWon') && (
        <>
          <InstantSearch defaultQueryState={ONLINE_DEFAULT_QUERY_STATE} urlSync={false}>
            <OpportunityAttributionOnlineTable
              oppType={oppType}
              startDate={startDate}
              endDate={endDate}
              opportunity={opportunity}
            />
          </InstantSearch>
          <InstantSearch defaultQueryState={OFFLINE_DEFAULT_QUERY_STATE} urlSync={false}>
            <OpportunityAttributionOfflineTable
              oppType={oppType}
              startDate={startDate}
              endDate={endDate}
              opportunity={opportunity}
            />
          </InstantSearch>
        </>
      )}
    </Flex>
  );
};

export default ClosedWonContent;

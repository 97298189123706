import { t } from '@lingui/core/macro';

import { Flex } from '@/components/Flex';
import {
  Page,
  PageAudienceFilter,
  PageDateRangeFilter,
  PageFilterProvider,
} from '@/components/page';
import ShareMenu from '@/features/ShareMenu';

import WebTrafficDashboard from './WebTrafficDashboard';
import WebTrafficKPIMetrics from './WebTrafficKPIMetrics';

const WebTraffic = () => {
  return (
    <Page title={t`Analytics`} pageName={t`Web Traffic`} style={{ gap: 24 }}>
      <PageFilterProvider>
        <Flex vertical gap="large" fullWidth fullHeight>
          <Flex justify="space-between">
            <PageDateRangeFilter />
            <Flex gap="small">
              <PageAudienceFilter />
              <ShareMenu notificationPageKey="web-traffic" />
            </Flex>
          </Flex>
          <WebTrafficKPIMetrics />
          <WebTrafficDashboard />
        </Flex>
      </PageFilterProvider>
    </Page>
  );
};

export default WebTraffic;

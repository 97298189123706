import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useState } from 'react';

import {
  useCampaignOpportunitiesInfluenced,
  useCampaignOpportunitiesInfluencedList,
} from '@/api/campaign';
import { OpportunityStatusType } from '@/api/opportunity';
import { Radio } from '@/components/Form';
import { InstantSearchSortOrder, QueryState } from '@/components/InstantSearch';
import { DateRangeType, usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { OpportunityInfluenceChartTableCombo } from '@/features/OpportunityInfluenceChartTableCombo';

type Props = {
  campaignIds?: string[];
  isLoading?: boolean;
};

const CampaignOpportunityInfluenceChartTable = ({
  campaignIds,
  isLoading: isCampaignLoading,
}: Props) => {
  const {
    pageFilters: { startDate, endDate, audience },
  } = usePageFilterContext();

  const [tableState, setTableState] = useState<Omit<QueryState, 'search' | 'filters'>>({
    page: 1,
    size: 10,
    sortBy: 'date',
    sortOrder: InstantSearchSortOrder.DESC,
  });

  const [opportunityType, setOpportunityType] = useState<OpportunityStatusType>('pipeline');
  const [dateRangeOverride, setDateRangeOverride] = useState<DateRangeType>();

  const {
    campaignOpportunitiesInfluenced,
    isLoading: isCampaignOpportunitiesInfluencedLoading,
    error,
  } = useCampaignOpportunitiesInfluenced(campaignIds, startDate, endDate, audience?.id);

  const {
    opportunitiesListData,
    totalResults: opportunitiesListTotalResults,
    isLoading: opportunitiesListIsLoading,
    error: opportunitiesListError,
  } = useCampaignOpportunitiesInfluencedList(
    tableState,
    opportunityType,
    campaignIds,
    dateRangeOverride?.startDate ?? startDate,
    dateRangeOverride?.endDate ?? endDate,
    audience?.id,
  );

  const options: { value: OpportunityStatusType; label: string }[] = [
    { value: 'pipeline', label: t`Pipeline` },
    { value: 'closedWon', label: t`Closed Won` },
  ];

  return (
    <Panel
      size="L"
      title={<Trans>Opportunity Influence</Trans>}
      noPadding
      actions={
        <Radio.Group
          value={opportunityType}
          optionType="button"
          onChange={(e) => setOpportunityType(e.target.value)}
          options={options}
        />
      }
    >
      <OpportunityInfluenceChartTableCombo
        tableState={tableState}
        opportunityType={opportunityType}
        opportunitiesTimeseriesData={
          opportunityType === 'pipeline'
            ? campaignOpportunitiesInfluenced?.pipeline.data
            : campaignOpportunitiesInfluenced?.recentDeals.data
        }
        opportunitiesTimeseriesIsLoading={
          isCampaignLoading || isCampaignOpportunitiesInfluencedLoading
        }
        opportunitiesTimeseriesError={error}
        opportunitiesListData={opportunitiesListData}
        opportunitiesListTotalResults={opportunitiesListTotalResults}
        opportunitiesListIsLoading={isCampaignLoading || opportunitiesListIsLoading}
        opportunitiesListError={opportunitiesListError}
        onSelectedDateRangeChange={setDateRangeOverride}
        onTableStateChange={setTableState}
      />
    </Panel>
  );
};

export default CampaignOpportunityInfluenceChartTable;

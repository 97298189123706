import { BubbleSizeByValue, Options, PointOptionsObject, SeriesBubbleOptions } from 'highcharts';
import { ReactNode, useEffect } from 'react';

import { useFlareContext } from '@/components/Flare/FlareContext';
import {
  FlareDataPointOptions,
  FlareSeriesOptions,
  SanitizedFlareProps,
} from '@/components/Flare/types';

type BubbleProps = {
  x?: string;
  y?: string;
  z?: string;
  sizeBy?: BubbleSizeByValue;
  minSize?: number;
  maxSize?: number;
  showInteractionHalo?: boolean;
  loader?: ReactNode;
};

const buildBubbleOptions = (
  options: Options,
  parentProps: SanitizedFlareProps,
  props: BubbleProps,
): Options => {
  const { data, colors, parseX, parseY } = parentProps;
  const { x, y, z, sizeBy, minSize, maxSize, showInteractionHalo = true } = props;
  const series: SeriesBubbleOptions[] | undefined = data?.map(
    (series: FlareSeriesOptions, seriesIndex: number) => {
      const { name, data: seriesData, ...rest } = series;

      const newSeriesData = seriesData.map((item: FlareDataPointOptions) => {
        const newItem: PointOptionsObject = {
          ...item,
          x: x ? parseX(item[x]) : undefined,
          y: y ? parseY(item[y]) : undefined,
          z: z ? item[z] : undefined,
        };
        delete newItem[x as keyof PointOptionsObject];
        delete newItem[y as keyof PointOptionsObject];
        delete newItem[z as keyof PointOptionsObject];

        return newItem;
      });

      return {
        type: 'bubble',
        ...rest,
        name,
        color: colors[seriesIndex],
        lineColor: colors[seriesIndex],
        fillOpacity: 0.25,
        data: newSeriesData,
        clip: false,
        sizeBy,
        marker: {
          fillOpacity: 1,
          fillColor: colors[seriesIndex],
        },
      };
    },
  );

  return {
    ...options,
    chart: {
      ...options.chart,
      type: 'bubble',
    },
    series,
    plotOptions: {
      ...options.plotOptions,
      series: {
        ...options.plotOptions?.series,
      },
      bubble: {
        minSize,
        maxSize,
        marker: {
          states: {
            hover: {
              enabled: true,
              lineColor: '#ffffff',
              lineWidth: 1,
              radiusPlus: 1,
            },
            select: {
              enabled: true,
              fillColor: undefined,
              lineColor: '#ffffff',
              lineWidth: 1,
            },
          },
        },
        states: {
          hover: {
            enabled: showInteractionHalo,
            halo: {
              size: 6,
            },
          },
          select: {
            enabled: showInteractionHalo,
            halo: {
              size: 6,
              opacity: 0.25,
            },
          },
        },
      },
    },
  };
};

const Bubble = (props: BubbleProps) => {
  const { id, registerChild, isLoading } = useFlareContext();

  useEffect(() => {
    registerChild(id, (options: Options, parentProps: SanitizedFlareProps) =>
      buildBubbleOptions(options, parentProps, props),
    );
  }, [props]);

  if (isLoading) {
    if (props.loader) {
      return props.loader;
    }
    return null;
  }

  return null;
};

export default Bubble;

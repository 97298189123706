import { Trans } from '@lingui/react/macro';
import { useState } from 'react';

import { AudienceOptionType } from '@/api/audience';
import {
  NotificationInputType,
  NotificationPageMeta,
  NotificationSubscriptionDataType,
  mutateNotificationCreate,
  mutateNotificationUpdate,
} from '@/api/settings';
import { UserType } from '@/api/user';
import { Flex } from '@/components/Flex';
import Form from '@/components/Form';
import { Button } from '@/components/buttons';
import { Modal } from '@/components/modals';
import { Title } from '@/components/typography';
import NotificationSubscriptionForm from '@/features/NotificationSubscriptionModal/NotificationSubscriptionForm';
import { useMe } from '@/providers/User';

type Props = {
  type: NotificationSubscriptionDataType['type'] | undefined | null;
  subscription?: NotificationSubscriptionDataType | null;
  audience?: AudienceOptionType;
  notificationPageMeta?: NotificationPageMeta;
  onCancel: () => void;
  onSaveComplete: (success: boolean) => void;
};

export enum FORM_FIELD {
  NAME = 'name',
  RECIPIENTS = 'recipients',
  REPEATS = 'repeats',
  REPORTING_WINDOW = 'reportingWindow',
  DELIVERY_DAYS = 'deliveryDays',
  KPI = 'kpi',
  GOAL = 'goal',
  GOAL_START_DATE = 'goalStartDate',
}

export type FormType = {
  [FORM_FIELD.NAME]: string;
  [FORM_FIELD.RECIPIENTS]: UserType[];
  [FORM_FIELD.REPEATS]: string;
  [FORM_FIELD.REPORTING_WINDOW]: string;
  [FORM_FIELD.DELIVERY_DAYS]: string[];
};

const NotificationSubscriptionModal = ({
  type,
  subscription,
  audience,
  notificationPageMeta,
  onCancel,
  onSaveComplete,
}: Props) => {
  const currentUser = useMe();
  const [form] = Form.useForm<FormType>();
  const [isSaving, setIsSaving] = useState(false);
  const isEditing = subscription?.id != null;

  const handleFinish = async (values: FormType) => {
    setIsSaving(true);

    const notificationInput: NotificationInputType = {
      name: values[FORM_FIELD.NAME],
      additionalRecipients: values[FORM_FIELD.RECIPIENTS]
        .map((recipient) => recipient.id)
        .filter((recipientId) => recipientId !== currentUser?.id),
      repeatsWeeks: +values[FORM_FIELD.REPEATS],
      reportingWindowDays: +values[FORM_FIELD.REPORTING_WINDOW],
      sunday: values[FORM_FIELD.DELIVERY_DAYS].includes('0'),
      monday: values[FORM_FIELD.DELIVERY_DAYS].includes('1'),
      tuesday: values[FORM_FIELD.DELIVERY_DAYS].includes('2'),
      wednesday: values[FORM_FIELD.DELIVERY_DAYS].includes('3'),
      thursday: values[FORM_FIELD.DELIVERY_DAYS].includes('4'),
      friday: values[FORM_FIELD.DELIVERY_DAYS].includes('5'),
      saturday: values[FORM_FIELD.DELIVERY_DAYS].includes('6'),
    };

    let success = false;

    try {
      if (isEditing) {
        const response = await mutateNotificationUpdate({
          ...notificationInput,
          id: subscription?.id,
        });
        success = !!response;
      } else {
        const urlPath = window.location.pathname + window.location.search;
        const urlTemplate = `${urlPath}${urlPath.indexOf('?') === -1 ? '?' : '&'}startDate={startDate}&endDate={endDate}`;

        const response = await mutateNotificationCreate({
          ...notificationInput,
          urlTemplate,
        });
        success = !!response;
      }
    } catch {
      success = false;
    }

    onSaveComplete(success);
    setIsSaving(false);
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={type != null}
      width={667}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancelButton"
          color="black"
          variant="secondary"
          size="large"
          isDisabled={isSaving}
          onClick={handleCancel}
        >
          <Trans>Cancel</Trans>
        </Button>,
        <Button
          key="okButton"
          color="black"
          variant="primary"
          size="large"
          isLoading={isSaving}
          onClick={() => form.submit()}
        >
          <Trans>Save</Trans>
        </Button>,
      ]}
    >
      <Flex gap="large" vertical>
        <Title level={2} weight="light">
          {subscription?.type === 'goal' ? (
            isEditing ? (
              <Trans>Edit Goal Notification</Trans>
            ) : (
              <Trans>Goal Notification</Trans>
            )
          ) : isEditing ? (
            <Trans>Edit Notification</Trans>
          ) : (
            <Trans>Schedule Email</Trans>
          )}
        </Title>
        <Form form={form} validateTrigger={['onBlur', 'onChange']} onFinish={handleFinish}>
          {type && (
            <NotificationSubscriptionForm
              type={type}
              subscription={subscription}
              audience={audience}
              notificationPageMeta={notificationPageMeta}
              isSaving={isSaving}
            />
          )}
        </Form>
      </Flex>
    </Modal>
  );
};

export default NotificationSubscriptionModal;

import { InputNumber, InputNumberProps } from 'antd';
import classNames from 'classnames';
import { Ref } from 'react';

import { maxDefaultLength } from '@/constants/numbers';

import styles from './NumberInput.module.scss';

export type NumberInputProps = Omit<
  InputNumberProps,
  'variant' | 'size' | 'disabled' | 'suffix'
> & {
  ref?: Ref<HTMLInputElement>;
  isDisabled?: boolean;
  size?: 'small' | 'medium' | 'large';
};

const NumberInput = ({
  ref,
  className,
  style = {},
  isDisabled = false,
  size = 'large',
  maxLength = maxDefaultLength,
  width,
  ...rest
}: NumberInputProps) => {
  return (
    <InputNumber
      {...rest}
      className={classNames(styles.input, className)}
      style={{ ...style, width: width ? width : undefined }}
      ref={ref}
      variant="outlined"
      size={size === 'medium' ? 'middle' : size}
      disabled={isDisabled}
      maxLength={maxLength}
    />
  );
};

export default NumberInput;

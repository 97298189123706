import { t } from '@lingui/core/macro';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router';

import { OpportunityStatusType } from '@/api/opportunity';
import AttributionBreakdownTables from '@/app/attribution/AttributionBreakdownTables';
import AttributionKPIMetrics from '@/app/attribution/AttributionKPIMetrics';
import AttributionTrendsChart from '@/app/attribution/AttributionTrendsChart';
import { Flex } from '@/components/Flex';
import {
  Page,
  PageAudienceFilter,
  PageDateRangeFilter,
  PageFilterProvider,
} from '@/components/page';
import { OpportunityTypeSelect } from '@/features/OpportunityTypeSelect';
import ShareMenu from '@/features/ShareMenu';

const AttributionOverview = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const oppType = searchParams.get('oppType');

  useEffect(() => {
    if (oppType !== 'pipeline' && oppType !== 'closedWon') {
      setSearchParams({ oppType: 'pipeline' }, { replace: true });
    }
  }, [oppType]);

  const handleOppTypeChange = (value: OpportunityStatusType) => {
    setSearchParams({ oppType: value });
  };

  if (oppType !== 'pipeline' && oppType != 'closedWon') {
    return null;
  }

  return (
    <Page title={t`Analytics`} pageName={t`Attribution`}>
      <Flex gap="large" vertical fullWidth>
        <PageFilterProvider>
          <Flex justify="space-between">
            <PageDateRangeFilter />
            <Flex gap="small">
              <OpportunityTypeSelect value={oppType} onChange={handleOppTypeChange} />
              <PageAudienceFilter />
              <ShareMenu notificationPageKey="attribution" />
            </Flex>
          </Flex>
          <AttributionKPIMetrics />
          <AttributionTrendsChart oppType={oppType} />
          <AttributionBreakdownTables oppType={oppType} />
        </PageFilterProvider>
      </Flex>
    </Page>
  );
};

export default AttributionOverview;

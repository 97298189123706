import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import type { RadioChangeEvent } from 'antd';
import { useMemo, useState } from 'react';

import type { GenericActivityDataType } from '@/api/attribution';
import type { AudienceOptionType } from '@/api/audience';
import { useCampaignActivity } from '@/api/campaign';
import type { OpportunityStatusType, OpportunityType } from '@/api/opportunity';
import { useVendorActivity } from '@/api/vendor';
import { Radio } from '@/components/Form';
import { InstantSearchSortOrder, useInstantSearchState } from '@/components/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@/components/InstantSearch/util/search-util';
import { Panel } from '@/components/panels';

import OpportunityAttributionOnlineTable from './OpportunityAttributionOnlineTable';

type Props = {
  oppType: OpportunityStatusType;
  startDate?: string;
  endDate?: string;
  audience?: AudienceOptionType;
  opportunity?: OpportunityType;
};

const OpportunityAttributionOnlineTableContainer = ({
  oppType,
  startDate,
  endDate,
  audience,
  opportunity,
}: Props) => {
  const { queryState, updateQueryState } = useInstantSearchState();
  const [breakdownObject, setBreakdownObject] = useState<'vendor' | 'campaign'>('vendor');

  const breakdownOptionsObject = useMemo<{ value: 'vendor' | 'campaign'; label: string }[]>(
    () => [
      { value: 'vendor', label: t`Vendor` },
      { value: 'campaign', label: t`Campaign` },
    ],
    [],
  );

  const {
    vendorActivity,
    totalResults: totalVendorResults,
    isLoading: isLoadingVendor,
    error: errorVendor,
  } = useVendorActivity(
    breakdownObject === 'vendor' ? getSolQueryParamsNewFromQueryState(queryState) : undefined,
    startDate,
    endDate,
    audience?.id,
    opportunity?.id,
  );
  const {
    campaignActivity,
    totalResults: totalCampaignResults,
    isLoading: isLoadingCampaign,
    error: errorCampaign,
  } = useCampaignActivity(
    breakdownObject === 'campaign' ? getSolQueryParamsNewFromQueryState(queryState) : undefined,
    startDate,
    endDate,
    audience?.id,
    opportunity?.id,
  );

  const data = useMemo<GenericActivityDataType[] | undefined>(() => {
    return breakdownObject === 'vendor'
      ? vendorActivity?.map<GenericActivityDataType>(({ vendor, ...rest }) => ({
          ...rest,
          id: vendor.id,
          name: `${vendor.name} - ${vendor.channel?.name}`,
        }))
      : campaignActivity?.map<GenericActivityDataType>(({ ad, ...rest }) => ({
          ...rest,
          id: ad.id,
          name: ad.name,
        }));
  }, [vendorActivity, campaignActivity, breakdownObject]);

  const sortedData = useMemo(() => {
    // Vendor needs to be sorted by channel AND vendor. We only sort by vendor name, so this
    // client-side sort will order channel names too.
    if (queryState.sortBy === 'name' && breakdownObject === 'vendor') {
      return data?.toSorted(
        (a, b) =>
          a.name.localeCompare(b.name) *
          (queryState.sortOrder === InstantSearchSortOrder.ASC ? 1 : -1),
      );
    }
    return data;
  }, [queryState.sortBy, queryState.sortOrder, data]);

  const handleBreakdownChange = (e: RadioChangeEvent) => {
    setBreakdownObject(e.target.value);
    updateQueryState({
      ...queryState,
      filters: [],
      page: 1,
    });
  };

  const title =
    breakdownObject === 'vendor' ? t`Attribution by Vendor` : t`Attribution by Campaign`;

  return (
    <Panel
      size="L"
      title={title}
      infoPopover={{
        title,
        body: (
          <Trans>
            Attribution is shown for each attribution model on this table. This takes into account
            all interactions during each opportunity's timeline from the start of the Opportunity
            influence window to the opportunity creation date (for Pipeline) or closed date (for
            Closed Won).
          </Trans>
        ),
      }}
      actions={
        <Radio.Group
          value={breakdownObject}
          optionType="button"
          onChange={handleBreakdownChange}
          options={breakdownOptionsObject}
        />
      }
    >
      <OpportunityAttributionOnlineTable
        oppType={oppType}
        breakdownObject={breakdownObject}
        data={sortedData}
        totalResults={breakdownObject === 'vendor' ? totalVendorResults : totalCampaignResults}
        isLoading={breakdownObject === 'vendor' ? isLoadingVendor : isLoadingCampaign}
        error={breakdownObject === 'vendor' ? errorVendor : errorCampaign}
      />
    </Panel>
  );
};

export default OpportunityAttributionOnlineTableContainer;

import dayjs from 'dayjs';
import dayjsIsBetween from 'dayjs/plugin/isBetween';
import dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjsUTC from 'dayjs/plugin/utc';

dayjs.extend(dayjsUTC);
dayjs.extend(dayjsIsBetween);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(relativeTime);

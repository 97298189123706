import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useMemo } from 'react';

import { useChannelList } from '@/api/channel';
import { Option, Select, SelectProps } from '@/components/Form';

interface Props extends Omit<SelectProps, 'mode'> {
  value?: string[];
}

const ChannelMultiSelect = ({ value, ...rest }: Props) => {
  const { channels, isLoading } = useChannelList();
  const channelNames = useMemo(() => channels?.map((chan) => chan.name) || [], [channels]);

  return (
    <Select
      isLoading={isLoading}
      value={value}
      mode="multiple"
      placeholder={t`Select Channel...`}
      notFoundContent={<Trans>No channels were found...</Trans>}
      showSearch
      {...rest}
    >
      {channelNames.map((channel) => (
        <Option key={channel} value={channel}>
          {channel}
        </Option>
      ))}
    </Select>
  );
};

export default ChannelMultiSelect;

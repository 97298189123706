import { Column } from '@silevis/reactgrid';
import dayjs from 'dayjs';
import { MutableRefObject, useEffect, useRef } from 'react';

import { VendorSpendCellData, VendorSpendDataType } from '@/api/vendorSpend';

type Props = {
  tableWrapperRef: MutableRefObject<HTMLDivElement | null>;
  data: VendorSpendDataType[];
  totalsData: VendorSpendCellData[];
  totalColumns: Column[];
};

/**
 * Sets the initial scroll position to reveal the previous month, the current month, and as many of
 * the future 12 months as the browser window size will allow.
 */
const useVendorSpendTableScrollPosition = ({
  tableWrapperRef,
  totalsData,
  totalColumns,
}: Props) => {
  const previousData = useRef<VendorSpendCellData[] | null>(null);

  useEffect(() => {
    // Wait until the first load of data is complete. We'll only scroll on first load.
    if (tableWrapperRef.current && !previousData.current && totalsData) {
      previousData.current = totalsData;

      // Find the location of the "last month" column. We don't need to worry about the fixed
      // columns since they are "fixed" and thus out of the scrollable region. Only value columns
      // are scrollable.
      const lastMonth = dayjs().utc().subtract(1, 'month').startOf('month').toISOString();
      const lastMonthColumnIndex = totalsData.findIndex((d) => d.firstDayOfMonth === lastMonth);

      let scrollXPos = 0;
      for (let i = 0; i < lastMonthColumnIndex; i++) {
        const col = totalColumns[i];
        if (col?.width != null) {
          scrollXPos += col.width || 0;
        }
      }

      tableWrapperRef.current.scrollLeft = scrollXPos;
    }
  }, [totalsData, totalColumns]);
};

export default useVendorSpendTableScrollPosition;

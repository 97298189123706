import * as analyticsRoutes from './analyticsRoutes';
import * as appRoutes from './appRoutes';
import * as audienceRoutes from './audienceRoutes';
import * as campaignRoutes from './campaignRoutes';
import * as channelRoutes from './channelRoutes';
import * as dashboardRoutes from './dashboardRoutes';
import * as opportunityRoutes from './opportunityRoutes';
import * as pixelRoutes from './pixelRoutes';
import * as settingsRoutes from './settingsRoutes';
import * as susiRoutes from './susiRoutes';
import * as userRoutes from './userRoutes';
import * as vendorRoutes from './vendorRoutes';

export const ROUTES = {
  ...analyticsRoutes,
  ...appRoutes,
  ...audienceRoutes,
  ...campaignRoutes,
  ...channelRoutes,
  ...dashboardRoutes,
  ...opportunityRoutes,
  ...pixelRoutes,
  ...settingsRoutes,
  ...susiRoutes,
  ...userRoutes,
  ...vendorRoutes,
};

export const DEFAULT_APP_PATH = ROUTES.dashboard.path;
export const DEFAULT_ONBOARDING_PATH = ROUTES.healthCenter.path;

export const CHAT_AGENT_INSTRUCTIONS = `
I would like to teach you about the routes in Channel99's web application. When I ask you where I
might go to receive an answer to my question, I would like you to respond with a \`url\` string
only unless the question is not relevant to my application, in which case respond with \`N/A\`
instead. The base path of the url is \`https://app.channel99.com\`.

Some paths are not intended to be navigated to directly, these are marked as \`navigable: false\`.
Please don't suggest them to me even if I ask for them.

\`searchParams\` are inclusive by default, however they can become exclusive by adding a \`!\`
character to the end of the field name in the search parameter. They can only be inclusive or
exclusive, not both. A parameter of type \`range\` can only be inclusive using square bracket
notation, exclusive is not supported yet.
`;

import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useMemo } from 'react';

import type { AudienceOptionType } from '@/api/audience';
import type { OpportunityStatusType, OpportunityType } from '@/api/opportunity';
import { type OfflineActivityDataType, useOfflineActivity } from '@/api/vendor';
import { InstantSearchTable, useInstantSearchState } from '@/components/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@/components/InstantSearch/util/search-util';
import { type ColumnsType, FilterDropdown } from '@/components/Table';
import { RangeFilter } from '@/components/filters';
import { Panel } from '@/components/panels';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  oppType: OpportunityStatusType;
  startDate?: string;
  endDate?: string;
  audience?: AudienceOptionType;
  opportunity?: OpportunityType;
};

const AttributionOfflineBreakdownTable = ({
  oppType,
  startDate,
  endDate,
  audience,
  opportunity,
}: Props) => {
  const { queryState } = useInstantSearchState();

  const { offlineActivity, isLoading, error } = useOfflineActivity(
    getSolQueryParamsNewFromQueryState(queryState),
    startDate,
    endDate,
    audience?.id,
    opportunity?.id,
  );

  const columns: ColumnsType<OfflineActivityDataType> = useMemo(
    () => [
      {
        title: t`Campaign`,
        key: 'campaignTypeId',
        width: 240,
        fixed: 'left',
        render: (_, field) => `${field.campaignType.vendor.name} - ${field.campaignType.name}`,
      },
      {
        title: t`Occurrences/Events`,
        key: 'visits',
        width: 130,
        align: 'right',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text),
      },
      {
        title: t`Pipeline Influence`,
        key: 'pipelineInfluenced',
        width: 120,
        align: 'right',
        hidden: oppType === 'closedWon',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Closed Won Influence`,
        key: 'closedWonInfluenced',
        width: 120,
        align: 'right',
        hidden: oppType === 'pipeline',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`First-Touch Attribution`,
        key: 'pipelineFirstTouchAttribution',
        width: 120,
        align: 'right',
        hidden: oppType === 'closedWon',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`First-Touch Attribution`,
        key: 'closedWonFirstTouchAttribution',
        width: 120,
        align: 'right',
        hidden: oppType === 'pipeline',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Last-Touch Attribution`,
        key: 'pipelineLastTouchAttribution',
        width: 120,
        align: 'right',
        hidden: oppType === 'closedWon',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Linear Attribution`,
        key: 'pipelineLinearAttribution',
        width: 120,
        align: 'right',
        hidden: oppType === 'closedWon',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Linear Attribution`,
        key: 'closedWonLinearAttribution',
        width: 120,
        align: 'right',
        hidden: oppType === 'pipeline',
        sorter: true,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
    ],
    [oppType],
  );

  const title = t`Attribution by Non-Digital Event`;

  return (
    <Panel
      size="L"
      title={title}
      infoPopover={{
        title,
        body: (
          <Trans>
            Attribution is shown for each attribution model on this table. This takes into account
            all interactions during each opportunities' timeline from the start of the Opportunity
            influence window to the opportunity creation date (for Pipeline) or closed date (for
            Closed Won).
          </Trans>
        ),
      }}
    >
      <InstantSearchTable<OfflineActivityDataType>
        data-testid="offline-table"
        columns={columns}
        dataSource={offlineActivity}
        loading={isLoading}
        error={error}
        emptyMessage={<Trans>No non-digital events found</Trans>}
        rowKey={(record) => record.campaignType.id}
        pagination={false}
        sticky
        scroll={{ x: 700 }}
      />
    </Panel>
  );
};

export default AttributionOfflineBreakdownTable;

import { t } from '@lingui/core/macro';
import { useMemo, useState } from 'react';

import { PageMetaNotificationNameType, useNotificationPageMeta } from '@/api/settings';
import EnvelopeIcon from '@/assets/svg/bx-envelope.svg?react';
import ShareIcon from '@/assets/svg/bx-share.svg?react';
import TargetLockIcon from '@/assets/svg/bx-target-lock.svg?react';
import { Dropdown } from '@/components/Dropdown';
import { ActionButton } from '@/components/buttons';
import { usePageFilterContext } from '@/components/page';
import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';
import NotificationSubscriptionModal from '@/features/NotificationSubscriptionModal';
import { useNotification } from '@/providers/Notification';

type Props = {
  notificationPageKey: PageMetaNotificationNameType;
};

type ItemKey = 'recurring' | 'goal';

const ShareMenu = ({ notificationPageKey }: Props) => {
  const notificationPageMeta = useNotificationPageMeta(notificationPageKey);
  const hasNotificationsFlag = useFeatureFlag(FEATURE_FLAG.notifications);
  const hasGoalNotificationsFlag = useFeatureFlag(FEATURE_FLAG.goalNotifications);
  const hasGoalNotifications = notificationPageMeta.kpiMetrics != null;
  const {
    pageFilters: { audience },
  } = usePageFilterContext();
  const [selectedMenuItem, setSelectedMenuItem] = useState<ItemKey | null>(null);
  const { pushNotification } = useNotification();

  const handleSaveComplete = (success: boolean) => {
    setSelectedMenuItem(null);
    if (success) {
      pushNotification({ type: 'success', message: t`Notification scheduled successfully` });
    } else {
      pushNotification({
        type: 'error',
        message: t`Failed to schedule notification. Please try again or contact support if you encounter the issue again.`,
      });
    }
  };

  const menuItems = useMemo(() => {
    const goalItem = {
      key: 'goal' satisfies ItemKey,
      label: t`Goal Notification`,
      icon: <TargetLockIcon />,
    };
    const recurringItem = {
      key: 'recurring' satisfies ItemKey,
      label: t`Schedule Email`,
      icon: <EnvelopeIcon />,
    };

    return hasGoalNotificationsFlag && hasGoalNotifications
      ? [goalItem, recurringItem]
      : [recurringItem];
  }, [hasGoalNotificationsFlag]);

  if (!hasNotificationsFlag) {
    return null;
  }

  return (
    <>
      <Dropdown
        menu={{
          items: menuItems,
          onClick: ({ key }) => setSelectedMenuItem(key as ItemKey),
        }}
      >
        <ActionButton size="large" color="black" icon={<ShareIcon />} />
      </Dropdown>
      <NotificationSubscriptionModal
        type={selectedMenuItem}
        audience={audience}
        notificationPageMeta={notificationPageMeta}
        onCancel={() => setSelectedMenuItem(null)}
        onSaveComplete={handleSaveComplete}
      />
    </>
  );
};

export default ShareMenu;

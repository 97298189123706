import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Col } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';

import { AudienceOptionType } from '@/api/audience';
import { NotificationPageMeta, NotificationSubscriptionRecurringDataType } from '@/api/settings';
import Form, { DayChooser, Select } from '@/components/Form';
import { useHeaderContext } from '@/hooks/header/HeaderContext';

import NotificationSubscriptionFormCommon from './NotificationSubscriptionFormCommon';
import { FORM_FIELD } from './NotificationSubscriptionModal';

type Props = {
  subscription?: NotificationSubscriptionRecurringDataType | null;
  audience?: AudienceOptionType;
  notificationPageMeta?: NotificationPageMeta;
  isSaving: boolean;
};

const NotificationSubscriptionRecurringForm = ({
  subscription,
  audience,
  notificationPageMeta,
  isSaving,
}: Props) => {
  const { title } = useHeaderContext();
  const reportingWindowOptions = useMemo<{ value: string; label: string }[]>(
    () => [
      { value: '7', label: t`Last 7 Days` },
      { value: '14', label: t`Last 14 Days` },
      { value: '30', label: t`Last 30 Days` },
      { value: '60', label: t`Last 60 Days` },
      { value: '90', label: t`Last 90 Days` },
      { value: '180', label: t`Last 180 Days` },
    ],
    [],
  );

  const [reportingWindow, setReportingWindow] = useState<{ value: string; label: string }>(
    reportingWindowOptions[2],
  );

  const getDefaultReportName = useCallback(() => {
    const reportNameParts: string[] = [];
    if (title) {
      reportNameParts.push(title);
    }
    if (!notificationPageMeta?.hasNoAudienceFilter) {
      if (audience?.name) {
        reportNameParts.push(audience.name);
      } else {
        reportNameParts.push(t`TAM`);
      }
    }
    if (reportingWindow.label) {
      reportNameParts.push(reportingWindow.label);
    }
    return reportNameParts.join(' - ');
  }, [notificationPageMeta, title, audience?.name, reportingWindow?.label]);

  return (
    <>
      <NotificationSubscriptionFormCommon
        subscription={subscription}
        defaultReportName={getDefaultReportName()}
        isSaving={isSaving}
      />
      <Col md={8} sm={10} xs={12}>
        <Form.Item
          label={<Trans>Repeats</Trans>}
          name={FORM_FIELD.REPEATS}
          initialValue={subscription?.repeatsWeeks || '1'}
          rules={[{ required: true, message: <Trans>Repeats is Required</Trans> }]}
        >
          <Select
            placeholder={t`Choose Interval`}
            options={[
              { value: '1', label: t`Every Week` },
              { value: '2', label: t`Every 2 Weeks` },
              { value: '3', label: t`Every 3 Weeks` },
              { value: '4', label: t`Every 4 Weeks` },
              { value: '5', label: t`Every 5 Weeks` },
              { value: '6', label: t`Every 6 Weeks` },
              { value: '7', label: t`Every 7 Weeks` },
              { value: '8', label: t`Every 8 Weeks` },
            ]}
            isDisabled={isSaving}
          />
        </Form.Item>
      </Col>
      <Col md={8} sm={10} xs={12}>
        <Form.Item
          label={<Trans>Reporting Window</Trans>}
          name={FORM_FIELD.REPORTING_WINDOW}
          initialValue={subscription?.reportingWindowDays || '30'}
          onReset={() => setReportingWindow(reportingWindowOptions[2])}
        >
          <Select
            placeholder={t`Choose Reporting Window`}
            options={reportingWindowOptions}
            isDisabled={isSaving}
            onChange={(_, option) => setReportingWindow(option as { value: string; label: string })}
          />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          label={<Trans>Delivery Day</Trans>}
          name={FORM_FIELD.DELIVERY_DAYS}
          initialValue={subscription?.deliveryDays ?? [`${dayjs().day()}`]}
          rules={[
            {
              required: true,
              message: <Trans>Delivery Day is Required. Please choose at least one.</Trans>,
            },
          ]}
        >
          <DayChooser selectionMode="multiple" isDisabled={isSaving} />
        </Form.Item>
      </Col>
    </>
  );
};

export default NotificationSubscriptionRecurringForm;

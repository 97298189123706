import { useState } from 'react';

import { useCampaignActivity } from '@/api/campaign';
import CampaignActivityTableContainer from '@/app/campaigns/CampaignActivityTableContainer';
import CampaignFitScoringTable from '@/app/campaigns/CampaignFitScoringTable';
import CampaignOverviewKPIMetrics from '@/app/campaigns/CampaignOverviewKPIMetrics';
import CampaignOverviewNoData from '@/app/campaigns/CampaignOverviewNoData';
import { useInstantSearchState } from '@/components/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@/components/InstantSearch/util/search-util';
import { usePageFilterContext } from '@/components/page';

const CampaignOverviewContent = () => {
  const {
    pageFilters: { startDate, endDate, audience },
  } = usePageFilterContext();
  const { queryState } = useInstantSearchState();
  const [scoreCampaignIds, setScoreCampaignIds] = useState<string[]>([]);

  const { totalResults } = useCampaignActivity(
    getSolQueryParamsNewFromQueryState(queryState),
    startDate,
    endDate,
    audience?.id,
  );

  if (totalResults === 0) {
    return <CampaignOverviewNoData />;
  }

  return (
    <>
      <CampaignOverviewKPIMetrics />
      <CampaignActivityTableContainer onScoreCampaigns={setScoreCampaignIds} />
      <CampaignFitScoringTable campaignIds={scoreCampaignIds} />
    </>
  );
};

export default CampaignOverviewContent;

import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router';

import { type OpportunityStatusType, OpportunityType, useRecentDeals } from '@/api/opportunity';
import ClosedWonContent from '@/app/closed-won/ClosedWonContent';
import { Flex } from '@/components/Flex';
import Error404 from '@/components/errors/Error404';
import PageError from '@/components/errors/PageError';
import { Page } from '@/components/page';
import { Link, Text, Title } from '@/components/typography';
import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';
import { OpportunityTypeSelect } from '@/features/OpportunityTypeSelect';
import { ROUTES } from '@/router';

import ClosedWonKPIMetrics from './ClosedWonKPIMetrics';
import ClosedWonSelect from './ClosedWonSelect';

const getDefaultRecentDeal = (recentDeals?: OpportunityType[], opportunityId?: string) => {
  if (!recentDeals?.length) {
    return undefined;
  }

  if (opportunityId) {
    const deal = recentDeals.find((d) => d.id === opportunityId);
    return deal || undefined;
  }
  return recentDeals[0];
};

const ClosedWonOverview = () => {
  const hasAttributionFlag = useFeatureFlag(FEATURE_FLAG.attribution);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { opportunity: opportunityId } = useParams();
  const { recentDeals, isLoading, error } = useRecentDeals();
  const [selectedDeal, setSelectedDeal] = useState<OpportunityType>();
  const defaultDeal = getDefaultRecentDeal(recentDeals, opportunityId);
  const defaultDeal404 = defaultDeal == null;
  const deal = selectedDeal || defaultDeal;
  const oppType = searchParams.get('oppType') ?? undefined;

  useEffect(() => {
    if (hasAttributionFlag && oppType !== 'pipeline' && oppType !== 'closedWon') {
      setSearchParams({ oppType: 'pipeline' }, { replace: true });
    }
  }, [oppType, hasAttributionFlag]);

  useEffect(() => {
    if (deal) {
      navigate(ROUTES.closedWonById(deal.id), { replace: true });
    }
  }, [deal]);

  const handleOppTypeChange = (value: OpportunityStatusType) => {
    setSearchParams({ oppType: value });
  };

  if (hasAttributionFlag && oppType !== 'pipeline' && oppType !== 'closedWon') {
    return null;
  }

  return (
    <Page title={t`Analytics`} pageName={t`Closed Won`}>
      <Space direction="vertical" size="large">
        {!recentDeals?.length && !isLoading ? (
          <PageError
            message={<Trans>No Closed Won opportunities were found.</Trans>}
            detail={
              <Trans>
                Please{' '}
                <Link variant="headline" to={ROUTES.integrations.path}>
                  connect your Salesforce CRM
                </Link>{' '}
                to see Closed Won opportunities.
              </Trans>
            }
          />
        ) : (
          <>
            <Flex justify="space-between" align="bottom">
              <Flex gap="small" vertical>
                {deal?.account?.company.name && (
                  <Title level="3">{deal?.account.company.name}</Title>
                )}
                <Text variant="body1">
                  <Trans>Opportunity name: {deal?.name}</Trans>
                </Text>
              </Flex>
              <Flex gap="small">
                {hasAttributionFlag && (
                  <OpportunityTypeSelect
                    value={oppType as OpportunityStatusType}
                    onChange={handleOppTypeChange}
                  />
                )}
                <ClosedWonSelect value={deal} onChange={setSelectedDeal} />
              </Flex>
            </Flex>
            {!isLoading && defaultDeal404 ? (
              <Error404
                message={
                  <Trans>The Closed Won opportunity you're looking for does not exist</Trans>
                }
              />
            ) : (
              <>
                <ClosedWonKPIMetrics data={deal} isLoading={isLoading} error={error} />
                <ClosedWonContent opportunity={deal} oppType={oppType as OpportunityStatusType} />
              </>
            )}
          </>
        )}
      </Space>
    </Page>
  );
};

export default ClosedWonOverview;

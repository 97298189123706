import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import CloseIcon from '@/assets/svg/bx-x.svg?react';
import TooltipTitle from '@/components/Flare/guides/TooltipTitle';
import { ActionIcon } from '@/components/buttons';

import styles from './MultiSeriesTooltip.module.scss';

type Props = {
  ['data-testid']?: string | false;
  title?: string;
  sticky?: boolean;
  onClose?: () => void;
};

const MultiSeriesTooltip = ({
  'data-testid': dataTestId,
  title,
  sticky,
  children,
  onClose,
}: PropsWithChildren<Props>) => {
  return (
    <>
      <table
        data-testid={dataTestId}
        className={classNames(styles.tooltip, { [styles.sticky]: sticky })}
        onClick={(e) => e.stopPropagation()}
      >
        {title && (
          <thead>
            <TooltipTitle>{title}</TooltipTitle>
          </thead>
        )}
        <tbody>{children}</tbody>
      </table>

      {sticky && (
        <ActionIcon
          className={styles.closeButton}
          color="white"
          size="small"
          icon={<CloseIcon />}
          onClick={onClose}
        />
      )}
    </>
  );
};

export default MultiSeriesTooltip;

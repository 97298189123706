import { Trans } from '@lingui/react/macro';
import { useContext } from 'react';

import { Flex } from '@/components/Flex';
import Notification from '@/components/Notification';
import { Button } from '@/components/buttons';
import { clearForeignSuperuserInstance } from '@/helper/user-preferences';
import { useLogOut } from '@/hooks/useLogOut';
import { UserContext } from '@/providers/User';
import { ROUTES } from '@/router';

import styles from './LoginAsNotification.module.scss';

type Props = {
  instanceId: string;
};

const LoginAsNotification = ({ instanceId }: Props) => {
  const context = useContext(UserContext);
  const { clearCacheOnly } = useLogOut();

  const handleReturnToHomeOrg = () => {
    clearForeignSuperuserInstance();
    clearCacheOnly();
    if (context) {
      context.setForeignInstance(null);
    }
    // reload the page to clear all state
    window.location.href = ROUTES.app.path;
  };

  return (
    <Notification className={styles.notification} type="info" size="large">
      <Flex justify="space-between" align="center">
        <Trans>REMINDER! You are currently an Admin in instance: "{instanceId}"</Trans>
        <Button color="white" onClick={handleReturnToHomeOrg}>
          <Trans>Return To Your Org</Trans>
        </Button>
      </Flex>
    </Notification>
  );
};

export default LoginAsNotification;

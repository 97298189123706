import Highcharts, { Options, Point } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Ref, useLayoutEffect } from 'react';

import useForwardedRef from '@/hooks/useForwardedRef';

import { ChartCallbackFunction } from './types';

type Props = {
  ref?: Ref<HighchartsReact.RefObject>;
  callback?: ChartCallbackFunction;
  options: Options | null | undefined;
  measuredWidth?: number;
  measuredHeight?: number;
  disabledSeriesIds?: string[];
};

interface PointExtended extends Point {
  id?: string;
}

const HighchartsRenderer = ({
  ref,
  callback,
  options,
  measuredWidth,
  measuredHeight,
  disabledSeriesIds,
}: Props) => {
  const chartRef = useForwardedRef<HighchartsReact.RefObject>(ref);

  useLayoutEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart && measuredWidth && measuredHeight) {
      chart.setSize(measuredWidth, measuredHeight, false);
    }
  }, [measuredWidth, measuredHeight, chartRef.current?.chart]);

  useLayoutEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart && disabledSeriesIds) {
      chart.series.forEach((series) => {
        // Each pie slice is typically seen as a series, so we toggle them on/off instead.
        if (series.type === 'pie') {
          series.points.forEach((point) => {
            const id = (point as PointExtended).id || '';
            point.setVisible(disabledSeriesIds?.includes(id) === false);
          });
        } else {
          series.setVisible(disabledSeriesIds?.includes(series.options.id ?? '') === false);
        }
      });
    }
  }, [disabledSeriesIds, chartRef.current?.chart]);

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      containerProps={{
        style: {
          width: '100%',
          height: '100%',
        },
      }}
      options={options}
      callback={callback}
    />
  );
};

export default HighchartsRenderer;

import { plural, t } from '@lingui/core/macro';

import { AudienceGroupedOptionType } from '@/api/audience';
import {
  usePixelImpressionTrend,
  usePixelOpportunitiesInfluenced,
  usePixelReachEngagementTrend,
  usePixelTargetingEfficiency,
} from '@/api/pixel';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';
import { WebTrafficSearchParamType } from '@/router/searchParams/webTrafficSearchParams';

type Props = {
  pixelIds?: string[];
  audience?: AudienceGroupedOptionType;
  isLoading: boolean;
};

const PixelDetailKPIMetrics = ({ pixelIds, audience, isLoading: isPixelLoading }: Props) => {
  const { pageFilters } = usePageFilterContext();
  const { startDate, endDate } = pageFilters;
  const audienceIds = audience?.audienceIds;

  const {
    pixelReachEngagementTrend,
    isLoading: isReachEngagementLoading,
    error: reachEngagementError,
  } = usePixelReachEngagementTrend(pixelIds, startDate, endDate, audienceIds);
  const {
    pixelImpressionTrend,
    isLoading: isImpressionTrendLoading,
    error: pixelImpressionError,
  } = usePixelImpressionTrend(pixelIds, startDate, endDate, audienceIds);
  const {
    pixelTargetingEfficiencyData,
    isLoading: isTargetingEfficiencyLoading,
    error: targetingEfficiencyError,
  } = usePixelTargetingEfficiency(pixelIds, startDate, endDate, audienceIds);
  const {
    pixelOpportunitiesInfluenced,
    isLoading: isOpportunitiesInfluencedLoading,
    error: oppInfluencedError,
  } = usePixelOpportunitiesInfluenced(pixelIds, startDate, endDate, audienceIds);

  const lastTrendItem = pixelReachEngagementTrend?.at(-1);
  const reached = lastTrendItem?.reach;
  const engaged = lastTrendItem?.engagement;
  const totalSize = lastTrendItem?.audienceSize;

  const defaultFilters: Partial<WebTrafficSearchParamType> = {
    'w.pixelIds': pixelIds,
    'w.audienceIds': audience?.audienceIds,
  };

  return (
    <KpiMetricGroup>
      <KpiMetric
        label={t`Total Impressions`}
        value={pixelImpressionTrend && numberFormat(pixelImpressionTrend.totalImpressions)}
        infoPopover={{
          title: t`Impressions (Pixel)`,
          body: t`Number of events the C99 Pixel recorded and analyzed. This only counts impressions where the Pixel code has been placed. This differs from the Impressions on the Vendors or Campaign page which gathers impression information directly from the Vendor Integration.`,
        }}
        isLoading={isImpressionTrendLoading || isPixelLoading}
        error={pixelImpressionError}
      />
      <KpiMetric
        label={t`Efficiency`}
        value={
          pixelTargetingEfficiencyData &&
          numberFormat(
            pixelTargetingEfficiencyData.audience /
              Math.max(
                (pixelTargetingEfficiencyData.all ?? 0) -
                  (pixelTargetingEfficiencyData.unresolved ?? 0),
                1, // avoid division by zero
              ),
            { isPercent: true, precision: 1 },
          )
        }
        infoPopover={{
          title: t`Efficiency (Pixel)`,
          body: t`Number of impressions to an audience (TAM or Custom Audience) compared to the ALL impressions from the Pixel. Expressed as a percentage to show how well the vendor is serving impressions to the correct companies.`,
        }}
        isLoading={isTargetingEfficiencyLoading || isPixelLoading}
        error={targetingEfficiencyError}
      />
      <KpiMetric
        label={t`Reached Companies`}
        value={reached && numberFormat(reached)}
        valueLink={
          !!reached &&
          ROUTES.webTrafficActivityWithParams({
            ...defaultFilters,
            'w.metrics.impressions': '[1,]',
          })
        }
        caption={
          reached &&
          totalSize &&
          t`${numberFormat(reached / totalSize, { isPercent: true, precision: 1 })} of ${numberFormat(totalSize)}`
        }
        infoPopover={{
          title: t`Reached Companies`,
          body: t`Number of Companies within an audience which received at least one impression. The viewer does not have to visit the site to be "Reached" they only must view the content within the timeframe you have selected.`,
        }}
        isLoading={isReachEngagementLoading || isPixelLoading}
        error={reachEngagementError}
      />
      <KpiMetric
        label={t`Engaged Companies`}
        value={engaged && numberFormat(engaged)}
        valueLink={
          !!engaged &&
          ROUTES.webTrafficActivityWithParams({
            ...defaultFilters,
            'w.metrics.impressions': '[1,]',
            'w.isEngaged': 'true',
          })
        }
        caption={
          engaged &&
          totalSize &&
          t`${numberFormat(engaged / totalSize, { isPercent: true, precision: 1 })} of ${numberFormat(totalSize)}`
        }
        infoPopover={{
          title: t`Engaged Companies`,
          body: t`Engaged companies are a count of companies which had at least one visit within the timeframe selected. Engaged companies within the Pixel section expands upon direct click through visits to include companies which have view-through as well.`,
        }}
        isLoading={isReachEngagementLoading || isPixelLoading}
        error={reachEngagementError}
      />
      <KpiMetric
        label={t`Pipeline Influence`}
        value={
          pixelOpportunitiesInfluenced &&
          numberFormat(pixelOpportunitiesInfluenced.pipeline.totalRevenue, {
            isCurrency: true,
            precision: 0,
          })
        }
        caption={plural(pixelOpportunitiesInfluenced?.pipeline.totalOpportunities || 0, {
          one: '# Opportunity',
          other: '# Opportunities',
        })}
        infoPopover={{
          title: t`Pipeline Influence (Pixel)`,
          body: t`Pipeline Influence includes opportunities opened within the opportunity influence window with at least one visit or view-through visit. This is an expanded form from the Vendors and Campaign Pages which uses click-through visits only.`,
        }}
        isLoading={isOpportunitiesInfluencedLoading || isPixelLoading}
        error={oppInfluencedError}
      />
      <KpiMetric
        label={t`Closed Won Influence`}
        value={
          pixelOpportunitiesInfluenced &&
          numberFormat(pixelOpportunitiesInfluenced.recentDeals.totalRevenue, {
            isCurrency: true,
            precision: 0,
          })
        }
        caption={plural(pixelOpportunitiesInfluenced?.recentDeals.totalOpportunities || 0, {
          one: '# Opportunity',
          other: '# Opportunities',
        })}
        infoPopover={{
          title: t`Closed Won Influence (Pixel)`,
          body: t`Closed Won Influence includes opportunities closed/won within dates selected with at least one visit or view-through visit. This is an expanded form from the Vendors and Campaign Pages which uses click-through visits only.`,
        }}
        isLoading={isOpportunitiesInfluencedLoading || isPixelLoading}
        error={oppInfluencedError}
      />
    </KpiMetricGroup>
  );
};

export default PixelDetailKPIMetrics;

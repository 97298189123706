import { usePipelineWeeklyTrends } from '@/api/pipeline';
import { DashboardWidgetComponentProps } from '@/components/Dashboard/types';
import PipelineTrendsChart from '@/features/charts/PipelineTrendsChart';

const PipelineTrendsWidget = ({ pageFilters }: DashboardWidgetComponentProps) => {
  const { startDate, endDate } = pageFilters;
  const { pipelineWeeklyTrends, isLoading, error } = usePipelineWeeklyTrends(startDate, endDate);

  return <PipelineTrendsChart isLoading={isLoading} data={pipelineWeeklyTrends} error={error} />;
};

export default PipelineTrendsWidget;

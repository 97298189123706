import classNames from 'classnames';

import { Select, SelectProps } from '../Select';
import styles from './TagTextArea.module.scss';

export type TagTextAreaProps = Omit<SelectProps, 'dropdownStyle' | 'mode'> & {
  className?: string;
  tokenSeparators?: string[];
};

const TOKEN_SEPARATORS = [',', ' ', '\n'];

const TagTextArea = ({
  ref,
  className,
  tokenSeparators = TOKEN_SEPARATORS,
  ...props
}: TagTextAreaProps) => {
  return (
    <Select
      {...props}
      className={classNames(styles.tagTextArea, className)}
      ref={ref}
      mode="tags"
      tokenSeparators={tokenSeparators}
      dropdownStyle={{ display: 'none' }}
      suffixIcon={null}
    />
  );
};

export default TagTextArea;

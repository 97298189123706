import { useWebTrafficKPIs } from '@/api/webTraffic';
import { usePageFilterContext } from '@/components/page';
import { TrafficKPIs } from '@/features/TrafficKPIs';

const WebTrafficKPIMetrics = () => {
  const { pageFilters } = usePageFilterContext();
  const { startDate, endDate, audience } = pageFilters;
  const { webTrafficKPIs, isLoading, error } = useWebTrafficKPIs(startDate, endDate, audience);

  return <TrafficKPIs trafficKPIData={webTrafficKPIs} isLoading={isLoading} error={error} />;
};

export default WebTrafficKPIMetrics;

import { i18n } from '@lingui/core';
import classNames from 'classnames';
import { InputHTMLAttributes, useCallback, useMemo, useRef, useState } from 'react';

import { Flex } from '@/components/Flex';
import Form from '@/components/Form/Form';
import { Text } from '@/components/typography';
import { getWeekdayNames } from '@/helper/dateHelper';
import useControlled from '@/hooks/useControlled';

import styles from './DayChooser.module.scss';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'prefix' | 'type' | 'value'> & {
  className?: string;
  defaultValue?: string[];
  value?: string[];
  selectionMode?: 'single' | 'multiple';
  isDisabled?: boolean;
  status?: 'error' | 'warning';
  onChange?: (value?: string[]) => void;
};

const DayChooser = ({
  defaultValue,
  value: valueFromProps,
  selectionMode = 'single',
  isDisabled = false,
  onChange: onChangeFromProps,
  ...rest
}: Props) => {
  const { status: formStatus } = Form.Item.useStatus();
  const containerRef = useRef<HTMLElement>(null);
  const [value, onChange] = useControlled(valueFromProps, defaultValue, onChangeFromProps);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(0);
  const status = rest.status || formStatus;

  const handleItemClick = useCallback(
    (itemValue: string) => {
      if (selectionMode === 'single') {
        onChange([itemValue]);
      }

      if (selectionMode === 'multiple') {
        if ((value || []).includes(itemValue)) {
          onChange((value || []).filter((v) => v !== itemValue));
        } else {
          onChange([...(value || []), itemValue]);
        }
      }
    },
    [value, selectionMode],
  );

  const days = useMemo(() => getWeekdayNames('narrow'), []);

  return (
    <Flex
      ref={containerRef}
      role="listbox"
      gap="medium"
      aria-multiselectable={selectionMode === 'multiple' ? true : undefined}
      {...rest}
    >
      {days.map((day, index) => {
        const isSelected = (value || []).includes(index + '');
        const dayLabel = i18n._(day);

        return (
          <Text
            key={index}
            className={classNames(styles.day, {
              [styles.selected]: isSelected,
              [styles.disabled]: isDisabled,
              [styles.invalid]: status === 'error',
              [styles.warning]: status === 'warning',
            })}
            variant="body2"
            weight="light"
            role="option"
            aria-label={dayLabel}
            aria-selected={isSelected}
            tabIndex={focusedIndex === index && !isDisabled ? 0 : -1}
            onClick={() => !isDisabled && handleItemClick(index + '')}
            onFocus={() => !isDisabled && setFocusedIndex(index)}
            onKeyDown={(e) => {
              e.preventDefault();

              if (containerRef.current == null) {
                return;
              }

              const itemEls = containerRef.current.querySelectorAll<HTMLElement>('[role="option"]');

              switch (e.key) {
                case 'ArrowUp':
                  itemEls[Math.max(index - 1, 0)].focus();
                  setFocusedIndex(Math.max(index - 1, 0));
                  break;
                case 'ArrowDown':
                  itemEls[Math.min(index + 1, days.length - 1)].focus();
                  setFocusedIndex(Math.min(index + 1, days.length - 1));
                  break;
                case 'Home':
                  itemEls[0].focus();
                  setFocusedIndex(0);
                  break;
                case 'End':
                  itemEls[days.length - 1].focus();
                  setFocusedIndex(days.length - 1);
                  break;
                case ' ':
                case 'Enter':
                  handleItemClick(index + '');
                  break;
              }
            }}
          >
            {dayLabel}
          </Text>
        );
      })}
    </Flex>
  );
};

export default DayChooser;

import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useParams } from 'react-router';

import { useCampaignsByIds } from '@/api/campaign';
import { Flex } from '@/components/Flex';
import { ReturnToLink } from '@/components/ReturnToLink';
import {
  Page,
  PageAudienceFilter,
  PageDateRangeFilter,
  PageFilterProvider,
} from '@/components/page';
import { CAMPAIGN_GROUP_MAX_LIMIT } from '@/constants/numbers';
import ShareMenu from '@/features/ShareMenu';
import { ROUTES } from '@/router';

import CampaignDetailContent from './CampaignDetailContent';
import CampaignDetailErrorOrChildren from './CampaignDetailErrorOrChildren';
import CampaignTagList from './CampaignTagList';

const CampaignDetail = () => {
  const { campaigns: campaignIdString } = useParams();
  const campaignIds = campaignIdString?.split(',');
  const { campaigns, isLoading } = useCampaignsByIds(campaignIds);
  const isGrouped = !!(campaignIds && campaignIds?.length > 1);

  const campaignName = isGrouped ? t`Grouped (${campaignIds?.length})` : campaigns?.[0].name;
  const pageTitle = campaignName;

  return (
    <Page title={t`Campaign`} pageName={pageTitle}>
      <Flex vertical gap="large" fullWidth>
        <Flex justify="space-between" align="start" gap="xlarge">
          <ReturnToLink
            route={ROUTES.campaigns.path}
            routeName={<Trans>Campaign Overview</Trans>}
          />
          <CampaignTagList campaigns={campaigns} />
        </Flex>
        <PageFilterProvider>
          <CampaignDetailErrorOrChildren
            campaignIds={campaignIds}
            maxLimit={CAMPAIGN_GROUP_MAX_LIMIT}
          >
            <Flex justify="space-between">
              <PageDateRangeFilter />
              <Flex gap="small">
                <PageAudienceFilter isDisabled={isLoading} />
                <ShareMenu notificationPageKey="campaign" />
              </Flex>
            </Flex>
            <CampaignDetailContent
              campaignName={campaignName}
              campaignIds={campaignIds}
              isLoading={isLoading}
            />
          </CampaignDetailErrorOrChildren>
        </PageFilterProvider>
      </Flex>
    </Page>
  );
};

export default CampaignDetail;

import { useWebTrafficActivityList } from '@/api/activity';
import WebTrafficCompanyActivityTable from '@/app/web-traffic/WebTrafficCompanyActivityTable';
import { DashboardWidgetComponentProps } from '@/components/Dashboard/types';
import { InstantSearchSortOrder } from '@/components/InstantSearch';
import { WEB_TRAFFIC_ACTIVITY_SORT_KEYS } from '@/router/searchParams/webTrafficSearchParams';

const CompanyActivityTopVisitsWidget = ({ pageFilters }: DashboardWidgetComponentProps) => {
  const { startDate, endDate, audience } = pageFilters;
  const { companies, isLoading, error } = useWebTrafficActivityList(
    {
      page: {
        offset: 0,
        limit: 10,
      },
      sort: {
        field: 'metrics.visits' satisfies WEB_TRAFFIC_ACTIVITY_SORT_KEYS,
        direction: InstantSearchSortOrder.DESC,
      },
      filter: audience
        ? [
            {
              field: 'audienceIds',
              operator: 'in',
              operand: [audience?.id],
            },
          ]
        : [],
    },
    startDate,
    endDate,
  );

  return <WebTrafficCompanyActivityTable data={companies} isLoading={isLoading} error={error} />;
};

export default CompanyActivityTopVisitsWidget;

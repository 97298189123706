import gql from 'graphql-tag';
import { useMemo } from 'react';

import type { AudienceOptionType } from '@/api/audience';
import { useSolQuery } from '@/hooks/useSolQuery';

import {
  OfflineTimelineGQLResponse,
  RecentDealVisitTrendsGQLResponse,
  RecentDealsGQLResponse,
} from './opportunityType';

export const useRecentDeals = () => {
  const { data, error, isLoading } = useSolQuery<RecentDealsGQLResponse>({
    query: gql`
      query Opportunities {
        opportunities {
          recentDeals {
            id
            account {
              company {
                name
              }
              visits
              people
              vendors
            }
            amount
            closeDate
            daysToClose
            name
            offlineEvents
            openDate
          }
        }
      }
    `,
  });

  return {
    recentDeals: data?.opportunities.recentDeals,
    isLoading,
    error,
  };
};

export const useRecentDealVisitTrends = (opportunityId?: string) => {
  const { data, error, isLoading } = useSolQuery<RecentDealVisitTrendsGQLResponse>({
    query:
      !!opportunityId &&
      gql`
        query RecentDealVisitTrends($opportunityId: String) {
          visualization {
            recentDealsTrends(opportunityId: $opportunityId) {
              channelData {
                channel {
                  id
                  name
                  color
                }
                data {
                  sundayOfWeek
                  runningTotalVisits
                }
              }
            }
          }
        }
      `,
    variables: {
      opportunityId,
    },
  });

  useMemo(() => {
    const { recentDealsTrends } = data?.visualization ?? {};

    if (!recentDealsTrends) {
      return;
    }

    recentDealsTrends.channelData.sort((a, b) => {
      if (a.channel.name < b.channel.name) {
        return -1;
      }
      if (a.channel.name > b.channel.name) {
        return 1;
      }
      return 0;
    });

    return recentDealsTrends;
  }, [data]);

  return {
    recentDealVisitTrends: data?.visualization.recentDealsTrends,
    isLoading,
    error,
  };
};

export const useOpportunityNonDigitalEventsTrends = (opportunityId?: string) => {
  const { data, isLoading, error } = useSolQuery<OfflineTimelineGQLResponse>({
    query:
      !!opportunityId &&
      gql`
        query OfflineTimeline($opportunityId: String!) {
          visualization {
            offlineTimeline(opportunityId: $opportunityId) {
              campaignType {
                id
                name
                vendor {
                  color
                  id
                  name
                  channel {
                    color
                    id
                    name
                  }
                }
              }
              data {
                events {
                  eventId
                  eventUrl
                  eventDate
                }
                sundayOfWeek
              }
            }
          }
        }
      `,
    variables: {
      opportunityId,
    },
  });

  return {
    offlineTimeline: data?.visualization.offlineTimeline,
    isLoading,
    error,
  };
};
export type OpportunityTrendGQLResponse = {
  visualization: {
    opportunityTrend: {
      count: number;
      revenue: number;
      status: 'Closed Won' | 'Pipeline' | 'Closed Lost';
      sundayOfWeek: string;
    }[];
  };
};

export const useOpportunityTrend = (
  startDate?: string,
  endDate?: string,
  audience?: AudienceOptionType,
) => {
  const canExecuteQuery = !!startDate && !!endDate;
  const { data, isLoading, error } = useSolQuery<OpportunityTrendGQLResponse>({
    query:
      canExecuteQuery &&
      gql`
        query OpportunityTrend($startDate: DateTime!, $endDate: DateTime!, $audienceIds: [String]) {
          visualization {
            opportunityTrend(startDate: $startDate, endDate: $endDate, audienceIds: $audienceIds) {
              count
              revenue
              status
              sundayOfWeek
            }
          }
        }
      `,
    variables: {
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds: audience?.id ? [audience.id] : undefined,
    },
  });

  return {
    opportunityTrend: data?.visualization.opportunityTrend,
    isLoading,
    error,
  };
};

/* eslint-disable no-restricted-imports */
import { Popover as AntdPopover, PopoverProps as AntdPopoverProps } from 'antd';
import { TooltipRef } from 'antd/es/tooltip';
import classNames from 'classnames';
import { Ref } from 'react';

import styles from './Popover.module.scss';

export type PopoverProps = AntdPopoverProps & {
  ref?: Ref<TooltipRef>;
  noPadding?: boolean;
  variant?: 'default' | 'dark';
};

const Popover = ({
  ref,
  classNames: { root, body } = { root: undefined, body: undefined },
  variant = 'default',
  noPadding = false,
  ...rest
}: PopoverProps) => {
  return (
    <AntdPopover
      ref={ref}
      classNames={{
        body: body,
        root: classNames(styles.overlay, root, variant === 'dark' && styles.dark, {
          [styles.noPadding]: noPadding,
        }),
      }}
      arrow
      {...rest}
    />
  );
};

export default Popover;

/* eslint-disable no-restricted-imports */
import { Button as AntdButton } from 'antd';
import classNames from 'classnames';
import { ReactNode, Ref } from 'react';
import { Link, To } from 'react-router';

import { ButtonHTMLProps } from '../types';
import styles from './ActionIcon.module.scss';

export type ActionIconProps = Omit<ButtonHTMLProps, 'children' | 'href'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: Ref<any>;
  color: 'black' | 'white' | 'red';
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  icon: ReactNode;
  isDisabled?: boolean;
  autoFocus?: boolean;
  to?: To | false;
};

const ActionIcon = ({
  ref,
  className,
  color,
  size = 'medium',
  icon,
  isDisabled = false,
  autoFocus,
  to,
  target,
  rel,
  ...rest
}: ActionIconProps) => {
  const button = (
    <AntdButton
      {...rest}
      ref={ref}
      className={classNames(
        styles.actionIcon,
        className,
        styles[`color-${color}`],
        styles[`size-${size}`],
        {
          [styles.disabled]: isDisabled,
        },
      )}
      type="text"
      shape="default"
      icon={icon}
      autoFocus={autoFocus}
      disabled={isDisabled}
    />
  );

  if (to) {
    return (
      <Link
        ref={ref}
        to={to}
        target={target}
        rel={!rel && target === '_blank' ? 'noreferrer' : rel}
      >
        {button}
      </Link>
    );
  }

  return button;
};

export default ActionIcon;

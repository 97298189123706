import { Trans } from '@lingui/react/macro';
import classNames from 'classnames';

import { Flex, Props } from '@/components/Flex';
import { Text } from '@/components/typography';
import { numberFormat } from '@/helper/numberFormatter';

import styles from './DeltaLabel.module.scss';

type FlexProps = Omit<Props, 'children'> & {
  percentChange: number | null;
};

const getDeltaType = (percentChange: number | null) => {
  if (percentChange === null) {
    return 'neutral';
  }

  if (Math.abs(percentChange) < 0.005) {
    return 'neutral';
  }

  if (percentChange > 0) {
    return 'positive';
  } else if (percentChange < 0) {
    return 'negative';
  } else {
    return 'neutral';
  }
};

const DeltaLabel = ({ percentChange, ...rest }: FlexProps) => {
  const deltaType = getDeltaType(percentChange);
  const absValue = percentChange === null ? null : Math.abs(percentChange);

  return (
    <Flex gap={2} align="center" justify="start" {...rest}>
      <div className={classNames(styles.percentChangeArrow, styles[deltaType])} />
      <Text variant="caption1" color="black">
        {deltaType === 'positive' && '+'}
        {deltaType === 'negative' && '-'}
        {percentChange === null ? (
          <Text variant="caption1" color="grey">
            <Trans>N/A</Trans>
          </Text>
        ) : (
          numberFormat(absValue, { isPercent: true, precision: 0 })
        )}
      </Text>
    </Flex>
  );
};

export default DeltaLabel;

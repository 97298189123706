import { I18n, i18n } from '@lingui/core';
import { t } from '@lingui/core/macro';
import dayjs from 'dayjs';

export const getUTCTime = (date?: string) => {
  if (!date) {
    return 0;
  }

  const utcDate = parseUTCDate(date);
  return utcDate.getTime();
};

export const getTime = (date: string) => {
  return dayjs(date).toDate().getTime();
};

export const formatDate = (
  date?: string | number | null,
  format?: string,
  { isUTC = false } = {},
) => {
  if (!date) {
    return t`N/A`;
  }

  let dayjsDate = dayjs(date);
  if (isUTC) {
    dayjsDate = dayjsDate.utc();
  }
  return dayjsDate.format(format);
};

export const formatDateFromNow = (date?: string, withoutPrefix?: boolean) => {
  if (!date) {
    return t`N/A`;
  }
  return dayjs(date).fromNow(withoutPrefix);
};

export const parseUTCDate = (date: number | string | Date) => {
  const d = dayjs(date).toDate();
  return new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds(),
    d.getUTCMilliseconds(),
  );
};

export const dateFormatter = (
  i18n: I18n,
  date: number | string | Date | undefined | null,
  options: Intl.DateTimeFormatOptions,
) => {
  if (date == null) {
    return '';
  }
  const d = dayjs(date);
  if (!d.isValid()) {
    return '';
  }
  return i18n.date(d.toDate(), options);
};

export const dateFormatterUTC = (
  i18n: I18n,
  date: number | string | Date | undefined | null,
  options: Intl.DateTimeFormatOptions,
) => {
  if (date == null) {
    return '';
  }
  const utcDate = parseUTCDate(date);
  return dateFormatter(i18n, utcDate, options);
};

export const isLastMonthOfYear = (isoDate: string) => dayjs(isoDate).utc().month() === 11;
export const isCurrentMonth = (isoDate: string) => dayjs().utc().isSame(isoDate, 'month');

export const getWeekdayNames = (weekdayFormat: Intl.DateTimeFormatOptions['weekday']) => {
  const { format } = new Intl.DateTimeFormat(i18n.locale, { weekday: weekdayFormat });
  return [...Array(7).keys()].map((day) => format(new Date(Date.UTC(2021, 5, day))));
};

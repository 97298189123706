import { t } from '@lingui/core/macro';
// eslint-disable-next-line no-restricted-imports
import { Button, InputRef } from 'antd';
import classNames from 'classnames';
import { Ref, useEffect, useState } from 'react';

import AIStarsIcon from '@/assets/svg/ai-stars.svg?react';
import SendIcon from '@/assets/svg/bx-send.svg?react';
import { TextInput } from '@/components/Form';
import { MicrophoneButton } from '@/components/buttons';
import useForwardedRef from '@/hooks/useForwardedRef';
import useSpeechRecognition from '@/hooks/useSpeechRecognition';

import styles from './AISearchInput.module.scss';

type Props = {
  ref?: Ref<InputRef>;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  isRound?: boolean;
  isSearchDisabled?: boolean;
  showSearchButton?: boolean;
  onSearch: (value: string) => void;
};

const AISearchInput = ({
  ref,
  className = '',
  size = 'large',
  isSearchDisabled = false,
  isRound = false,
  showSearchButton = false,
  onSearch,
}: Props) => {
  const inputRef = useForwardedRef<InputRef>(ref);
  const [textValue, setTextValue] = useState('');
  const {
    value: audioValue,
    setValue: setAudioValue,
    isRecording,
    startRecording,
    stopRecording,
  } = useSpeechRecognition({
    lang: 'en-US',
    continuous: true,
  });
  const value = isRecording ? audioValue : textValue;

  const handleToggleRecording = (newIsRecording: boolean) => {
    if (inputRef.current?.input) {
      if (newIsRecording) {
        startRecording();
        inputRef.current?.focus();
      } else {
        stopRecording();
        const valueLen = inputRef.current.input.value.length;
        inputRef.current.input.setSelectionRange(valueLen, valueLen);
        inputRef.current.input.scrollLeft = inputRef.current.input.scrollWidth;
      }
    }
  };

  const handleTextChange = (value: string) => {
    setTextValue(value);

    if (isRecording) {
      stopRecording();
    }
  };

  const handleSearch = (val = value) => {
    if (val.length > 0) {
      onSearch(val);
      setTextValue('');
      setAudioValue('');
    }
  };

  useEffect(() => {
    if (isRecording) {
      setTextValue('');
    } else {
      if (audioValue.length > 0) {
        handleSearch(audioValue);
      }
    }
  }, [isRecording]);

  useEffect(() => {
    if (isRecording && inputRef.current?.input) {
      inputRef.current.focus();
      inputRef.current.input.setSelectionRange(audioValue.length, audioValue.length);
      inputRef.current.input.scrollLeft = inputRef.current.input.scrollWidth;
    }
  }, [isRecording, audioValue]);

  return (
    <div className={classNames(styles.container, className, { [styles.round]: isRound })}>
      <div className={styles.inputContainer}>
        <TextInput
          ref={inputRef}
          className={classNames(styles.input, { [styles.withButtonAfter]: showSearchButton })}
          value={value}
          prefix={<AIStarsIcon />}
          maxLength={Number.MAX_SAFE_INTEGER}
          size={size}
          placeholder={t`AI Search`}
          onChange={handleTextChange}
          onKeyDown={(e) => !isSearchDisabled && e.key === 'Enter' && handleSearch()}
        />
        <MicrophoneButton
          className={styles.microphone}
          isRecording={isRecording}
          onRecordingChange={handleToggleRecording}
          size={size}
        />
      </div>
      {showSearchButton && (
        <Button
          className={styles.searchButton}
          type="primary"
          icon={<SendIcon />}
          size="large"
          disabled={isSearchDisabled}
          onClick={() => handleSearch()}
        />
      )}
    </div>
  );
};

export default AISearchInput;

import gql from 'graphql-tag';

import type {
  AttributionKpiGQLResponse,
  AttributionTrendGQLResponse,
} from '@/api/attribution/attributionType';
import { useSolQuery } from '@/hooks/useSolQuery';

export const useAttributionKpiMetrics = (startDate?: string, endDate?: string) => {
  const canExecuteQuery = startDate?.length && endDate?.length;
  const { data, isLoading, error } = useSolQuery<AttributionKpiGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query AttributionKpi($startDate: DateTime!, $endDate: DateTime!) {
          visualization {
            attributionKpi(startDate: $startDate, endDate: $endDate) {
              averageDealCycleDays
              closedWonOpportunityCount
              closedWonRevenue
              offlineEventsPerOpportunity
              pipelineOpportunityCount
              pipelineOpportunityRevenue
              visitsPerOpportunity
            }
          }
        }
      `,
    variables: {
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
    },
  });

  return {
    attributionKpi: data?.visualization.attributionKpi,
    isLoading,
    error,
  };
};

export const useAttributionOpportunitiesTrend = (
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;
  const { data, isLoading, error } = useSolQuery<AttributionTrendGQLResponse>({
    query:
      !!canExecuteQuery &&
      gql`
        query AttributionTrend($startDate: DateTime!, $endDate: DateTime!, $audienceIds: [String]) {
          visualization {
            attributionTrend(startDate: $startDate, endDate: $endDate, audienceIds: $audienceIds) {
              closedWon {
                data {
                  opportunities
                  revenue
                  sundayOfWeek
                }
                totalOpportunities
                totalRevenue
              }
              pipeline {
                data {
                  sundayOfWeek
                  opportunities
                  revenue
                }
                totalOpportunities
                totalRevenue
              }
            }
          }
        }
      `,
    variables: {
      startDate: startDate + 'T00:00:00Z',
      endDate: endDate + 'T23:59:59Z',
      audienceIds: audienceId ? [audienceId] : [],
    },
  });

  return {
    attributionTrend: data?.visualization.attributionTrend,
    isLoading,
    error,
  };
};

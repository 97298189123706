import type { OpportunityStatusType } from '@/api/opportunity';
import { InstantSearch, InstantSearchSortOrder, type QueryState } from '@/components/InstantSearch';
import { usePageFilterContext } from '@/components/page';
import { OpportunityAttributionOfflineTable } from '@/features/OpportunityAttributionOfflineTable';
import { OpportunityAttributionOnlineTable } from '@/features/OpportunityAttributionOnlineTable';

const ONLINE_DEFAULT_QUERY_STATE: Partial<QueryState> = {
  sortBy: 'visits',
  sortOrder: InstantSearchSortOrder.DESC,
  size: 10,
};

const OFFLINE_DEFAULT_QUERY_STATE: Partial<QueryState> = {
  sortBy: 'visits',
  sortOrder: InstantSearchSortOrder.DESC,
  size: 10,
};

type Props = {
  oppType: OpportunityStatusType;
};

const AttributionBreakdownTables = ({ oppType }: Props) => {
  const {
    pageFilters: { startDate, endDate, audience },
  } = usePageFilterContext();

  return (
    <>
      <InstantSearch defaultQueryState={ONLINE_DEFAULT_QUERY_STATE} urlSync={false}>
        <OpportunityAttributionOnlineTable
          oppType={oppType}
          startDate={startDate}
          endDate={endDate}
          audience={audience}
        />
      </InstantSearch>
      <InstantSearch defaultQueryState={OFFLINE_DEFAULT_QUERY_STATE} urlSync={false}>
        <OpportunityAttributionOfflineTable
          oppType={oppType}
          startDate={startDate}
          endDate={endDate}
          audience={audience}
        />
      </InstantSearch>
    </>
  );
};

export default AttributionBreakdownTables;

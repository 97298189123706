import { plural } from '@lingui/core/macro';
import { Plural, Trans } from '@lingui/react/macro';

import { useAttributionKpiMetrics } from '@/api/attribution';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { numberFormat } from '@/helper/numberFormatter';

const AttributionKPIMetrics = () => {
  const {
    pageFilters: { startDate, endDate },
  } = usePageFilterContext();
  const { attributionKpi, isLoading, error } = useAttributionKpiMetrics(startDate, endDate);

  return (
    <KpiMetricGroup>
      <KpiMetric
        label={<Trans>Pipeline Opportunities</Trans>}
        value={
          attributionKpi &&
          numberFormat(attributionKpi.pipelineOpportunityRevenue, {
            isCurrency: true,
            precision: 0,
          })
        }
        caption={
          <Plural
            value={attributionKpi && numberFormat(attributionKpi.pipelineOpportunityCount)}
            one="# Opportunity"
            other="# Opportunities"
          />
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Closed Won Opportunities</Trans>}
        value={
          attributionKpi &&
          numberFormat(attributionKpi.closedWonRevenue, {
            isCurrency: true,
            precision: 0,
          })
        }
        caption={
          <Plural
            value={attributionKpi && numberFormat(attributionKpi.closedWonOpportunityCount)}
            one="# Opportunity"
            other="# Opportunities"
          />
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Visits / Opp</Trans>}
        value={attributionKpi && numberFormat(attributionKpi.visitsPerOpportunity)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Non-Digital Events / Opp</Trans>}
        value={attributionKpi && numberFormat(attributionKpi.offlineEventsPerOpportunity)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Avg. Deal Cycle Length</Trans>}
        value={
          attributionKpi &&
          plural(numberFormat(attributionKpi.averageDealCycleDays), {
            one: '# Day',
            other: '# Days',
          })
        }
        isLoading={isLoading}
        error={error}
      />
    </KpiMetricGroup>
  );
};

export default AttributionKPIMetrics;

import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { OpportunityType } from '@/api/opportunity';
import Table, { ColumnsType } from '@/components/Table';
import { Panel } from '@/components/panels';
import { Link } from '@/components/typography';
import { USDateViewFormat } from '@/constants/formats';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

export const OpportunitiesOverviewTable = () => {
  const columns = useMemo<ColumnsType<OpportunityType>>(
    () => [
      {
        title: t`Company Name`,
        key: 'account.company.name',
        fixed: 'left',
        sorter: true,
        render: (_, record) =>
          record.account?.company ? (
            <Link variant="caption1" to={`/app/companies/${record.account.company.id}`}>
              {record.account.company.name}
            </Link>
          ) : (
            '-'
          ),
      },
      {
        title: t`Opportunity`,
        key: 'name',
        sorter: true,
        render: (_, record) => (
          <Link variant="caption1" to={ROUTES.opportunityById(record.id)}>
            {record.name}
          </Link>
        ),
      },
      {
        title: t`Domain`,
        key: 'account.company.tld',
        sorter: true,
        render: (_, record) => record.account?.company.tld || '-',
      },
      {
        title: t`Create Date`,
        key: 'openDate',
        sorter: true,
        render: (_, record) => dayjs(record.openDate).format(USDateViewFormat),
      },
      {
        title: t`Close Date`,
        key: 'closeDate',
        sorter: true,
        render: (_, record) => dayjs(record.closeDate).format(USDateViewFormat),
      },
      {
        title: t`Status`,
        key: 'status',
        sorter: true,
        render: (_, record) => {
          switch (record.status) {
            case 'closedWon':
              return t`Closed Won`;
            case 'closedLost':
              return t`Closed Lost`;
            default:
              return t`Pipeline`;
          }
        },
      },
      {
        title: t`Amount`,
        key: 'amount',
        align: 'right',
        sorter: true,
        render: (_, record) => numberFormat(record.amount, { isCurrency: true, precision: 0 }),
      },
    ],
    [],
  );

  return (
    <Panel title={t`Opportunities`}>
      <Table
        columns={columns}
        dataSource={[]}
        loading={false}
        error={null}
        rowKey="id"
        emptyMessage={<Trans>No opportunities found</Trans>}
      />
    </Panel>
  );
};

import { t } from '@lingui/core/macro';

import { useAttributionOpportunitiesTrend } from '@/api/attribution';
import { OpportunityStatusType } from '@/api/opportunity';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { OpportunityInfluenceChart } from '@/features/OpportunityInfluenceChartTableCombo';

type Props = {
  oppType: OpportunityStatusType;
};

const AttributionTrendsChart = ({ oppType }: Props) => {
  const {
    pageFilters: { startDate, endDate, audience },
  } = usePageFilterContext();
  const { attributionTrend, isLoading, error } = useAttributionOpportunitiesTrend(
    startDate,
    endDate,
    audience?.id,
  );

  return (
    <Panel
      size="L"
      title={t`Attribution Trend`}
      infoPopover={{
        title: t`Attribution Trend`,
        body: t`Weekly pipeline value of opportunities which have closed or were created depending on the Closed Won or Pipeline page-level filter.`,
      }}
      noPadding
    >
      <OpportunityInfluenceChart
        oppType={oppType}
        data={
          oppType === 'closedWon'
            ? attributionTrend?.closedWon.data
            : attributionTrend?.pipeline.data
        }
        isLoading={isLoading}
        error={error}
      />
    </Panel>
  );
};

export default AttributionTrendsChart;

import { useWebTrafficBreakdownByCompanySize } from '@/api/webTraffic';
import WebTrafficCompanySizeChart from '@/app/web-traffic/WebTrafficCompanySizeChart';
import { DashboardWidgetComponentProps } from '@/components/Dashboard/types';

const WebTrafficBreakdownByCompanySizeWidget = ({ pageFilters }: DashboardWidgetComponentProps) => {
  const { startDate, endDate, audience } = pageFilters;
  const { webTrafficRevRangeSummary, isLoading, error } = useWebTrafficBreakdownByCompanySize(
    startDate,
    endDate,
    audience?.id,
  );

  return (
    <WebTrafficCompanySizeChart
      data={webTrafficRevRangeSummary}
      audience={audience}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default WebTrafficBreakdownByCompanySizeWidget;
